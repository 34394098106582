/* eslint-disable max-statements */
/* eslint-disable max-params */
/* eslint-disable max-len */
import {
    AxesHelper,
    Box3, Box3Helper, BoxGeometry, BufferGeometry, DoubleSide, Line, LineBasicMaterial,
    Material,
    Matrix4,
    Mesh, MeshBasicMaterial, Quaternion, Raycaster, SphereGeometry, Vector3,
    PlaneGeometry,
    Scene,
}
    from "three"
import { getFirstAndLastSides } from "./meshHelpers"
import { DragMesh, FreePositions } from "./types"
import { convertInToCm } from "../../components/main/DesignScreen/utils/UnitUtils"

type WalkBoundingBoxParams = {
    mesh: Mesh,
    obb: Mesh | undefined,
    onBoundingPoint: (position: Vector3) => void,
    onMiddlePoint: (position: Vector3) => void,
    stepSize: number,
};

function isInsideWithTolerance(point: Vector3, boundingBox: Box3, tolerance = 0.00001) {
    return (
        point.x >= boundingBox.min.x - tolerance
        && point.x <= boundingBox.max.x + tolerance
        && point.y >= boundingBox.min.y - tolerance
        && point.y <= boundingBox.max.y + tolerance
        && point.z >= boundingBox.min.z - tolerance
        && point.z <= boundingBox.max.z + tolerance
    )
}

function findEdges(obb: Mesh, start: Vector3) {
    const directions = [
        new Vector3(0, 1, 0),
        new Vector3(0, -1, 0),
        new Vector3(1, 0, 0),
        new Vector3(-1, 0, 0),
    ]
    // window.drawVector3Point(start, window.scene, 0x0000ff, 0.002, 3000)

    const raycaster = new Raycaster()
    const startPosition = new Vector3().copy(start)
    const intersectedPoints: Vector3[] = []

    directions.forEach(direction => {
        const localDirection = direction.clone().applyQuaternion(obb.quaternion)
        raycaster.set(startPosition, localDirection)
        // visualizeRay(raycaster)

        const intersects = raycaster.intersectObject(obb, true)
        if (intersects.length > 0) {
            const point = intersects[intersects.length - 1].point
            intersectedPoints.push(point)
            // window.drawVector3Point(point, window.scene, 0xff0000, 0.002, 3000)
        }
    })
    return intersectedPoints
}

function getOBB(mesh: Mesh, expandOffset: number) {
    const bbox = mesh.geometry.boundingBox
    if (!bbox) {
        console.error("Bounding box is null")
        return null
    }

    const worldScale = new Vector3()
    mesh.getWorldScale(worldScale)

    const scaledBbox = new Box3(
        bbox.min.clone().multiply(worldScale),
        bbox.max.clone().multiply(worldScale)
    )
    const { width, height, depth, } = getOBBDimensions(scaledBbox)
    const { width: widthExpanded, height: heightExpanded, depth: depthExpanded, }
        = getOBBDimensions(scaledBbox, expandOffset)

    const bboxMesh = createOBBMesh(width, height, depth)
    const bboxMeshExpanded = createOBBMesh(widthExpanded, heightExpanded, depthExpanded)

    adjustOBBMeshPosition(mesh, bbox, bboxMesh)
    adjustOBBMeshPosition(mesh, bbox, bboxMeshExpanded)

    return { bboxMesh, bboxMeshExpanded, }
}

function getOBBDimensions(bbox: Box3, expandOffset?: number) {
    const zTolerance = 0.001

    const depth = bbox.max.z - bbox.min.z + 2 * zTolerance
    const width = (bbox.max.x - bbox.min.x) + (expandOffset || 0)
    const height = (bbox.max.y - bbox.min.y) + (expandOffset || 0)

    return { width, height, depth, }
}

function createOBBMesh(width: number, height: number, depth: number) {
    const bboxGeometry = new BoxGeometry(width, height, depth)
    const bboxMaterial = new MeshBasicMaterial({
        color: 0x00ff00,
        wireframe: true,
        side: DoubleSide,
    })
    return new Mesh(bboxGeometry, bboxMaterial)
}

function adjustOBBMeshPosition(mesh: Mesh, bbox: Box3, bboxMesh: Mesh) {
    const center = new Vector3()
    bbox.getCenter(center)
    bboxMesh.position.copy(center)

    const worldPosition = new Vector3()
    const worldQuaternion = new Quaternion()
    mesh.getWorldPosition(worldPosition)
    mesh.getWorldQuaternion(worldQuaternion)

    //console.log(mesh.name, worldPosition, "worldPosition inside adjustOBBMeshPosition")

    bboxMesh.position.applyQuaternion(worldQuaternion).add(worldPosition)
    bboxMesh.quaternion.copy(worldQuaternion)
    bboxMesh.updateMatrixWorld(true)
}

function walkBoundingBox(params: WalkBoundingBoxParams): void {
    const { mesh, onBoundingPoint, onMiddlePoint, stepSize, obb, } = params
    if (!obb) {
        console.error("OBB is undefined")
        return
    }
    const boundingBox = new Box3().setFromObject(obb)

    // Visualize the bounding box
    // const boxHelper = new Box3Helper(boundingBox, 0xff0000)
    // window.scene.add(boxHelper)

    const localPosition = mesh.position
    const localXAxis = new Vector3(stepSize, 0, 0).applyQuaternion(mesh.quaternion)
    const localYAxis = new Vector3(0, stepSize, 0).applyQuaternion(mesh.quaternion)
    const parent = mesh.parent || mesh

    function walkYDirection(
        localPositionWithStepX: Vector3,
        startY: number,
        stepY: number,
        isMiddlePoint: boolean
    ): void {
        let j = startY
        let conditionMetY = true
        while (conditionMetY) {
            const localPositionWithStepY
                = localPositionWithStepX.clone().add(localYAxis.clone().multiplyScalar(j))
            const globalPositionWithStepY = parent.localToWorld(localPositionWithStepY.clone())
            if (isInsideWithTolerance(globalPositionWithStepY, boundingBox)) {
                onBoundingPoint(globalPositionWithStepY)
                // window.drawVector3Point(globalPositionWithStepY,
                // window.scene, 0x00ff00, 0.001, 2000)

                if (isMiddlePoint) {
                    // window.drawVector3Point(globalPositionWithStepY,
                    // window.scene, 0x0000ff, 0.001, 2000)

                    onMiddlePoint(globalPositionWithStepY)
                }
                j += stepY
            } else {
                conditionMetY = false
            }
        }
    }

    function walkDirection(startX: number, stepX: number): void {
        let i = startX
        let conditionMetX = true
        let isMiddlePoint = true
        while (conditionMetX) {
            const localPositionWithStepX
                = localPosition.clone().add(localXAxis.clone().multiplyScalar(i))
            walkYDirection(localPositionWithStepX, 0, 1, isMiddlePoint)
            walkYDirection(localPositionWithStepX, -stepX, -1, isMiddlePoint)

            const globalPositionWithStepX = parent.localToWorld(localPositionWithStepX.clone())
            if (isInsideWithTolerance(globalPositionWithStepX, boundingBox)) {
                // window.drawVector3Point(globalPositionWithStepX,
                // window.scene, 0xff0000, 0.001, 2000)
                onMiddlePoint(globalPositionWithStepX)
                i += stepX
                isMiddlePoint = false
            } else {
                conditionMetX = false
            }
        }
    }

    walkDirection(0, 1)
    walkDirection(-1, -1)
}

// eslint-disable-next-line max-statements
function walkSurface(mesh: Mesh, stepSize = 1, expandOffset = 0, extendSurface = false, scene?: Scene) {
    const globalPosition = new Vector3()
    mesh.getWorldPosition(globalPosition)
    // drawVector3Point(globalPosition, window.scene, 0x0000ff, 0.012, true)
    const raycaster = new Raycaster()
    raycaster.near = 0
    raycaster.far = 0.1
    const perpendicularAxis = new Vector3(0, 0, 1)
    const points: Vector3[] = []
    const allPoints: Vector3[] = []
    const obbResult = getOBB(mesh, expandOffset)
    if (!obbResult) {
        console.error("Failed to get OBB")
        return { points: [], allPoints: [], }
    }
    const { bboxMesh, bboxMeshExpanded, } = obbResult
    // window.scene.add(obb)

    if (scene) {
        scene.add(bboxMesh)
        scene.add(bboxMeshExpanded)
    }

    // mesh.add(new AxesHelper(0.5))

    const worldQuaternion = new Quaternion()
    mesh.getWorldQuaternion(worldQuaternion)

    const worldPerpendicularAxis = perpendicularAxis.clone().applyQuaternion(worldQuaternion)
    const direction = worldPerpendicularAxis.clone().negate()

    function onMiddlePoint(position: Vector3) {
        if (bboxMesh && bboxMesh.geometry instanceof BufferGeometry
            && (bboxMesh.material instanceof Material || Array.isArray(bboxMesh.material))) {
            const edgePoints = findEdges(bboxMesh, position)
            edgePoints.forEach(p => {
                allPoints.push(p)
                // window.drawVector3Point(p, 0x0000ff, 0.0001)
            })
        } else {
            // Handle the case where obb is not the expected type
            console.error("obb is undefined or does not have the expected type.")
        }
    }

    function onBoundingPoint(position: Vector3) {
        if (extendSurface) {
            points.push(position)
            return
        }
        // window.drawVector3Point(position, window.scene, 0x0000ff, 0.002, 3000)

        const localXAxisOffset = new Vector3(0, 0, 0.01).applyQuaternion(worldQuaternion)
        const offsetPosition = position.clone().add(localXAxisOffset)
        // window.drawVector3Point(offsetPosition, window.scene, 0xff0000, 0.002, 3000)

        raycaster.set(offsetPosition, direction)
        // visualizeRay(raycaster, .1)

        const intersects = raycaster.intersectObject(mesh)
        if (intersects.length > 0) {
            // window.drawVector3Point(position, window.scene, 0xff0000, 0.002, 3000)
            points.push(position)
        }
    }

    walkBoundingBox({ mesh, obb: bboxMeshExpanded, onBoundingPoint, onMiddlePoint, stepSize, })
    return { points, allPoints, }
}

const findPointsAlongAxis = (points: FreePositions[], marker: Mesh) => {
    const markerPosition = new Vector3()
    marker.getWorldPosition(markerPosition)
    const globalQuaternion = new Quaternion()
    marker.getWorldQuaternion(globalQuaternion)
    const markerXAxis = new Vector3(1, 0, 0).applyQuaternion(globalQuaternion)
        .normalize()

    const pointsAlongAxis = points.filter((point: FreePositions) => {
        const directionToPoint = point.position.clone().sub(markerPosition)
            .normalize()
        const dotProduct = directionToPoint.dot(markerXAxis)
        return Math.abs(dotProduct) > 0.999999
    })
    return pointsAlongAxis
}

function getPointsArrayForEnds(
    markers: Mesh[],
    segmentLength = 0.25,
    extendSurface = false,
    stepDensity = 1,
    pieceLength?: number,
    singleLine?: boolean
) {
    const firstAndLastSides = getFirstAndLastSides(markers)
    const firstSide = markers[firstAndLastSides[0]]
    const step = convertInToCm(segmentLength) / 100 / stepDensity
    const mesh = firstSide

    const tolerance = 2.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100
    const offset = pieceLengthInCm * tolerance

    mesh.visible = true
    if (mesh.material instanceof MeshBasicMaterial) {
        mesh.material.wireframe = true
    }
    mesh.updateMatrixWorld(true)
    mesh.geometry.computeBoundingBox()

    const { points, allPoints, } = walkSurface(mesh, step, offset, extendSurface)
    const mapper = (p: Vector3) => {
        // window.drawVector3Point(p, window.scene, 0x0000ff, 0.002, 3000)

        return {
            meshName: mesh.userData.pointsMarkerName || mesh.name,
            position: p,
        }
    }
    const boundingBoxPoints = allPoints.map(mapper)
    const freePositions: FreePositions[] = points.map(mapper)
    if (singleLine) {
        // restrict to points along the axis
        return { freePositions: findPointsAlongAxis(freePositions, mesh), boundingBoxPoints, }
    }
    return { freePositions, boundingBoxPoints, }
}

function getPointsArrayForSingleEnd(mesh: any, segmentLength = 0.25, scene?: Scene) {
    const step = convertInToCm(segmentLength) / 100

    mesh.visible = true
    mesh.updateMatrixWorld(true)
    mesh.geometry.computeBoundingBox()

    const { points, allPoints, } = walkSurface(mesh, step, undefined, undefined, scene)
    const mapper = (p: Vector3) => {
        return {
            meshName: mesh.userData.pointsMarkerName || mesh.name,
            position: p,
        }
    }
    const boundingBoxPoints = allPoints.map(mapper)
    const freePositions = points.map(mapper)
    return { freePositions, boundingBoxPoints, }
}


function getDragMeshForEnds(sides: any, viewSurfaceMesh = false, viewDragMesh = false) {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const mesh = firstAndLastSides.map(key => sides[key].mesh)[0]
    const cloneMesh = mesh.clone() as Mesh
    const globalPosition = new Vector3()
    mesh.getWorldPosition(globalPosition)
    cloneMesh.position.copy(globalPosition)
    const globalQuaternion = new Quaternion()
    mesh.getWorldQuaternion(globalQuaternion)
    cloneMesh.quaternion.copy(globalQuaternion)

    const worldScale = new Vector3()
    mesh.getWorldScale(worldScale)
    cloneMesh.scale.copy(worldScale)

    cloneMesh.updateMatrixWorld(true)

    const material = new MeshBasicMaterial({
        color: 0xff0000,
        opacity: viewSurfaceMesh ? 1 : 0,
        transparent: true,
        wireframe: false,
        side: DoubleSide,
    })

    cloneMesh.material = material

    const boundingBox = new Box3().setFromObject(cloneMesh)
    const center = new Vector3()
    boundingBox.getCenter(center)

    // using a big plane for the drag collision, could also use it from the surface mesh and scale it
    const planeGeometry = new PlaneGeometry(10, 10)
    const planeMaterial = new MeshBasicMaterial({
        color: 0x0000ff,
        opacity: viewDragMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
        side: DoubleSide,
    })
    const plane = new Mesh(planeGeometry, planeMaterial)

    mesh.updateMatrixWorld(true)
    plane.position.copy(cloneMesh.getWorldPosition(new Vector3()))
    plane.quaternion.copy(cloneMesh.getWorldQuaternion(new Quaternion()))

    return {
        dragMesh: plane,
        surfaceMesh: cloneMesh,
        boundingBox,
        center,
    } as DragMesh
}

// function visualizeRay(raycaster: Raycaster, length = 10) {
//     const origin = raycaster.ray.origin
//     const direction = raycaster.ray.direction.clone().multiplyScalar(length)

//     const points = []
//     points.push(origin)
//     points.push(new Vector3().addVectors(origin, direction))

//     const geometry = new BufferGeometry().setFromPoints(points)
//     const material = new LineBasicMaterial({ color: 0xff0000, })

//     const line = new Line(geometry, material)
//     window.scene.add(line)


//     // setTimeout(() => window.scene.remove(line), 2000);
// }

export { getPointsArrayForEnds, getDragMeshForEnds, getPointsArrayForSingleEnd, }
