/* eslint-disable max-lines-per-function */
/* eslint-disable max-params */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable max-statements */
/* eslint-disable max-len */

import { Dispatch, MutableRefObject, SetStateAction } from "react"
import { ArrowHelper, AxesHelper, Box3, Box3Helper, BufferAttribute, BufferGeometry, Color, DoubleSide, Float32BufferAttribute, Group, Intersection, Line, LineBasicMaterial, Material, Matrix4, Mesh, MeshBasicMaterial, MeshMatcapMaterial, MeshStandardMaterial, Object3D, PlaneGeometry, Points, PointsMaterial, Quaternion, Raycaster, Scene, SphereGeometry, Texture, Vector2, Vector3 } from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { UseInstancedMeshSegmentedTubeExport } from "../../../../../../../../providers/instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"
import { ConnectionOfPart, PartConnectionType } from "../../../../../../../../state/scene/types"
import { getMarkerNumber, getMarkerUserData, getSideAndPosition, isInner, isInnerOrOuter, outerToInner, isPlus, isInnerOrOuterOrPlus } from "../../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { SegmentedTubeValues } from "../../../../../../../../utils/Types"
import { isCollineal, isParallel, isSameDirection, metersToInch, scale } from "../../../../../utils/utilsThree"
import { areSameConnection, areSameConnectionMesh, haveSameNumber } from "../../connector/utils/ConnectorUtils"
import { BlenderProcecedData, MarkerType, SegmentedTubeInfo, SectionType, SegmentedTubeMarkers, SegmentedTubeMarker, GetCorrectConnectionsPropsForAllIntersections } from "../types/types"
import { MarkerType as SceneMarkerType } from "../../../../../../../../utils/Types"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { getOtherPartOfTheConnection, isInConnection, isMarkerConnected } from "../../../../../../../../state/scene/util"
import { filterWithValue, ObjDictionary, toDictionary } from "../../../../../../../../../common/utils/utils"
import { ConnectionTypeAPI, SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import { convertInToCm } from "../../../../../utils/UnitUtils"
import { getPointsArrayForSingleEnd } from "../../../../../../../../providers/moveProvider/meshBuilderEnds"
import { allConnections } from "../../../../../../../../state/scene/selectors"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader"
import helvetikerFont from "three/examples/fonts/helvetiker_regular.typeface.json"
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils"



const RAY_POSITION_REDUCTION = 0.2

// eslint-disable-next-line max-len
const initSegmentedTubeInfo: (scene: Scene) => SegmentedTubeInfo = (scene: Scene) => {
    return {
        scene,
        blenderData: [],
        attachmentPoint: undefined,
        originPoint: undefined,
        instancedMeshOrigins: [],
        middleSection: {},
        startSection: {},
        endSection: {},
        newPartButtons: [],
        maxPossibleLength: 80,
        snapped: { start: false, end: false, },
        movableSection: SegmentedTubeSectionType.END,
        middlePositionsEdges: {
            min: 1,
            max: 1,
        },
        guidelines: [],
        multipleMove: {
            connected: false,
            tempConnections: [],
            snapped: { start: false, end: false, },
        },
        diagonalEdges: {
            start: false,
            end: false,
        },
        mergedMesh: undefined,
        mergedMeshOrigins: [],
        zeroLengthAttachmentPoint: undefined,

    }
}

const getSectionType = (model: Object3D) => {
    if (model.name.includes("middle")) {
        return SectionType.MIDDLE
    }
    if (model.name.includes("start")) {
        return SectionType.START
    }
    if (model.name.includes("end")) {
        return SectionType.END
    }

    throw Error("The object has no section asociated")
}

const getMainModel = (model: Object3D) => {
    const mainModel = model.children.find((c) => c.name.includes("mainModel"))
    if (!mainModel) {
        throw Error("Main model was not found")
    }
    mainModel.name = "mainModel"
    return mainModel
}

const getMarkersCollection = (model: Object3D) => {
    const isMesh = (name: string) => name.includes("mesh")
    const allMarkers = model.children.filter((m) => {
        const innerOrOuter = isInnerOrOuter(m.name)
        const plus = isPlus(m.name)
        const mesh = isMesh(m.name)
        return innerOrOuter || mesh || plus
    })
    const allMakersNameCorrection = allMarkers.map((m) => {
        (m as Mesh).material = new MeshBasicMaterial({
            alphaTest: 0,
            visible: false,
            //depthTest: false,
            wireframe: true,
            //color: "purple",
            opacity: 50,
            side: DoubleSide,
            name: m.name,
        })
        if (isInner(m.name)) {
            return {
                model: m,
                type: MarkerType.INNER,
                id: m.name.split("inner")[1],
            }
        } else if (isInnerOrOuter(m.name)) {
            return {
                model: m,
                type: MarkerType.OUTER,
                id: m.name.split("outer")[1],
            }
        } else if (isPlus(m.name)) {
            return {
                model: m,
                type: MarkerType.PLUS,
                id: m.name.split("plus")[1],
            }
        } else {
            return {
                model: m,
                type: "MESH",
                id: m.name.split("mesh")[1],
            }
        }
    })

    const groupedMarkerdById: {
        [id: string]: {
            inner?: {
                model: Object3D,
                type: MarkerType.INNER,
                id: string,
            },
            outer?: {
                model: Object3D,
                type: MarkerType.OUTER,
                id: string,
            },
            mesh?: {
                model: Object3D,
                type: "MESH",
                id: string,
            },
            plus?: {
                model: Object3D,
                type: MarkerType.PLUS,
                id: string,
            },
        },
    } = {}

    allMakersNameCorrection.forEach((m) => {
        if (groupedMarkerdById[m.id]) {
            groupedMarkerdById[m.id] = {
                ...groupedMarkerdById[m.id],
                [m.type]: m,
            }
        } else {
            groupedMarkerdById[m.id] = {
                [m.type]: m,
            }
        }
    })

    return groupedMarkerdById
}

const processData = (gltf: GLTF) => {
    const modelData: BlenderProcecedData[] = []
    const sectionGroups = gltf.scene.children
    if (sectionGroups.length !== 3) {
        throw Error("The blender file must contain 3 groups: middleSection, startSection and endSection")
    }
    sectionGroups.forEach((modelSection) => {
        modelData.push({
            sectionType: getSectionType(modelSection),
            mainModel: getMainModel(modelSection) as Mesh,
            markerCollection: getMarkersCollection(modelSection),
            sectionOrigin: modelSection,
        })
    })

    return modelData
}

const createPool = (partId: string, modelName: string, mesh: Mesh, scene: Scene, instancedMeshHandler: UseInstancedMeshSegmentedTubeExport, onTubeSelected?: () => void) => {
    instancedMeshHandler.createPool(partId, modelName, mesh, scene, onTubeSelected)
}

const updateInstancedTransforms = (
    middleProvider: UseInstancedMeshSegmentedTubeExport,
    startProvider: UseInstancedMeshSegmentedTubeExport,
    endProvider: UseInstancedMeshSegmentedTubeExport,
    info: MutableRefObject<SegmentedTubeInfo>,
    segmentScaleFactor?: number,
    skipBoundingSphere?: boolean,
    movableSection?: SegmentedTubeSectionType
) => {

    const middleOrigins = [...info.current.instancedMeshOrigins,]
    const startOrigin = middleOrigins.shift()
    const endOrigin = middleOrigins.pop()

    const origins = [] as Object3D[]
    if (startOrigin) {
        origins.push(startOrigin)
    }
    if (middleOrigins.length > 0) {
        origins.push(...middleOrigins)
    }
    if (endOrigin) {
        origins.push(endOrigin)
    }


    if (segmentScaleFactor) {

        const currentZScale = origins[0].scale.z

        if (currentZScale !== segmentScaleFactor) {
            const groupForAllOrigins = new Group()
            const parentForAllOrigins = origins[0].parent

            const markersByDirection = movableSection === SegmentedTubeSectionType.START ? info.current.startSection : info.current.endSection

            let anchorMarker: Object3D | undefined
            let anchorPositionBefore: Vector3 | undefined
            let firstKey: string | undefined

            if (markersByDirection) {
                firstKey = Object.keys(markersByDirection)[0]
                anchorMarker = markersByDirection[firstKey].inner!
                anchorPositionBefore = MeshUtils.copyWorldPosition(anchorMarker)
            }

            // Add all origins to the group
            origins.forEach(o => {
                groupForAllOrigins.add(o.clone()) // Clone to avoid modifying original objects
            })

            // Apply scale to the group
            groupForAllOrigins.scale.z = segmentScaleFactor
            groupForAllOrigins.updateMatrixWorld(true)

            // Transfer the scaled transformation back to individual origins
            origins.forEach((o, index) => {
                const scaledOrigin = groupForAllOrigins.children[index]
                o.matrix.copy(scaledOrigin.matrix)
                o.matrix.decompose(o.position, o.quaternion, o.scale)
                o.updateMatrixWorld(true)
            })

            // Only apply offset if there's no movable section
            if (movableSection && anchorMarker && anchorPositionBefore) {
                // Calculate the offset caused by scaling
                const anchorPositionAfter = MeshUtils.copyWorldPosition(anchorMarker)
                const offset = anchorPositionBefore.sub(anchorPositionAfter)

                // Apply the offset to all origins to maintain the original position
                origins.forEach(o => {
                    o.position.add(offset)
                    o.updateMatrixWorld(true)
                })
            }

            // Clean up
            groupForAllOrigins.clear()

            if (parentForAllOrigins) {
                origins.forEach(o => {
                    parentForAllOrigins.add(o)
                })
            }
        }
    }

    // Update middle origins
    middleProvider.updateInstancedMeshesTranforms(middleOrigins, skipBoundingSphere, segmentScaleFactor ?? undefined)

    if (startOrigin) {
        startProvider.updateInstancedMeshesTranforms([startOrigin,], skipBoundingSphere, segmentScaleFactor ?? undefined)
    }

    if (endOrigin) {
        endProvider.updateInstancedMeshesTranforms([endOrigin,], skipBoundingSphere, segmentScaleFactor ?? undefined)
    }
}

const cleanUpPreviousOrigins = (info: React.MutableRefObject<SegmentedTubeInfo>) => {
    const origins = info.current.instancedMeshOrigins

    // Cleanup previous origins
    if (origins.length > 0) {
        origins.forEach((p) => {
            p.removeFromParent();
            (p as unknown) = null
        })
        info.current.instancedMeshOrigins = []
    }
}

const cleanUpPreviousMarkers = (info: React.MutableRefObject<SegmentedTubeInfo>) => {
    info.current.startSection = {}
    info.current.endSection = {}
    info.current.middleSection = {}
}


const createMergedMesh = (info: MutableRefObject<SegmentedTubeInfo>, partId: string, moonTexture?: Texture, color?: string, scaleFactor?: number, debug?: boolean) => {
    const mainModelGeometries: BufferGeometry[] = []

    //console.log("info", info.current)

    //const point1 = performance.now()

    info.current.instancedMeshOrigins.forEach((origin) => {
        origin.children.forEach((child) => {
            if (child.name === "mainModel" && child instanceof Mesh) {
                // Create a new Matrix4 to store the world transformation
                const worldMatrix = new Matrix4()


                if (scaleFactor) {
                    child.scale.z = scaleFactor
                }

                // Get the world matrix of the child
                child.updateWorldMatrix(true, false)
                worldMatrix.copy(child.matrixWorld)

                // Clone the geometry
                const clonedGeometry: BufferGeometry = child.geometry.clone()

                // Apply the world matrix to the cloned geometry
                clonedGeometry.applyMatrix4(worldMatrix)

                mainModelGeometries.push(clonedGeometry)

                // Optional: Change the material of the original child
                //console.log(child, "child")
                //child.material = new MeshBasicMaterial({ color: "red", wireframe: true, visible: true })
                child.removeFromParent()
            }
        })
    })

    if (mainModelGeometries.length > 0) {

        const createEmptyUVAttribute = (geometry: BufferGeometry): BufferAttribute => {
            const vertexCount = geometry.attributes.position.count
            return new BufferAttribute(new Float32Array(vertexCount * 2), 2)
        }

        const createDefaultColorAttribute = (geometry: BufferGeometry): BufferAttribute => {
            const vertexCount = geometry.attributes.position.count
            const colors = new Float32Array(vertexCount * 3)
            colors.fill(1) // Set all colors to white (1, 1, 1)
            return new BufferAttribute(colors, 3)
        }

        const normalizeGeometry = (geometry: BufferGeometry, requiredAttributes: string[]): BufferGeometry => {
            const normalizedGeometry = geometry.clone()

            requiredAttributes.forEach(attr => {
                if (!normalizedGeometry.attributes[attr]) {
                    switch (attr) {
                        case "normal":
                            normalizedGeometry.computeVertexNormals()
                            break
                        case "uv":
                            normalizedGeometry.setAttribute("uv", createEmptyUVAttribute(normalizedGeometry))
                            break
                        case "color":
                            normalizedGeometry.setAttribute("color", createDefaultColorAttribute(normalizedGeometry))
                            break
                        default:
                            break
                    }
                }
            })

            // Remove any attributes that are not in the required list
            Object.keys(normalizedGeometry.attributes).forEach(attr => {
                if (!requiredAttributes.includes(attr)) {
                    normalizedGeometry.deleteAttribute(attr)
                }
            })

            return normalizedGeometry
        }

        let mergedGeometry = BufferGeometryUtils.mergeGeometries(mainModelGeometries)

        if (!mergedGeometry) {
            const requiredAttributes = ["position", "normal", "uv",] // Add or remove attributes as needed
            const normalizedGeometries = mainModelGeometries.map(geo => normalizeGeometry(geo, requiredAttributes))
            mergedGeometry = BufferGeometryUtils.mergeGeometries(normalizedGeometries)
        }

        if (!mergedGeometry) {
            return
        }


        // Create a new material for the merged mesh
        const matcapMaterial = new MeshMatcapMaterial({
            color: color ?? "white",
            matcap: moonTexture ?? undefined,
        })

        const mergedMesh = new Mesh(mergedGeometry, matcapMaterial)
        mergedMesh.name = `MergedMesh_${partId}`
        mergedMesh.userData = {
            type: "MergedMesh", partId: partId,
        }
        //mergedMesh.position.set(0, 0, 0)
        //mergedMesh.rotation.set(0, 0, 0)
        mergedMesh.updateMatrix()

        // Add the merged mesh to the scene

        //console.log(info.current.mergedMesh, "mergedMesh")
        //console.log(info.current.attachmentPoint, "attachmentPoint")
        //console.log(info.current.originPoint, "originPoint")

        if (info.current.mergedMesh && info.current.originPoint) {
            //info.current.attachmentPoint.remove(info.current.mergedMesh)
            info.current.originPoint.remove(info.current.mergedMesh)
        }
        if (info.current.originPoint) {
            info.current.mergedMesh = mergedMesh
            info.current.originPoint.attach(mergedMesh)
            /*if (debug && mergedMesh instanceof Mesh) {
                mergedMesh.material = new MeshBasicMaterial({
                    color: 0xcccccc,  // White color
                    wireframe: true,
                    transparent: true,
                    opacity: 0.5,    // Very low opacity
                    depthWrite: false // Prevents z-fighting with other transparent objects
                })
            }*/
            // Reset the position and rotation of the merged mesh

        }


    }
    const point2 = performance.now()
    //console.log("createMergedMesh", point2 - point1)
}

const removeEmptyOrigins = (info: MutableRefObject<SegmentedTubeInfo>) => {
    //console.log(info.current.instancedMeshOrigins, "info.current.instancedMeshOrigins")
    info.current.instancedMeshOrigins = info.current.instancedMeshOrigins.filter((origin) => {
        if (origin.children.length === 0) {
            // Remove the empty origin from the scene
            //console.log(origin, "origin")
            origin.removeFromParent()
            info.current.scene.remove(origin)
            return false
        }
        return true
    })
}

const createInstancedMeshOrigins = (
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    middleProvider: UseInstancedMeshSegmentedTubeExport | undefined,
    startProvider: UseInstancedMeshSegmentedTubeExport | undefined,
    endProvider: UseInstancedMeshSegmentedTubeExport | undefined,
    tubeLength: number,
    tubeNegativeLength: number,
    segmentScaleFactor?: number,
    moonTexture?: Texture,
    color?: string,
    debug?: boolean,
) => {

    if (!info.current || !info.current.originPoint) {
        throw new Error("Pivot point not defined")
    }

    const negativeLengthToUse = tubeNegativeLength

    cleanUpPreviousOrigins(info)
    cleanUpPreviousMarkers(info)

    const pivot = info.current.originPoint
    const originPos = MeshUtils.copyWorldPosition(pivot)
    const originRot = MeshUtils.copyWorldQuaternion(pivot)

    const startLengthInMeters = tube.startSegmentLength / metersToInch
    const endLengthInMeters = tube.endSegmentLength / metersToInch
    const segmentLengthInMeters = tube.segmentLength / metersToInch

    const originDir = MeshUtils.copyWorldDirection(pivot).normalize()
    originPos.addVectors(originPos, originDir.multiplyScalar(segmentLengthInMeters * negativeLengthToUse))

    // Segments length plus start and end sections
    const length = tubeLength + negativeLengthToUse + 2

    //this is because it may have been scaled before
    info.current.originPoint.scale.set(1, 1, 1)


    //console.log(length, "length")
    //console.log(tubeLength, "tubeLength")
    //console.log(tubeNegativeLength, "tubeNegativeLength")

    // Create new origins
    for (let i = 0; i < length; i++) {
        const placeholder = new Object3D()
        placeholder.position.copy(originPos)
        placeholder.rotation.setFromQuaternion(originRot)
        placeholder.name = "Instanced Mesh Origin"
        pivot.attach(placeholder)

        const iaux = i - 1 < 0 ? 0 : i - 1
        let distance = 0
        if (i === 1) {
            distance = startLengthInMeters
        }
        if (i > 1) {
            distance = (segmentLengthInMeters * iaux) + startLengthInMeters
        }
        if (i === length - 1) {
            distance = (segmentLengthInMeters * iaux) + startLengthInMeters + endLengthInMeters
        }
        placeholder.position.setZ(placeholder.position.z - (distance * (segmentScaleFactor ?? 1)))
        info.current.instancedMeshOrigins.push(placeholder)
    }
    //console.log("info", info, tube, "tube", tubeNegativeLength, "negative length")

    createMarkers(info, tube, negativeLengthToUse, tubeLength, segmentScaleFactor ?? 1, debug ?? false)

    const point1 = performance.now()
    createMergedMesh(info, tube.id, moonTexture, color, segmentScaleFactor ?? 1, false,)
    const point2 = performance.now()
    //console.log("createMergedMesh", point2 - point1)
    removeEmptyOrigins(info)
    //console.log(info.current.instancedMeshOrigins, "info.current.instancedMeshOrigins after")
    //updateInstancedTransforms(middleProvider, startProvider, endProvider, info, segmentScaleFactor ?? tube.segmentScaleFactor ?? undefined, true)
}


const saveMarkerInfo = (marker: Object3D, info: React.MutableRefObject<SegmentedTubeInfo>, sectionType: SectionType) => {
    const markerNumber = getMarkerNumber(marker.name)
    const { markerSide, markerPosition, } = getSideAndPosition(marker.name)
    switch (sectionType) {
        case SectionType.START:
            if (!info.current.startSection[markerNumber]) {
                info.current.startSection[markerNumber] = { inner: undefined, outer: undefined, }
            }
            if (isInner(marker.name)) {
                info.current.startSection[markerNumber].inner = marker as Mesh
            } else if (isInnerOrOuter(marker.name)) {
                info.current.startSection[markerNumber].outer = marker as Mesh
            } else if (isPlus(marker.name)) {
                info.current.startSection[markerNumber].plus = marker as Mesh
            } else if (marker.name.includes("mainModel")) {
                info.current.startSection[markerNumber].mainModel = marker as Mesh
            } else {
                info.current.startSection[markerNumber].mesh = marker as Mesh
            }
            break
        case SectionType.END:
            if (!info.current.endSection[markerNumber]) {
                info.current.endSection[markerNumber] = { inner: undefined, outer: undefined, }
            }
            if (isInner(marker.name)) {
                info.current.endSection[markerNumber].inner = marker as Mesh
            } else if (isInnerOrOuter(marker.name)) {
                info.current.endSection[markerNumber].outer = marker as Mesh
            } else if (isPlus(marker.name)) {
                info.current.endSection[markerNumber].plus = marker as Mesh
            } else if (marker.name.includes("mainModel")) {
                info.current.endSection[markerNumber].mainModel = marker as Mesh
            } else {
                info.current.endSection[markerNumber].mesh = marker as Mesh
            }
            break
        case SectionType.MIDDLE:
            if (markerSide) {
                if (!info.current.middleSection[markerSide]) {
                    info.current.middleSection[markerSide] = {}
                }
                if (!info.current.middleSection[markerSide][markerPosition]) {
                    info.current.middleSection[markerSide][markerPosition] = { inner: undefined, outer: undefined, }
                }
                if (isInner(marker.name)) {
                    info.current.middleSection[markerSide][markerPosition].inner = marker as Mesh
                }
                else if (isPlus(marker.name)) {
                    info.current.middleSection[markerSide][markerPosition].plus = marker as Mesh
                }
                else if (marker.name.includes("mainModel")) {
                    info.current.middleSection[markerSide][markerPosition].mainModel = marker as Mesh
                }
                else if (marker.name.includes("mesh")) {
                    info.current.middleSection[markerSide][markerPosition].mesh = marker as Mesh
                }
                else {
                    info.current.middleSection[markerSide][markerPosition].outer = marker as Mesh
                }
            }
            break
        default:
            throw new Error("Section type doesn't exist")
    }
}
const scalePlane = (plane: Mesh, realBoundary: number) => {

    // Calculate the desired scale factor for the Y axis
    const currentSize = new Vector3()

    if (plane.geometry && plane.geometry.boundingBox) {

        plane.geometry.computeBoundingBox()
        plane.geometry.boundingBox.getSize(currentSize)
        const scaleFactorY = (2 * realBoundary) / currentSize.y

        // Apply the scale factor to the plane
        plane.scale.setY(scaleFactorY)

        // Reposition the plane to keep the center the same
        const center = new Vector3()
        plane.geometry.boundingBox.getCenter(center)
        plane.position.sub(center.multiplyScalar(scaleFactorY - 1))
    }
}

const drawPoints = (scene: Scene, points: Vector3[], color = 0xff0000) => {
    const geometry = new BufferGeometry()
    const vertices = new Float32BufferAttribute(points.flatMap(p => [p.x, p.y, p.z,]), 3)
    geometry.setAttribute("position", vertices)

    const material = new PointsMaterial({ color, size: 0.01, }) // Adjust size as needed
    const pointCloud = new Points(geometry, material)
    scene.add(pointCloud)
}

const cloneMarker = (
    tube: SegmentedTubeValues,
    blenderData: BlenderProcecedData,
    origin: Object3D,
    info: MutableRefObject<SegmentedTubeInfo>,
    index?: number,
    totalLength?: number,
    positiveLength?: number,
    negativeLength?: number,
    segmentScaleFactor?: number,
    debug?: boolean,
) => {
    const aligmentPlaneAdded = {
        start: false,
        end: false,
    }



    blenderData.sectionOrigin.traverse((object) => {

        if (isInnerOrOuterOrPlus(object.name)) {
            const marker = tube.markers.find(marker => haveSameNumber(object.name, marker.name))!
            const clone = object.clone(true)
            if (index || index === 0) {
                clone.name = `${clone.name}_${index}`
            }

            if (blenderData.sectionType === SectionType.MIDDLE && (index === 0 && index !== undefined)) {
                if (clone instanceof Mesh && debug) {
                    clone.material = new MeshBasicMaterial({
                        color: "red",
                        depthTest: true,
                        wireframe: true,

                    })
                }

                //console.log(positiveLength, negativeLength, "positive and negative length")

                // ... inside your function
                if ((positiveLength || negativeLength) && clone instanceof Mesh) {

                    const singleSegmentLength = convertInToCm(tube.segmentLength) / 100

                    const currentSize = new Vector3()
                    clone.geometry.computeBoundingBox()
                    clone.geometry.boundingBox.getSize(currentSize)
                    const width = currentSize.x
                    const scaleFactorForInnerOuter = width / singleSegmentLength

                    //console.log(scaleFactorForInnerOuter, "scaleFactorForInnerOuter")
                    //console.log(singleSegmentLength, "singleSegmentLength")
                    //console.log(currentSize, "currentSize")

                    const scaleMatrixForInnerOuter = new Matrix4().makeScale(1 / scaleFactorForInnerOuter, 1, 1)
                    clone.geometry.applyMatrix4(scaleMatrixForInnerOuter)


                    // Calculate the desired width
                    const totalActualWidth = ((positiveLength ?? 0) + (negativeLength ?? 0))

                    //console.log(tube.segmentLength, "tube.segmentLength")
                    //console.log(positiveLength, negativeLength, "positive and negative length")
                    //console.log(totalLength, "totalLength")


                    // Calculate the scale factor
                    const scaleFactor = totalActualWidth

                    //console.log(segmentScaleFactor, "segmentScaleFactor")
                    //console.log(scaleFactor, "scaleFactorX")

                    // Create a scaling matrix
                    const scaleMatrix = new Matrix4().makeScale(scaleFactor, 1, 1)

                    // Apply the matrix to the geometry of the temporary clone
                    clone.geometry.applyMatrix4(scaleMatrix)

                    // Update the geometry of the temporary clone
                    clone.geometry.computeVertexNormals()

                    //console.log(singleSegmentLength, "singleSegmentLength")

                    const positiveOffset = singleSegmentLength / 2 * (positiveLength ?? 0)
                    const negativeOffset = singleSegmentLength / 2 * (negativeLength ?? 0)

                    //console.log(positiveOffset, negativeOffset, "positive and negative offset")
                    const offset = positiveOffset - negativeOffset - singleSegmentLength / 2

                    //const offsetForSingleSegment = convertInToCm(tube.segmentLength)/100 * 0.5 * (segmentScaleFactor ?? 1)

                    //console.log(offset, "offset")

                    // Apply changes to the original clone
                    clone.position.setZ(clone.position.z - ((offset)))

                    if (marker.boundary) {
                        const realBoundary = convertInToCm(marker.boundary) / 100 * 2
                        // Calculate the current height of the geometry
                        const currentHeight = currentSize.y

                        // Calculate the scale factor for Y axis
                        const scaleFactorY = realBoundary / currentHeight

                        // Create a scaling matrix for Y axis
                        const scaleMatrixY = new Matrix4().makeScale(1, scaleFactorY, 1)

                        // Apply the Y scaling to the geometry
                        clone.geometry.applyMatrix4(scaleMatrixY)

                    }



                    //add to the origin and save the marker info
                    clone.userData = {
                        id: marker.id,
                        markerName: clone.name,
                        partId: tube.id,
                        iELength: marker.iELenght,
                        sizeId: marker.sizeId,
                        lateralFacing: marker.lateralFacing,
                        boundary: marker.boundary,
                        userDataType: "MarkerUserData",
                        type: SceneMarkerType.COLLISION,
                        middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                    }
                    if (debug) {
                        clone.visible = true
                    } else {
                        clone.visible = false
                    }
                    origin.add(clone)
                    clone.updateMatrixWorld()
                    //clone.matrixAutoUpdate = true


                    saveMarkerInfo(clone, info, blenderData.sectionType)
                    ///

                    if (object.name.includes("inner")) {
                        const meshClone = clone.clone(true)
                        if (debug && meshClone instanceof Mesh) {
                            meshClone.visible = true
                            meshClone.material = new MeshBasicMaterial({
                                color: "red",
                                side: DoubleSide,
                                transparent: true,
                                opacity: 0.3,
                            })
                        } else {
                            meshClone.visible = false
                            meshClone.material.transparent = false
                            meshClone.material.opacity = 1
                        }


                        if (index || index === 0) {
                            const cleanedName = meshClone.name.replace(/(inner|outer)_?/, "")
                            meshClone.name = `mesh${cleanedName}`
                        }


                        const worldPosition = new Vector3()
                        clone.getWorldPosition(worldPosition)

                        //console.log(meshClone.name, worldPosition, "worldPosition when being sent to getPointsArrayForSingleEnd")


                        //const axesHelper = new AxesHelper(0.05)
                        //axesHelper.name = "ignore_axesHelper"
                        //meshClone.add(axesHelper)


                        //const { freePositions, } = getPointsArrayForSingleEnd(meshClone, 0.25)

                        //console.log(freePositions, "freePositions")

                        meshClone.userData = {
                            id: marker.id,
                            name: meshClone.name,
                            markerName: meshClone.name,
                            partId: tube.id,
                            iELength: marker.iELenght,
                            sizeId: marker.sizeId,
                            lateralFacing: marker.lateralFacing,
                            boundary: marker.boundary,
                            userDataType: "MarkerUserData",
                            type: SceneMarkerType.COLLISION,
                            middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                        }
                        if (segmentScaleFactor) {
                            meshClone.scale.set(segmentScaleFactor, 1, 1)
                        }
                        origin.add(meshClone)
                        meshClone.updateMatrixWorld()

                        saveMarkerInfo(meshClone, info, blenderData.sectionType)
                    }
                }
            }

            else if (blenderData.sectionType === SectionType.MIDDLE && (index !== 0 && index !== undefined)) {
                if (clone instanceof Mesh && debug) {
                    clone.material = new MeshBasicMaterial({
                        color: "red",
                        depthTest: true,
                        wireframe: true,

                    })
                }

                //add to the origin and save the marker info
                clone.userData = {
                    id: marker.id,
                    markerName: clone.name,
                    partId: tube.id,
                    iELength: marker.iELenght,
                    sizeId: marker.sizeId,
                    lateralFacing: marker.lateralFacing,
                    boundary: marker.boundary,
                    userDataType: "MarkerUserData",
                    type: SceneMarkerType.COLLISION,
                    middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                }
                if (debug) {
                    clone.visible = true
                } else {
                    clone.visible = false
                }
                origin.add(clone)
                clone.updateMatrixWorld(true) // Force update of world matrix

                // Store the world position and quaternion
                const worldPosition = new Vector3()
                const worldQuaternion = new Quaternion()
                clone.getWorldPosition(worldPosition)
                clone.getWorldQuaternion(worldQuaternion)

                //saveMarkerInfo(clone, info, blenderData.sectionType)

                //console.log(info.current.middleSection, "info.current.middleSection")

                const isInner = clone.name.includes("inner")
                const isOuter = clone.name.includes("outer")

                const { markerSide, markerPosition, } = getSideAndPosition(clone.name)


                if ((!markerSide || !markerPosition) && markerSide) {
                    console.log("markerSide or markerPosition not found", markerSide, markerPosition, clone.name, info.current.middleSection[markerSide][markerPosition])
                }

                //console.log(info.current.middleSection[markerNumber], "maker.name", marker.name)

                /*if (!info.current.middleSection[markerSide]) {
                    console.log(markerPosition, clone.name, "markerPosition in not found", "makerside was:", markerSide, info.current.middleSection)
                    return
                    console.log("info.current.middleSection[markerNumber] not found", markerSide, markerPosition, clone.name, info.current)
                }*/

                if (isInner && markerSide) {
                    //console.log(markerSide, markerPosition, "markerSide and markerPosition")

                    const innerMarker = info.current.middleSection[markerSide][0].inner
                    if (!innerMarker) {
                        console.log("innerMarker not found", markerSide, markerPosition, clone.name, info.current.middleSection[markerSide][0])
                    }
                    if (innerMarker && !innerMarker.userData.middleRefs) {
                        innerMarker.userData.middleRefs = []
                    }
                    const innerLocalPosition = new Vector3()
                    innerMarker?.worldToLocal(innerLocalPosition.copy(worldPosition))
                    // Add the new reference to both inner and outer
                    if (innerMarker) {
                        //console.log(markerPosition, "markerPosition")
                        innerMarker.userData.middleRefs.push({
                            refName: clone.name,
                            localOffset: innerLocalPosition,
                        })
                    }

                }

                if (isOuter && markerSide) {
                    const outerMarker = info.current.middleSection[markerSide][0].outer
                    if (outerMarker && !outerMarker.userData.middleRefs) {
                        outerMarker.userData.middleRefs = []
                    }
                    const outerLocalPosition = new Vector3()
                    outerMarker?.worldToLocal(outerLocalPosition.copy(worldPosition))
                    if (outerMarker) {
                        outerMarker.userData.middleRefs.push({
                            refName: clone.name,
                            localOffset: outerLocalPosition,
                        })
                    }
                }


                // Detach from origin
                clone.removeFromParent()

                // Set the stored world position and quaternion
                //clone.position.copy(worldPosition)
                //clone.quaternion.copy(worldQuaternion)
                //clone.updateMatrixWorld()
                ///


            } else if (blenderData.sectionType === SectionType.START || blenderData.sectionType === SectionType.END) {
                //console.log("here2", origin, marker.name,)
                clone.userData = {
                    id: marker.id,
                    markerName: clone.name,
                    partId: tube.id,
                    iELength: marker.iELenght,
                    sizeId: marker.sizeId,
                    lateralFacing: marker.lateralFacing,
                    boundary: marker.boundary,
                    userDataType: "MarkerUserData",
                    type: SceneMarkerType.COLLISION,
                    middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                }

                if (clone instanceof Mesh && debug) {
                    clone.visible = true
                    clone.material = new MeshBasicMaterial({
                        color: "red",
                        depthTest: true,
                        wireframe: true,

                    })
                } else {
                    clone.visible = false
                }

                origin.add(clone)
                saveMarkerInfo(clone, info, blenderData.sectionType)
                //console.log("here", origin, marker.name)

            }

            //////////////////////////////

            if (((marker.position === SegmentedTubeSectionType.START && !aligmentPlaneAdded.start)
                || (marker.position === SegmentedTubeSectionType.END && !aligmentPlaneAdded.end))
                && !marker.lateralFacing
            ) {

                const center = clone.position.clone()
                clone.userData.localSlidePoints = [{ position: center, },]

                const globalCenter = clone.localToWorld(center.clone())
                //drawPoints(info.current.scene, [globalCenter,], 0x000000,)

                const alignmentPlaneGeometry = new PlaneGeometry(80, 90)
                const aligmentPlaneMaterial = new MeshBasicMaterial({
                    alphaTest: 0,
                    visible: false,
                    color: "red",
                    side: DoubleSide,
                })
                const aligmentPlane = new Mesh(alignmentPlaneGeometry, aligmentPlaneMaterial)
                aligmentPlane.name = `${clone.name}_aligmentPlane`
                aligmentPlane.userData = {
                    type: SceneMarkerType.COLLISION_PLANE, partId: tube.id,
                }
                if (marker.position === SegmentedTubeSectionType.START) {
                    aligmentPlaneAdded.start = true
                } else {
                    aligmentPlaneAdded.end = true
                }
                clone.add(aligmentPlane)
                saveMarkerInfo(clone, info, blenderData.sectionType)
            }

        } else if (object.name.includes("mesh")) {
            const marker = tube.markers.find(marker => areSameConnectionMesh(object.name, marker.name))!
            const clone = object.clone(true)
            if (index || index === 0) {
                clone.name = `${clone.name}_${index}`
            }


            if (debug && clone instanceof Mesh) {
                clone.material = new MeshBasicMaterial({
                    color: "red",
                    side: DoubleSide,
                    transparent: true,
                    opacity: 0.3,
                })
                clone.visible = true
            } else {
                clone.visible = false
            }

            clone.userData = {
                id: marker.id,
                markerName: clone.name,
                partId: tube.id,
                iELength: marker.iELenght,
                sizeId: marker.sizeId,
                lateralFacing: marker.lateralFacing,
                boundary: marker.boundary,
                userDataType: "MarkerUserData",
                type: SceneMarkerType.COLLISION,
                middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
            }

            origin.add(clone)

            //const axesHelper = new AxesHelper(0.05) // Size of the axes (adjust as needed)
            //axesHelper.name = "ignore_axesHelper" // This name helps identify it later if needed
            //clone.add(axesHelper)

            saveMarkerInfo(clone, info, blenderData.sectionType)

        }
        else if (object.name.includes("mainModel")) {
            const clone = object.clone()
            clone.visible = false
            //console.log("clone", clone)
            origin.add(clone)
        }
    }
    )
}



const createMarkers = (info: React.MutableRefObject<SegmentedTubeInfo>, tube: SegmentedTubeValues, tubeNegativeLength: number, tubeLength: number, segmentScaleFactor: number, debug: boolean) => {
    const middleInfo = info.current.blenderData.find((bd) => bd.sectionType === SectionType.MIDDLE)
    const startInfo = info.current.blenderData.find((bd) => bd.sectionType === SectionType.START)
    const endInfo = info.current.blenderData.find((bd) => bd.sectionType === SectionType.END)


    let zeroFound = false
    const negativeMiddleMarkers: { origin: Object3D, markerIndex: number, totalLength: number, }[] = []

    info.current.instancedMeshOrigins.forEach((origin, index) => {
        const isLast = info.current.instancedMeshOrigins.length - 1 === index
        const isFirst = index === 0
        const totalLength = info.current.instancedMeshOrigins.length - 2
        const markerIndex = index - tubeNegativeLength - 1

        if (isFirst && startInfo && middleInfo) {
            cloneMarker(tube, startInfo, origin, info, undefined, undefined, undefined, undefined, undefined, debug)
        } else if (isLast && endInfo) {
            cloneMarker(tube, endInfo, origin, info, undefined, undefined, undefined, undefined, undefined, debug)
        } else if (middleInfo) {
            if (markerIndex < 0) {
                negativeMiddleMarkers.push({ origin, markerIndex, totalLength, })
            } else {
                zeroFound = true
                //console.log(tube.id, markerIndex, "markerIndex")
                cloneMarker(tube, middleInfo, origin, info, markerIndex, totalLength, tubeLength, tubeNegativeLength, segmentScaleFactor ?? undefined, debug)
            }
        }
    })

    // Process negative middle markers after everything else
    negativeMiddleMarkers.forEach(({ origin, markerIndex, totalLength, }) => {
        if (middleInfo) {
            //console.log(tube.id, markerIndex, "negative markerIndex")
            cloneMarker(tube, middleInfo, origin, info, markerIndex, totalLength, tubeLength, tubeNegativeLength, segmentScaleFactor ?? undefined, debug)
        }
    })

    if (!zeroFound) {
        console.log("zeroFound not found", zeroFound)
    }
}

const removeAttachmentPointsOriginWithNoChildren = (info: React.MutableRefObject<SegmentedTubeInfo>, partId: string, newAttachment: Object3D) => {
    const attachmentPoints = info.current.scene.children.filter((child) => child.name.includes("Attachment Point Origin"))
    attachmentPoints.forEach((attachmentPoint) => {
        if (attachmentPoint.children.length === 0 && attachmentPoint.userData.partId === partId && attachmentPoint !== newAttachment) {
            attachmentPoint.removeFromParent()
        }
    })
}

const setAtachmentPoint = (newAttachmentPoint: Object3D, info: React.MutableRefObject<SegmentedTubeInfo>, setter?: (newAttachmentPoint: Object3D) => void, offset?: Vector3) => {
    const newAttachment = new Object3D()
    newAttachment.name = "Attachment Point Origin"
    const firstStartSectionKey = Object.keys(info.current.startSection)[0]
    const partId = info.current.startSection[firstStartSectionKey]?.inner?.userData.partId
    newAttachment.userData = {
        partId,
    }
    const wp = MeshUtils.copyWorldPosition(newAttachmentPoint)
    const wr = MeshUtils.copyWorldQuaternion(newAttachmentPoint)
    newAttachment.position.copy(wp)
    if (offset) {
        newAttachment.position.copy(offset)
    }
    newAttachment.setRotationFromQuaternion(wr)
    info.current.scene.attach(newAttachment)
    if (info.current.originPoint) {
        newAttachment.attach(info.current.originPoint)
        info.current.attachmentPoint = newAttachment
        if (typeof setter === "function") {
            setter(newAttachment)
        }
        removeAttachmentPointsOriginWithNoChildren(info, partId, newAttachment)
    }
}

const findIntersections = (scene: Scene, targetBox: Box3, partId: string) => {
    const intersectingObjects: Object3D[] = []

    const isExcluded = (object: Object3D, partId: string) => {
        return object.userData.partId === partId
    }

    /*const helper = new Box3Helper(targetBox)
    helper.material.depthTest = false
    const helperName = "targetBox"
    helper.name = helperName
    scene.add(helper)*/

    targetBox.expandByScalar(0.001)

    scene.traverse((object) => {
        if (object instanceof Mesh && object.name.includes("inner") && !object.name.includes("Boundary") && !object.name.includes("aligmentPlane") && !object.name.includes("ignore") && !isExcluded(object, partId)) {
            // Compute the object's geometry bounds in world space
            object.geometry.computeBoundingBox()
            const geometryBox = object.geometry.boundingBox.clone()
            geometryBox.applyMatrix4(object.matrixWorld)

            if (geometryBox.intersectsBox(targetBox)) {
                intersectingObjects.push(object)

                // Draw the bounding box for the intersecting object
                /*const helper = new Box3Helper(geometryBox)
                helper.material.depthTest = false
                helper.name = "something"
                scene.add(helper)*/
            }
        }
    })
    return intersectingObjects
}



const drawLocalSlidePoints = (scene: Scene, obj: Object3D, color: number) => {
    if (obj.userData.localSlidePoints) {
        obj.userData.localSlidePoints.forEach((point: { position: Vector3, }) => {
            const globalPosition = new Vector3()
            obj.localToWorld(globalPosition.copy(point.position))

            const sphereGeometry = new SphereGeometry(0.001, 32, 32)
            const sphereMaterial = new MeshBasicMaterial({ color: color, }) // Green color for the points
            const sphere = new Mesh(sphereGeometry, sphereMaterial)
            sphere.position.copy(globalPosition)
            scene.add(sphere)
        })
    }
}

const groupByMarkerIdAndSort = (result: { marker: any, intersectedObjects: any[], }[],) => {

    const markerMap: { [key: string]: { marker: any, intersectedObjects: any[], }, } = {}

    result.forEach(item => {
        const markerUUID = item.marker.uuid

        if (!markerMap[markerUUID]) {
            markerMap[markerUUID] = { ...item, intersectedObjects: [], }
        }

        item.intersectedObjects.forEach(intersectedObject => {
            const existingObject = markerMap[markerUUID].intersectedObjects.find(obj => obj.object.id === intersectedObject.object.id)
            if (!existingObject) {
                markerMap[markerUUID].intersectedObjects.push(intersectedObject)
            } else if (existingObject.distance > intersectedObject.distance) {
                // Replace the existing object with the new one if it has a lower distance
                const index = markerMap[markerUUID].intersectedObjects.indexOf(existingObject)
                markerMap[markerUUID].intersectedObjects[index] = intersectedObject
            }
        })
    })

    // Sort intersectedObjects by distance for each marker
    Object.values(markerMap).forEach(item => {
        item.intersectedObjects.sort((a, b) => a.distance - b.distance)
    })

    return Object.values(markerMap)
}

const findClosestPointOnBox = (point: Vector3, box: Box3): Vector3 => {
    const closest = new Vector3()

    // For each axis (x, y, z)
    closest.x = Math.max(box.min.x, Math.min(box.max.x, point.x))
    closest.y = Math.max(box.min.y, Math.min(box.max.y, point.y))
    closest.z = Math.max(box.min.z, Math.min(box.max.z, point.z))

    return closest
}

const getPartIntersectionsBoxV2 = (
    section: { [key: string]: SegmentedTubeMarker, },
    raycaster: Raycaster,
    scene: Scene,
    boundingBox: Box3,
    partId: string
) => {
    const thresholdDistance = 0.01

    const intersectingObjects = findIntersections(scene, boundingBox, partId)

    //console.log(intersectingObjects, "intersectingObjects")

    // Create a spatial index for intersecting objects
    //const spatialIndex = createSpatialIndex(intersectingObjects)

    const result: { marker: any, intersectedObjects: any[], }[] = []

    // Reusable Vector3 objects
    const globalSlidePointPos = new Vector3()

    Object.values(section).forEach(markerGroup => {
        Object.values(markerGroup).forEach(marker => {
            if (!(marker instanceof Mesh)
                || !marker.name.includes("inner")
                || marker.name.includes("Boundary")
                || marker.name.includes("aligment")
                || marker.name.includes("outer")) {
                return
            }

            if (!marker.userData.localSlidePoints) {
                const { freePositions, } = getPointsArrayForSingleEnd(marker, 0.25)
                const localSlidePoints = freePositions.map(point => {
                    const localPoint = new Vector3()
                    const positionCopy = new Vector3().copy(point.position)
                    marker.worldToLocal(positionCopy)
                    return { position: positionCopy, }
                })
                marker.userData.localSlidePoints = localSlidePoints
            }

            //console.log(marker.userData.localSlidePoints, "marker.userData.localSlidePoints")

            //console.log(intersectingObjects, "intersectingObjects")

            const optimizeSlidePoints = (points: { position: Vector3, }[], maxPoints = 1000) => {
                // If under max points, return original array
                if (points.length <= maxPoints) {
                    return points
                }

                // Sort points by position for more consistent sampling
                const sortedPoints = [...points,].sort((a, b) =>
                    a.position.z - b.position.z
                    || a.position.y - b.position.y
                    || a.position.x - b.position.x
                )

                // Calculate spacing to evenly distribute points
                const spacing = Math.ceil(points.length / maxPoints)

                // Take evenly spaced points plus edge points
                return sortedPoints.filter((_, index) => {
                    // Keep first and last points
                    if (index === 0 || index === points.length - 1) { return true }
                    // Keep evenly spaced points
                    return index % spacing === 0
                })
            }

            const optimizedSlidePoints = optimizeSlidePoints(marker.userData.localSlidePoints)

            const intersectedObjects: { distance: number, object: Object3D, globalSlidePoint: Vector3, }[] = []
            optimizedSlidePoints.forEach((localSlidePoint: { position: Vector3, }) => {
                marker.localToWorld(globalSlidePointPos.copy(localSlidePoint.position))

                // Use spatial index to get nearby objects
                //const nearbyObjects = spatialIndex.getNearbyObjects(globalSlidePointPos, thresholdDistance)

                intersectingObjects.forEach((obj: Object3D) => {

                    /*obj.getWorldPosition(objWorldPos)
                    const distance = globalSlidePointPos.distanceTo(objWorldPos)*/
                    if (obj instanceof Mesh) {
                        // Ensure bounding box exists and is computed
                        if (!obj.geometry.boundingBox) {
                            obj.geometry.computeBoundingBox()
                        }

                        if (obj.geometry.boundingBox) {
                            // Get world space bounding box
                            const worldBox = new Box3()
                            worldBox.copy(obj.geometry.boundingBox)
                            worldBox.applyMatrix4(obj.matrixWorld)

                            // Find closest point in world space
                            const closestPoint = findClosestPointOnBox(globalSlidePointPos, worldBox)
                            const distance = globalSlidePointPos.distanceTo(closestPoint)

                            //console.log(distance, "distance", obj.name)

                            if (distance < thresholdDistance) {
                                //const localSlidePoints = obj.userData.localSlidePoints || getMarkerCenter(obj)
                                //const closestSlidePoint = findClosestSlidePoint(localSlidePoints, obj, globalSlidePointPos, thresholdDistance)

                                if (closestPoint) {
                                    intersectedObjects.push({ distance, object: obj, globalSlidePoint: closestPoint, })
                                }
                            }
                        }
                    }

                })
            })

            if (intersectedObjects.length > 0) {
                result.push({
                    marker,
                    intersectedObjects: intersectedObjects.sort((a, b) => a.distance - b.distance),
                })
            }
        })
    })

    return groupByMarkerIdAndSort(filterIntersectingObjects(result))
}

// Helper functions

const createSpatialIndex = (objects: Object3D[]) => {
    // Implement a simple grid-based spatial index
    // This is a basic implementation and can be replaced with more sophisticated structures like Octree
    const cellSize = 1 // Adjust based on your scene scale
    const grid: { [key: string]: Object3D[], } = {}

    objects.forEach(obj => {
        const position = new Vector3()
        obj.getWorldPosition(position)
        const key = `${Math.floor(position.x / cellSize)},${Math.floor(position.y / cellSize)},${Math.floor(position.z / cellSize)}`
        if (!grid[key]) { grid[key] = [] }
        grid[key].push(obj)
    })

    return {
        getNearbyObjects: (position: Vector3, radius: number) => {
            const nearby: Object3D[] = []
            const cellRadius = Math.ceil(radius / cellSize)
            for (let x = -cellRadius; x <= cellRadius; x++) {
                for (let y = -cellRadius; y <= cellRadius; y++) {
                    for (let z = -cellRadius; z <= cellRadius; z++) {
                        const key = `${Math.floor(position.x / cellSize) + x},${Math.floor(position.y / cellSize) + y},${Math.floor(position.z / cellSize) + z}`
                        if (grid[key]) { nearby.push(...grid[key]) }
                    }
                }
            }
            return nearby
        },
    }
}

const getMarkerCenter = (obj: Object3D) => {
    if (obj instanceof Mesh && obj.geometry) {
        obj.geometry.computeBoundingBox()
        const boundingBox = obj.geometry.boundingBox
        const center = new Vector3()
        boundingBox.getCenter(center)
        return [{ position: center, },]
    }
    return []
}

const findClosestSlidePoint = (localSlidePoints: { position: Vector3, }[], obj: Object3D, globalSlidePointPos: Vector3, thresholdDistance: number) => {
    let closest = null
    let minDistance = Infinity
    const slidePointWorldPos = new Vector3()

    for (const point of localSlidePoints) {
        obj.localToWorld(slidePointWorldPos.copy(point.position))
        const distance = globalSlidePointPos.distanceTo(slidePointWorldPos)

        if (distance < minDistance) {
            minDistance = distance
            closest = {
                distance,
                object: obj,
                globalSlidePoint: slidePointWorldPos.clone(),
            }
        }

        if (distance < thresholdDistance) { break } // Early termination
    }

    return closest && closest.distance < thresholdDistance ? closest : null
}

const filterIntersectingObjects = (result: { marker: any, intersectedObjects: any[], }[]) => {
    return result.map(item => ({
        marker: item.marker,
        intersectedObjects: item.intersectedObjects.sort((a, b) => a.distance - b.distance),
    }))
}


const getSectionIntersections = (
    section: SegmentedTubeMarkers,
    markersToIntersect: Object3D[],
    partConnectionsValue: ConnectionOfPart[],
    raycaster: Raycaster,
    tubeDirection?: Vector3,
    intersectableMeshes?: Object3D[],
    scene?: Scene, // Add scene as a parameter to add lines for debugging
    boundingBox?: Box3 | null,
    partId?: string,
) => {
    return Object.keys(section).filter(key => {
        const innerMarker = section[key].inner
        return !partConnectionsValue.some(({ partMarkerName, }) => outerToInner(partMarkerName) === innerMarker?.name)
    })
        .map(key => {
            //accounts for middles which won't come with tube direction
            const innerMarker = section[key].inner!
            const innerMarkerPos = MeshUtils.copyWorldPosition(innerMarker)
            const direction = tubeDirection ? tubeDirection : MeshUtils.copyWorldDirection(innerMarker)
            const auxPos = new Vector3()
            const auxDir = new Vector3()
            auxDir.copy(direction)
            auxPos.addVectors(innerMarkerPos, auxDir.normalize().multiplyScalar(-RAY_POSITION_REDUCTION / metersToInch))
            raycaster.ray.set(auxPos, direction)

            //debug
            //const material = (innerMarker as Mesh).material as MeshBasicMaterial
            //material.color.set("orange")
            //material.depthTest = false
            //material.visible = true

            //
            //should update this to only be the markers from the scene instead all the scene
            let objectsToIntersect = []
            if (intersectableMeshes && intersectableMeshes.length > 0) {
                objectsToIntersect = intersectableMeshes
            } else if (markersToIntersect && markersToIntersect.length > 0) {
                objectsToIntersect = markersToIntersect
            } else {
                objectsToIntersect = scene?.children || []
            }
            const objects = raycaster.intersectObjects(objectsToIntersect)

            const filteredObjects = objects.filter(intersection => {
                const objectName = intersection.object.name
                const isMesh = intersection.object instanceof Mesh
                const hasValidName = (objectName.includes("inner") || objectName.includes("outer")) && !objectName.includes("aligmentPlane") && !objectName.includes("ignore")
                const isDifferentPartId = tubeDirection || intersection.object.userData.partId !== section[key].inner?.userData.partId
                return isMesh && hasValidName && isDifferentPartId
            })


            /*
            filteredObjects.forEach(intersection => {
                const material = (intersection.object as Mesh).material as MeshBasicMaterial
                //material.color.set(0x00ff00) // Green color
                material.wireframe = false
                material.depthTest = false
                //material.visible = true
            })

            if (scene) {
                // Debugging: Draw the ray
                const rayLength = 10 // Adjust the length as needed
                const endPos = new Vector3().copy(auxPos)
                .add(direction.multiplyScalar(rayLength))
                const points = [auxPos, endPos,]
                const geometry = new BufferGeometry().setFromPoints(points)
                const material = new LineBasicMaterial({ color: 0xff0000, }) // Red color for the ray
                const line = new Line(geometry, material)
                //scene.add(line)
            }*/

            return { marker: innerMarker, intersectedObjects: filteredObjects, }
        })
}

type SectionIntersectionsType = { marker: Mesh, intersectedObjects: Intersection[], }[]
type SegmentedTubeIntersectionsType = {
    startSection: SectionIntersectionsType,
    middleSection: { [side: string]: SectionIntersectionsType, },
    endSection: SectionIntersectionsType,
}

const getSectionIntersectionsOld = (
    section: SegmentedTubeMarkers,
    markersToIntersect: Object3D[],
    partConnectionsValue: ConnectionOfPart[],
    raycaster: Raycaster,
    tubeDirection?: Vector3,
    intersectableMeshes?: Object3D[],
) => {
    return Object.keys(section).filter(key => {
        const innerMarker = section[key].inner
        return !partConnectionsValue.some(({ partMarkerName, }) => outerToInner(partMarkerName) === innerMarker?.name)
    })
        .map(key => {
            const innerMarker = section[key].inner!
            const innerMarkerPos = MeshUtils.copyWorldPosition(innerMarker)
            const direction = tubeDirection ? tubeDirection : MeshUtils.copyWorldDirection(innerMarker)

            const auxPos = new Vector3()
            const auxDir = new Vector3()
            auxDir.copy(direction)
            auxPos.addVectors(innerMarkerPos, auxDir.normalize().multiplyScalar(-RAY_POSITION_REDUCTION / metersToInch))
            raycaster.ray.set(auxPos, direction)
            const objects = raycaster.intersectObjects(intersectableMeshes ? intersectableMeshes : markersToIntersect)
            return { marker: innerMarker, intersectedObjects: objects, }
        })
}

const proyectRays = (
    markersToIntersect: Object3D[],
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    newMiddlePositions: string[],
    markersToIntersectMiddle: Mesh[],
    scene?: Scene,
    allMiddles?: boolean,
    boundingBox?: Box3 | null,
    tube?: SegmentedTubeValues,
) => {
    const getFirstChildKeys = (middleSection: Record<string, any>): string[] => {
        const firstChildKey = Object.keys(middleSection)[0]
        if (firstChildKey) {
            return Object.keys(middleSection[firstChildKey])
        }
        return []
    }

    const { startSection, middleSection, endSection, } = info.current

    const firstChild = Object.values(info.current.startSection)[0]
    const partId = firstChild?.inner?.userData.partId || firstChild?.outer?.userData.partId || firstChild?.plus?.userData.partId || firstChild?.mesh?.userData.partId

    const keys = getFirstChildKeys(middleSection)

    const intersections: SegmentedTubeIntersectionsType = {
        startSection: [],
        middleSection: {},
        endSection: [],
    }

    const raycaster = new Raycaster()

    const tubeDirection = getTubeDirection(info, movableSectionName)

    if (!tubeDirection) {
        return {
            startSection: [],
            middleSection: {},
            endSection: [],
        }
    }

    if (movableSectionName === SegmentedTubeSectionType.END && scene && boundingBox) {
        intersections.startSection = getPartIntersectionsBoxV2(endSection, raycaster, scene, boundingBox, partId)
        intersections.endSection = getPartIntersectionsBoxV2(startSection, raycaster, scene, boundingBox, partId)
    }
    if (movableSectionName === SegmentedTubeSectionType.START && scene && boundingBox) {
        intersections.endSection = getPartIntersectionsBoxV2(endSection, raycaster, scene, boundingBox, partId)
        intersections.startSection = getPartIntersectionsBoxV2(startSection, raycaster, scene, boundingBox, partId)
    }
    if (allMiddles) {
        Object.keys(middleSection).forEach(key => {
            const newMiddleSection = keys.reduce((pv: SegmentedTubeMarkers, cv: string) => {
                pv[cv] = middleSection[key][cv]
                return pv
            }, {})
            if (scene && boundingBox) { intersections.middleSection[key] = getPartIntersectionsBoxV2(newMiddleSection, raycaster, scene, boundingBox, partId) }
        })
    }
    else {
        Object.keys(middleSection).forEach(key => {
            const newMiddleSection = newMiddlePositions.reduce((pv: SegmentedTubeMarkers, cv: string) => {
                pv[cv] = middleSection[key][cv]
                return pv
            }, {})
            if (scene && boundingBox) { intersections.middleSection[key] = getPartIntersectionsBoxV2(newMiddleSection, raycaster, scene, boundingBox, partId) }
        })
    }
    return intersections
}

const getTubeDirection = (
    info: MutableRefObject<SegmentedTubeInfo>,
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    const pivot = info.current.originPoint
    if (pivot) {
        const pivotDirection = MeshUtils.copyWorldDirection(pivot)
        if (movableSectionName === SegmentedTubeSectionType.END) {
            return pivotDirection.multiplyScalar(-1)
        }
        return pivotDirection
    }
    return undefined
}

const proyectMovableSectionRay = (
    scene: Scene,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    proyectionMarker: MarkerType,
    intersectableMeshes?: Object3D[],
    tubeDirectionRay?: boolean
) => {
    const movableSection = movableSectionName === SegmentedTubeSectionType.END ? info.current.endSection : info.current.startSection
    const raycaster = new Raycaster()
    const movableSectionMarkers: { [key: string]: SegmentedTubeMarker, } = {}
    Object.keys(movableSection).forEach(key => {
        const marker = proyectionMarker === MarkerType.INNER ? movableSection[key].inner : movableSection[key].outer
        if (!marker?.userData.lateralFacing) {
            movableSectionMarkers[key] = movableSection[key]
        }
    })
    const tubeDirection = tubeDirectionRay ? getTubeDirection(info, movableSectionName) : undefined
    return getSectionIntersectionsOld(movableSectionMarkers, scene.children, partConnectionsValue, raycaster, tubeDirection, intersectableMeshes)
}

const orderIntersections = (a: Intersection, b: Intersection) => a.distance - b.distance

const getClosestMovableSectionIntersection = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    checkCollineal?: boolean
) => {
    const tubeMovableSectionIntersections = proyectMovableSectionRay(scene, info, partConnectionsValue, movableSection, MarkerType.INNER, undefined, true)
    const closestIntersections = tubeMovableSectionIntersections.map(tubeMovableSectionIntersection => {
        const filteredIntersections = tubeMovableSectionIntersection.intersectedObjects.filter(intersection =>
            intersection.object.userData.partId !== tube.id
            && intersection.object.userData.type === SceneMarkerType.COLLISION
            && (!checkCollineal || areCollineal(tubeMovableSectionIntersection.marker, intersection.object))
        )
        return { marker: tubeMovableSectionIntersection.marker, intersectedObject: filteredIntersections.sort(orderIntersections)[0], }
    }).filter(intersection => intersection.intersectedObject)
    return closestIntersections.sort((a, b) => orderIntersections(a.intersectedObject, b.intersectedObject))[0]
}

const areCollineal = (marker: Object3D, object: Object3D) => {
    const markerWorldDirection = MeshUtils.copyWorldDirection(marker)
    const markerWorldPosition = MeshUtils.copyWorldPosition(marker)
    const objectWorldDirection = MeshUtils.copyWorldDirection(object)
    const objectWorldPosition = MeshUtils.copyWorldPosition(object)

    return isCollineal(
        markerWorldDirection,
        markerWorldPosition,
        objectWorldDirection,
        objectWorldPosition
    )
}

const areParrallel = (marker: Object3D, object: Object3D) => {
    const markerWorldDirection = MeshUtils.copyWorldDirection(marker)
    const objectWorldDirection = MeshUtils.copyWorldDirection(object)

    return isParallel(markerWorldDirection, objectWorldDirection)
}

const isCompatibleWith = (
    marker: Mesh,
    collidingObject: Intersection<Object3D>,
    compatibilityList: ObjDictionary<ConnectionTypeAPI>
) => {
    const ignoreSize = compatibilityList[marker.userData.id].ignoreSizeCompatibility
    const isSizeCompatible = ignoreSize || marker.userData.sizeId === collidingObject.object.userData.sizeId
    const result = compatibilityList[marker.userData.id].compatibleWithMulti.find(
        compatible => compatible === collidingObject.object.userData.id
    ) && isSizeCompatible
    return result
}

const isSomeMarkerNameInConnections = (
    partConnectionsValue: ConnectionOfPart[],
    markerNames: string[]
) => {
    return partConnectionsValue
        .some(connection => markerNames
            .some(markerName => outerToInner(markerName) === outerToInner(connection.partMarkerName)))
}

const isSomeMovableMarkerConnected = (
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    const movableSection = movableSectionName === SegmentedTubeSectionType.END ? info.current.endSection : info.current.startSection
    const movableMarkersNames = Object.keys(movableSection).map(key => movableSection[key].inner?.name)
    const result = isSomeMarkerNameInConnections(partConnectionsValue, filterWithValue(movableMarkersNames))
    return result
}

const checkSnap = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    allConnections: PartConnectionType[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    newLength: number,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    maxPossibleLength: number
) => {
    const closestIntersection = getClosestMovableSectionIntersection(scene, tube, info, partConnectionsValue, movableSection, true)
    let playSound = false
    if (closestIntersection && closestIntersection.intersectedObject) {
        const marker = getMarkerUserData(closestIntersection.intersectedObject.object)
        const intersectionConnection = allConnections.find(c => isInConnection(c, marker.partId, marker.markerName))
        if (!intersectionConnection) {
            const intersectedDir = MeshUtils.copyWorldDirection(closestIntersection.intersectedObject.object).multiplyScalar(-1)
            const markerDir = MeshUtils.copyWorldDirection(closestIntersection.marker)
            const isMarkerAlreadyConnected = isMarkerConnected(
                marker.markerName, marker.partId, allConnections
            )
            if (isCompatibleWith(closestIntersection.marker, closestIntersection.intersectedObject, compatibilityList)
                && closestIntersection.intersectedObject.distance * metersToInch < (0.1 + RAY_POSITION_REDUCTION)
                && !isMarkerAlreadyConnected
                && areCollineal(closestIntersection.intersectedObject.object, closestIntersection.marker)
                && isSameDirection(intersectedDir, markerDir)
            ) {
                playSound = movableSection === SegmentedTubeSectionType.START ? !info.current.snapped.start : !info.current.snapped.end
            }
        }
    }
    if (!playSound) {
        if (newLength >= maxPossibleLength * tube.segmentLength) {
            if (info.current.multipleMove.connected) {
                playSound = true
            } else if ((movableSection === SegmentedTubeSectionType.START && !info.current.snapped.start)
                || (movableSection === SegmentedTubeSectionType.END && !info.current.snapped.end)
            ) {
                playSound = isSomeMovableMarkerConnected(info, partConnectionsValue, movableSection)
            }
        }
    }
    if (playSound) {
        if (movableSection === SegmentedTubeSectionType.START) {
            if (info.current.multipleMove.connected) {
                info.current.multipleMove.snapped.start = true
            } else {
                info.current.snapped.start = true
            }
        } else {
            if (info.current.multipleMove.connected) {
                info.current.multipleMove.snapped.end = true
            }
            info.current.snapped.end = true
        }
        //SoundHelper.playUnsnap()
    }
}

const getMaxPossibleLength = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    actualLength: {
        positiveLength: number,
        negativeLength: number,
    },
    partsToMoveIds?: string[]
) => {
    const closestIntersection = getClosestMovableSectionIntersection(scene, tube, info, partConnectionsValue, movableSection)
    if (isSomeMovableMarkerConnected(info, partConnectionsValue, movableSection)) {
        const isTemporarilyUnsnapped = movableSection === SegmentedTubeSectionType.END ? !info.current.snapped.end : !info.current.snapped.start
        if (partsToMoveIds && partsToMoveIds.length > 0
            && info.current.multipleMove.maxPossibleLength
        ) {
            return actualLength.positiveLength + actualLength.negativeLength + Math.round(info.current.multipleMove.maxPossibleLength * metersToInch / tube.segmentLength)
        } else if (isTemporarilyUnsnapped) {
            return movableSection === SegmentedTubeSectionType.END
                ? tube.length + actualLength.negativeLength
                : tube.lengthNegativeSide + actualLength.positiveLength
        } else if (partsToMoveIds && partsToMoveIds.length > 0) {
            return tube.maxMiddles || 80
        } else {
            return actualLength.positiveLength + actualLength.negativeLength
        }
    } else if (closestIntersection && closestIntersection.intersectedObject
        && isCompatibleWith(closestIntersection.marker, closestIntersection.intersectedObject, compatibilityList)
        && areParrallel(closestIntersection.intersectedObject.object, closestIntersection.marker)
    ) {
        return actualLength.positiveLength
            + actualLength.negativeLength
            + Math.round(
                (closestIntersection.intersectedObject.distance
                    - (RAY_POSITION_REDUCTION / metersToInch))
                * metersToInch / tube.segmentLength)
    } else {
        return tube.maxMiddles || 80
    }
}

const checkMaxPossibleLength = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    setMaxPossibleLength: Dispatch<SetStateAction<number>>,
    actualLength: {
        positiveLength: number,
        negativeLength: number,
    },
    partsToMoveIds?: string[],
) => {
    setMaxPossibleLength(
        getMaxPossibleLength(
            scene,
            tube,
            info,
            partConnectionsValue,
            compatibilityList,
            movableSection,
            actualLength,
            partsToMoveIds
        )
    )
}

const checkCollinear = (
    diagonal: boolean,
    marker: Mesh,
    intersectedObject: Intersection
) => {
    if (diagonal) {
        return MeshUtils.areApproximatelyCollinear(marker, intersectedObject.object)
    } else {
        return areCollineal(intersectedObject.object, marker)
    }
}

type GetCorrectConnectionsProps = {
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    allConnections: PartConnectionType[],
    tube: SegmentedTubeValues,
    allMiddles?: boolean,
} & (
        {
            diagonal: false,
            intersections: {
                marker: Mesh,
                intersectedObject: Intersection | undefined,
            }[],
        }
        | {
            diagonal: true,
            intersections: {
                marker: Mesh,
                intersectedObject: Intersection[] | undefined,
            }[],
        }
    )

const getCorrectConnections = (props: GetCorrectConnectionsProps) => {
    const newConnections: PartConnectionType[] = []
    const existingConnections: PartConnectionType[] = []
    let closestIntersections: {
        marker: Mesh,
        intersectedObject: Intersection | undefined,
    }[] = []
    if (props.diagonal) {
        closestIntersections = props.intersections.map(intersection => {
            return {
                marker: intersection.marker,
                intersectedObject: intersection.intersectedObject
                    ? getMostCenteredCloseDiagonal(intersection.marker, intersection.intersectedObject)
                    : undefined,
            }
        })
    } else {
        closestIntersections = props.intersections
    }


    closestIntersections.forEach(intersection => {
        if (intersection.intersectedObject) {
            const markerDir = MeshUtils.copyWorldDirection(intersection.marker)
            const marker = getMarkerUserData(intersection.intersectedObject.object)
            const isMarkerAlreadyConnected = isMarkerConnected(
                marker.markerName, marker.partId, props.allConnections, props.allMiddles ? intersection.marker.name : undefined
            )

            const intersectedDir = MeshUtils.copyWorldDirection(intersection.intersectedObject.object).multiplyScalar(-1)

            const conditionA = isCompatibleWith(intersection.marker, intersection.intersectedObject, props.compatibilityList)
            //const conditionB = checkCollinear(props.diagonal, intersection.marker, intersection.intersectedObject)
            const conditionC = isSameDirection(markerDir, intersectedDir)
            const conditionD = intersection.intersectedObject.distance * metersToInch < (0.1 + RAY_POSITION_REDUCTION)
            const conditions = conditionA && conditionC && conditionD && !isMarkerAlreadyConnected
            const conditionsForAlreadyConnected = conditionA && conditionC && conditionD && isMarkerAlreadyConnected


            if (conditions) {
                newConnections.push({
                    partA: {
                        partId: props.tube.id,
                        markerName: intersection.marker.name,
                    },
                    partB: {
                        partId: intersection.intersectedObject.object.userData.partId,
                        markerName: intersection.intersectedObject.object.name,
                    },
                })
            }
            if (conditionsForAlreadyConnected) {
                existingConnections.push({
                    partA: {
                        partId: props.tube.id,
                        markerName: intersection.marker.name,
                    },
                    partB: {
                        partId: intersection.intersectedObject.object.userData.partId,
                        markerName: intersection.intersectedObject.object.name,
                    },
                })
            }
        }
    })

    return { newConnections, existingConnections, }
}

const visualizeConnections = (connections: PartConnectionType[], colorA: number, colorB: number, scene: Scene) => {
    const font = new FontLoader().parse(helvetikerFont)

    const findMarkerInScene = (scene: Scene, markerName: string, partId: string): Object3D | null => {
        let foundMarker: Object3D | null = null
        scene.traverse((object) => {
            if (object.name === markerName && object.userData.partId === partId) {
                foundMarker = object
            }
        })
        return foundMarker
    }

    const createTextMesh = (text: string, color: number) => {
        const geometry = new TextGeometry(text, {
            font: font,
            size: 0.01,
            height: 0.001,
        })
        const material = new MeshBasicMaterial({ color: color, depthTest: false, })
        return new Mesh(geometry, material,)
    }

    connections.forEach(connection => {
        const objectsToRemove = scene.children.filter(obj =>
            obj.name === "ignore_connectionVisualizationCloneA"
            || obj.name === "ignore_connectionVisualizationCloneB"
            || obj.name === "ignore_connectionVisualizationLine"
            || obj.name === "ignore_connectionVisualizationTextA"
            || obj.name === "ignore_connectionVisualizationTextB"
        )
        objectsToRemove.forEach(obj => scene.remove(obj))

        const markerA = findMarkerInScene(scene, connection.partA.markerName, connection.partA.partId)
        const markerB = findMarkerInScene(scene, connection.partB.markerName, connection.partB.partId)

        if (markerA && markerB) {
            const cloneA = markerA.clone()
            const cloneB = markerB.clone()

            const worldPositionA = new Vector3()
            const worldQuaternionA = new Quaternion()

            const worldPositionB = new Vector3()
            const worldQuaternionB = new Quaternion()

            markerA.getWorldPosition(worldPositionA)
            markerA.getWorldQuaternion(worldQuaternionA)

            markerB.getWorldPosition(worldPositionB)
            markerB.getWorldQuaternion(worldQuaternionB)


            const textMeshA = createTextMesh(`${markerA.name} (${connection.partA.partId.slice(0, 4)})`, colorA)
            textMeshA.name = "ignore_connectionVisualizationTextA"
            //textMeshA.position.copy(worldPositionA)
            //textMeshA.quaternion.copy(worldQuaternionA)

            cloneA.position.copy(worldPositionA)
            cloneA.quaternion.copy(worldQuaternionA)
            textMeshA.position.add(new Vector3(0.01, 0.01, 0.01)) // Offset from the marker

            const textMeshB = createTextMesh(`${markerB.name} (${connection.partB.partId.slice(0, 4)})`, colorB)
            textMeshB.name = "ignore_connectionVisualizationTextB"
            //textMeshB.position.copy(worldPositionB)
            //textMeshB.quaternion.copy(worldQuaternionB)
            textMeshB.position.add(new Vector3(0.01, 0.01, 0.01)) // Offset from the marker

            cloneA.name = "ignore_connectionVisualizationCloneA"
            cloneA.userData = {}
            cloneB.name = "ignore_connectionVisualizationCloneB"
            cloneB.userData = {}

            cloneB.position.copy(worldPositionB)
            cloneB.quaternion.copy(worldQuaternionB)

            // Make markers visible and set colors
            const materialA = new MeshBasicMaterial({ color: colorA, side: DoubleSide, transparent: true, opacity: 0.7, depthTest: false, })
            const materialB = new MeshBasicMaterial({ color: colorB, side: DoubleSide, transparent: true, opacity: 0.7, depthTest: false, })

            if (cloneA instanceof Mesh) {
                cloneA.material = materialA
            }
            if (cloneB instanceof Mesh) {
                cloneB.material = materialB
            }

            // Ensure the clones are visible
            cloneA.visible = true
            cloneB.visible = true

            // Set world position and quaternion for clones
            cloneA.position.setFromMatrixPosition(markerA.matrixWorld)
            cloneA.quaternion.setFromRotationMatrix(markerA.matrixWorld)

            cloneB.position.setFromMatrixPosition(markerB.matrixWorld)
            cloneB.quaternion.setFromRotationMatrix(markerB.matrixWorld)

            // Add TextGeometry meshes to clones
            cloneA.add(textMeshA)
            cloneB.add(textMeshB)

            scene.add(cloneA)
            scene.add(cloneB)

            // Add a line connecting the markers using world positions
            const lineGeometry = new BufferGeometry().setFromPoints([
                cloneA.position,
                cloneB.position,
            ])
            const lineMaterial = new LineBasicMaterial({ color: 0xffffff, depthTest: false, })
            const line = new Line(lineGeometry, lineMaterial)
            line.name = "ignore_connectionVisualizationLine"
            scene.add(line)
        }
    })
}

const getCorrectConnectionsForAllIntersections = (props: GetCorrectConnectionsPropsForAllIntersections) => {
    const newConnections: PartConnectionType[] = []
    const existingConnections: PartConnectionType[] = []
    const closestIntersections = props.intersections

    //console.log(props.allConnections, "allConnections")

    //console.log(closestIntersections, "closestIntersections")

    closestIntersections.forEach(intersection => {
        if ("intersectedObjects" in intersection && intersection.intersectedObjects) {
            intersection.intersectedObjects.forEach(intersectedObject => {
                const markerDir = MeshUtils.copyWorldDirection(intersection.marker)
                const marker = getMarkerUserData(intersection.marker)
                const isMarkerAlreadyConnected = isMarkerConnected(
                    intersectedObject.object.name, intersectedObject.object.userData.partId, props.allConnections, intersection.marker.name
                )

                const intersectedDir = MeshUtils.copyWorldDirection(intersectedObject.object).multiplyScalar(-1)

                const conditionA = isCompatibleWith(intersection.marker, intersectedObject, props.compatibilityList)
                //const conditionB = checkCollinear(props.diagonal, intersection.marker, intersection.intersectedObject)
                const conditionC = isSameDirection(markerDir, intersectedDir)
                //the distance condition is checked earlier so not needed here
                const conditionD = intersectedObject.distance * metersToInch < (0.1 + RAY_POSITION_REDUCTION)
                const conditions = conditionA && conditionC && !isMarkerAlreadyConnected
                const conditionsForAlreadyConnected = conditionA && conditionC && isMarkerAlreadyConnected


                //console.log(conditionD, "conditionD", intersectedObject.object.name, intersection.marker.name)


                if (conditions) {

                    newConnections.push({
                        partA: {
                            partId: props.tube.id,
                            markerName: intersection.marker.name,
                        },
                        partB: {
                            partId: intersectedObject.object.userData.partId,
                            markerName: intersectedObject.object.name,
                        },
                    })
                }
                if (conditionsForAlreadyConnected) {

                    existingConnections.push({
                        partA: {
                            partId: props.tube.id,
                            markerName: intersection.marker.name,
                        },
                        partB: {
                            partId: intersectedObject.object.userData.partId,
                            markerName: intersectedObject.object.name,
                        },
                    })
                }

            })
        }
    })

    return { newConnections, existingConnections, }
}

const getClosestIntersections = (intersections: Intersection[]) => {
    if (intersections.length > 0) {
        intersections.sort(orderIntersections)
        const closestDistance = intersections[0].distance
        return intersections.filter(intersection => intersection.distance < closestDistance + 0.1 / metersToInch)
    } else {
        return []
    }
}

const getMostCenteredCloseDiagonal = (marker: Mesh, intersections: Intersection[]) => {
    const markerDir = MeshUtils.copyWorldDirection(marker)
    const markerPos = MeshUtils.copyWorldPosition(marker)
    const closestIntersections = getClosestIntersections(intersections)
    const closestIntersectionsWithDistanceToCenter = filterWithValue(closestIntersections.map(intersection => {
        const distanceToCenterIntersection = MeshUtils.checkAproxCollinearToDirection(markerPos, markerDir, intersection.object, false)
        if (distanceToCenterIntersection) {
            return { ...intersection, distanceToCenter: distanceToCenterIntersection.distance, }
        } else {
            return undefined
        }
    }))
    closestIntersectionsWithDistanceToCenter.sort((a, b) => a.distanceToCenter - b.distanceToCenter)
    return closestIntersectionsWithDistanceToCenter[0]
}

type GetFilteredIntersectionsType<B> =
    B extends true ? {
        diagonal: true,
        intersections: {
            marker: Mesh,
            intersectedObject: Intersection[],
        }[],
    } : {
        diagonal: false,
        intersections: {
            marker: Mesh,
            intersectedObject: Intersection,
        }[],
    }

const getFilteredIntersections = <B extends boolean>(section: SectionIntersectionsType, tube: SegmentedTubeValues, diagonal: B): GetFilteredIntersectionsType<B> => {
    const filteredIntersections = section.map(markerIntersections => {
        const filteredIntersections = markerIntersections.intersectedObjects.filter(intersection => {
            const conditionA = intersection.object.userData.partId !== tube.id
            const conditionB = intersection.object.userData.type === SceneMarkerType.COLLISION
            //const conditionBB = areCollineal(markerIntersections.marker, intersection.object)
            const markerDir = MeshUtils.copyWorldDirection(markerIntersections.marker)
            const intersectionDir = MeshUtils.copyWorldDirection(intersection.object)
            const conditionBB = isParallel(markerDir, intersectionDir)
            const conditionC = diagonal || conditionBB
            //condition B now changed to parrallel
            return conditionA && conditionC && conditionB
        })

        if (diagonal) {
            return {
                marker: markerIntersections.marker,
                filteredIntersections,
            }
        } else {
            return {
                marker: markerIntersections.marker,
                filteredIntersections,
            }
        }
    })

    if (diagonal) {
        return {
            diagonal: true,
            intersections: filteredIntersections.map(intersection => {
                return {
                    marker: intersection.marker,
                    intersectedObject: getClosestIntersections(intersection.filteredIntersections),
                }
            }),
        } as unknown as GetFilteredIntersectionsType<B>
    } else {
        return {
            diagonal: false,
            intersections: filteredIntersections.map(intersection => {
                return {
                    marker: intersection.marker,
                    intersectedObject: intersection.filteredIntersections.sort(orderIntersections)[0],
                }
            }),
        } as unknown as GetFilteredIntersectionsType<B>
    }
}

const checkSnapOnEditionMovableSection = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    allConnections: PartConnectionType[],
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    newMiddlePositions: string[],
    markersToIntersectMiddle: Mesh[],
    playSound?: boolean,
    markersToIntersect?: Object3D[],
    allMiddles?: boolean,
    boundingBox?: Box3 | null,
    isAdmin?: boolean,
) => {
    let newConnections = []

    let existingConnections: PartConnectionType[] = []
    const tubeIntersections = proyectRays(
        markersToIntersect ? markersToIntersect : scene.children,
        info,
        partConnectionsValue,
        movableSection,
        newMiddlePositions,
        markersToIntersectMiddle,
        scene,
        allMiddles,
        boundingBox,
        tube,
    )

    if (isAdmin) {
        console.log(tubeIntersections, "checkSnapOnEditionMovableSection tubeIntersections")
    }

    const { newConnections: endIntersectionsConnections, existingConnections: endAllConnections, } = getCorrectConnectionsForAllIntersections({
        intersections: tubeIntersections.endSection,
        compatibilityList,
        allConnections,
        tube,
        diagonal: false,
    })


    newConnections = endIntersectionsConnections

    const { newConnections: startIntersectionsConnections, existingConnections: startAllConnections, } = getCorrectConnectionsForAllIntersections({
        intersections: tubeIntersections.startSection,
        compatibilityList,
        allConnections,
        tube,
        diagonal: false,
    })

    newConnections = newConnections.concat(startIntersectionsConnections)

    let newConnectionsforMiddles: PartConnectionType[] = []
    let existingConnectionsforMiddles: PartConnectionType[] = []

    Object.keys(tubeIntersections.middleSection).forEach(cv => {
        const { newConnections, existingConnections, } = getCorrectConnectionsForAllIntersections({
            intersections: tubeIntersections.middleSection[cv],
            compatibilityList,
            allConnections,
            tube,
            diagonal: false,
        })
        newConnectionsforMiddles = newConnectionsforMiddles.concat(newConnections)
        existingConnectionsforMiddles = existingConnectionsforMiddles.concat(existingConnections)
    })

    newConnections = newConnections.concat(newConnectionsforMiddles)

    const filterConnections = (newConnections: PartConnectionType[], existingConnections: PartConnectionType[]) => {
        const getPrefix = (str: string) => outerToInner(str.split("_")[0])

        if (newConnections.length === 0) {
            return []
        }

        if (existingConnections.length === 0 && newConnections.length > 0) {
            return newConnections
        }

        return newConnections.filter(intersection => {
            const { partA, partB, } = intersection
            return !existingConnections.some(newConn => {
                const samePartA = newConn.partA.partId === partA.partId && getPrefix(newConn.partA.markerName) === getPrefix(partA.markerName)
                const samePartB = newConn.partB.partId === partB.partId && getPrefix(newConn.partB.markerName) === getPrefix(partB.markerName)
                return samePartA && samePartB
            })
        })
    }

    existingConnections = existingConnections
        .concat(endAllConnections)
        .concat(startAllConnections)
        .concat(existingConnectionsforMiddles)

    if (isAdmin) {
        console.log(existingConnections, "existingConnections from checkSnapOnEditionMovableSection")
    }
    const filteredConnections = filterConnections(newConnections, existingConnections)

    const partsDictionary = toDictionary(filteredConnections, part => getOtherPartOfTheConnection(part, tube.id))

    newConnections = Object.values(partsDictionary)

    return { newConnections, existingConnections, }
}

const checkUnsnap = (
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    newLengthMovableSide: number,
    unsnap: (id: string, markerNames: string[]) => void,
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    const actualLength = movableSectionName === SegmentedTubeSectionType.START ? tube.lengthNegativeSide : tube.length
    if (actualLength > newLengthMovableSide) {
        const movableSection = movableSectionName === SegmentedTubeSectionType.END ? info.current.endSection : info.current.startSection
        const movableMarkersNames = filterWithValue(Object.keys(movableSection).map(key => {
            const marker = movableSection[key]
            return [marker.inner?.name, marker.outer?.name,]
        })
            .flat(1))
        unsnap(tube.id, movableMarkersNames)
    }
}

const checkUnsnapSound = (
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    newLength: number,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    maxPossibleLength: number,
) => {
    let snappedState = false
    if (info.current.multipleMove.connected) {
        snappedState = movableSection === SegmentedTubeSectionType.START ? info.current.multipleMove.snapped.start : info.current.multipleMove.snapped.end
    } else {
        snappedState = movableSection === SegmentedTubeSectionType.START ? info.current.snapped.start : info.current.snapped.end
    }
    if (
        snappedState
        && newLength < maxPossibleLength * tube.segmentLength
    ) {
        if (movableSection === SegmentedTubeSectionType.START) {
            if (info.current.multipleMove.connected) {
                info.current.multipleMove.snapped.start = false
            } else {
                info.current.snapped.start = false
            }
        } else if (info.current.multipleMove.connected) {
            info.current.multipleMove.snapped.end = false
        } else {
            info.current.snapped.end = false
        }
        //SoundHelper.playSnap()
    }
}

const setSnappedStateOnLoad = (
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
) => {
    const startMarkerNames = filterWithValue(Object.keys(info.current.startSection).map(key => info.current.startSection[key].inner?.name))
    const endMarkerNames = filterWithValue(Object.keys(info.current.endSection).map(key => info.current.endSection[key].inner?.name))
    info.current.snapped.start = isSomeMarkerNameInConnections(partConnectionsValue, startMarkerNames)
    info.current.snapped.end = isSomeMarkerNameInConnections(partConnectionsValue, endMarkerNames)
}

const removeGuidelines = (
    scene: Scene,
    info: MutableRefObject<SegmentedTubeInfo>,
) => {
    info.current.guidelines.forEach((line) => scene.remove(line))
    info.current.guidelines = []
}

const renderGuidelines = (
    scene: Scene,
    info: MutableRefObject<SegmentedTubeInfo>,
    guidelinesIntersections: {
        marker: Mesh,
        intersectedObjects: Intersection[],
    }[]
) => {
    guidelinesIntersections.forEach(intersection => {
        const markerPos = MeshUtils.copyWorldPosition(intersection.marker)
        intersection.intersectedObjects.forEach(intersectedObject => {
            const objectPos = MeshUtils.copyWorldPosition(intersectedObject.object)
            const points = [markerPos, objectPos,]
            const geometry = new BufferGeometry().setFromPoints(points)
            const lineMesh = new Line(
                geometry,
                new LineBasicMaterial({ color: "red", linewidth: 1, })
            )
            scene.add(lineMesh)
            info.current.guidelines.push(lineMesh)
        })
    })
}

const checkGuidelines = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    removeGuidelines(scene, info)
    const tubeMovableSectionIntersections = proyectMovableSectionRay(scene, info, partConnectionsValue, movableSection, MarkerType.OUTER)
    const guidelinesIntersections = tubeMovableSectionIntersections.map(tubeMovableSectionIntersection => {
        const filteredIntersections = tubeMovableSectionIntersection.intersectedObjects.filter(intersection =>
            intersection.distance * metersToInch <= 0.1 + RAY_POSITION_REDUCTION
            && intersection.object.userData.partId !== tube.id
            && intersection.object.userData.type === SceneMarkerType.COLLISION_PLANE)
        return { marker: tubeMovableSectionIntersection.marker, intersectedObjects: filteredIntersections.sort(orderIntersections), }
    }).filter(intersection => intersection.intersectedObjects.length > 0)
    renderGuidelines(scene, info, guidelinesIntersections)
}

const checkAlignments = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSections: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>[],
    intersectableMeshes: Object3D[]
) => {
    removeGuidelines(scene, info)
    const DISTANCE_TO_ACTIVATE_GUIDELINE = 20
    const initialValue: {
        marker: Mesh,
        intersectedObjects: Intersection[],
    }[] = []
    const movableSectionIntersections = movableSections.reduce((pv, cv) => {
        return pv.concat(proyectMovableSectionRay(
            scene,
            info,
            partConnectionsValue,
            cv,
            MarkerType.OUTER,
            intersectableMeshes
        ))
    }, initialValue)
    movableSectionIntersections.forEach(movableSectionIntersection => {
        const markerDirection = MeshUtils.copyWorldDirection(movableSectionIntersection.marker)
        const markerPosition = MeshUtils.copyWorldPosition(movableSectionIntersection.marker)
        const filteredIntersections = movableSectionIntersection.intersectedObjects.filter(intersection =>
            intersection.object.userData.type === SceneMarkerType.COLLISION
            && intersection.object.userData.partId !== tube.id
        ).sort((a, b) => a.distance - b.distance)
        const closestIntersection = filteredIntersections[0]
        if (closestIntersection && closestIntersection.distance < DISTANCE_TO_ACTIVATE_GUIDELINE) {
            const intersectionMarkerDirection = MeshUtils.copyWorldDirection(closestIntersection.object)
            const intersectionMarkerPosition = MeshUtils.copyWorldPosition(closestIntersection.object)

            if (isCollineal(markerDirection, markerPosition, intersectionMarkerDirection, intersectionMarkerPosition)) {
                const geometry = new BufferGeometry().setFromPoints([markerPosition, intersectionMarkerPosition,])
                const lineMesh = new Line(
                    geometry,
                    new LineBasicMaterial({ color: "red", linewidth: 1, })
                )
                info.current.guidelines.push(lineMesh)
                scene.add(lineMesh)
            }
        }
    })
}

const checkDiagonalEdges = (
    info: MutableRefObject<SegmentedTubeInfo>
) => {
    const startMarker = Object.values(info.current.startSection).length > 0
        ? Object.values(info.current.startSection).find(marker => marker.outer
            && !marker.outer.userData.lateralFacing)!.outer!
        : undefined
    const endMarker = Object.values(info.current.endSection).length > 0
        ? Object.values(info.current.endSection).find(marker => marker.outer
            && !marker.outer.userData.lateralFacing)!.outer!
        : undefined

    info.current.diagonalEdges = {
        start: startMarker ? !areParrallel(startMarker, info.current.originPoint!) : false,
        end: endMarker ? !areParrallel(endMarker, info.current.originPoint!) : false,
    }
}

const getMiddleFreeMarker = (section: SegmentedTubeMarkers, length: number) => {
    const keys = Object.keys(section).sort((a, b) => Number(a) - Number(b))
    return keys[Math.floor(length / 2)]
}

const getMarkerByFreePositions = (
    partId: string,
    connections: PartConnectionType[],
    middleSection: Record<string, SegmentedTubeMarkers>,
    length: number
) => {
    let markerKey: string | undefined
    const connectionsWithPart = connections.filter(c => isInConnection(c, partId))
    const middleSectionSidesKeys = Object.keys(middleSection)
    if (connectionsWithPart.length) {
        const freePositions: number[] = []

        Object.keys(Object.entries(middleSection)[0][1]).forEach((markerSectionKey) => {
            const SomeSideConnected = middleSectionSidesKeys.some((side) =>
                connectionsWithPart.some(c => isInConnection(c, partId, `outer${side}_${markerSectionKey}`))
            )

            if (!SomeSideConnected) {
                freePositions.push(parseInt(markerSectionKey, 10))
            }
        })
        const auxValue = [...freePositions,]
        const freePositionsGroupedByLot: number[][] = []
        freePositions.forEach((v, index) => {
            if (auxValue.length <= 2) {
                freePositionsGroupedByLot.push(auxValue)
            } else if (freePositions[index - 1] !== v - 1 || index === freePositions.length - 1) {
                freePositionsGroupedByLot.push(auxValue.splice(0, auxValue.findIndex(val => val === v)))
            }
        })
        freePositionsGroupedByLot.sort((a, b) => b.length - a.length)
        if (freePositionsGroupedByLot.length) {
            markerKey = freePositionsGroupedByLot[0][Math.floor(freePositionsGroupedByLot[0].length / 2)].toString()
        }
    }
    return markerKey || getMiddleFreeMarker(middleSection[middleSectionSidesKeys[0]], length)
}

const SegmentedTubeUtils = {
    createMergedMesh,
    checkGuidelines,
    getMaxPossibleLength,
    checkMaxPossibleLength,
    checkSnap,
    checkSnapOnEditionMovableSection,
    checkUnsnap,
    checkUnsnapSound,
    cleanUpPreviousOrigins,
    createInstancedMeshOrigins,
    createPool,
    initSegmentedTubeInfo,
    isSomeMovableMarkerConnected,
    processData,
    removeGuidelines,
    setAtachmentPoint,
    setSnappedStateOnLoad,
    updateInstancedTransforms,
    checkAlignments,
    visualizeConnections,
    checkDiagonalEdges,
    getMarkerByFreePositions,
    getTubeDirection,
}

export default SegmentedTubeUtils