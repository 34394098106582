/* eslint-disable no-loop-func */
import React from "react"
import { SliderContainer, SliderText } from "./segmentedTube/ui/ActionPanelStyles"
import { PartConnectionType } from "../../../../../../state/scene/types"
import RelativeMove from "./tube/ui/RelativeMove"
import Button from "../../../../../../../common/components/Button"
type SegmentedSliderProps = {
    connectedToSegmentedParts: PartConnectionType[] | undefined,
    connectionIndex: number,
    setConnectionIndex: (index: number) => void,
}

const SegmentedSlider = (props: SegmentedSliderProps) => {
    const getSlider = () => {
        if (props.connectedToSegmentedParts && props.connectedToSegmentedParts.length > 1) {
            return (
                <>
                    <SliderText>
                        Moving selected relative to:
                        <RelativeMove
                            connectedToSegmentedParts={props.connectedToSegmentedParts}
                            connectionIndex={props.connectionIndex}
                            setConnectionIndex={props.setConnectionIndex}
                        />
                    </SliderText >
                </>
            )
        }
    }

    return <SliderContainer>
        {getSlider()}
    </SliderContainer>
}

export default SegmentedSlider