/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Quaternion, Vector3 } from "three"
import {
    CategoryTypeAPIRaw,
    ConnectionTypeAPI,
    PartTypeAPI,
    MaterialAPI,
    SubClassAPI,
    ColorAPI,
    SizeAPI,
    PartTypeAPIRaw,
    CategoryTypeAPI,
    ShopifyIdAPI
} from "./Types"
import { GenericPartState, PartTypeEnum, TubeMarkerEnum } from "../../builder/exportables"
import { mapAsync, ObjDictionary, toDictionary, flatten, filterWithValue } from "../utils/utils"
import { connectionTypesCollection, GetDataFromFirebase } from "./firebase/firebaseUtils"
import { CategoryAdapter, partAdapter } from "./adapters"
import { query, where, documentId } from "firebase/firestore"
import { searchCompatibleParts } from "./algoliaUtils"
import { EnvHelper } from "../utils/EnvHelper"

const getRotationMeshName = (meshName: string): string => {
    const numberMatch = meshName.match(/\d+/)
    if (numberMatch) {
        const number = numberMatch[0]
        return `rotation${number}`
    }
    return meshName
}

const translateToPartValue = (
    part: PartTypeAPI,
    id: string,
    position: Vector3,
    rotation: Quaternion,
    color: string,
    initialMarkerName?: string,
    initialLength?: number,
    userRotation?: number,
): GenericPartState => {
    if (part.tube) {
        const firstConnection = part.connections[0]
        const diameter = part.diameters![firstConnection.connectorId]
        return {
            loaded: false,
            id,
            color,
            apiTypeId: part.id,
            type: PartTypeEnum.tube,
            position: {
                x: position.x || 0,
                y: position.y || 0,
                z: position.z || 0,
            },
            rotation: {
                x: rotation.x || 0,
                y: rotation.y || 0,
                z: rotation.z || 0,
                w: rotation.w || 0,
            },
            marker: {
                name: firstConnection.placeholderId,
                id: firstConnection.connectorId,
                sizeId: firstConnection.size.id,
                fullLeg: firstConnection.fullLeg,
                iELenght: firstConnection.iELength,
            },
            originMarkerName: TubeMarkerEnum.BOTTOM,
            length: initialLength ? initialLength : part.defaultLength,
            isCreated: false,
            angle: 0,
            opacity: 0,
            direction: {
                x: 0,
                y: 0,
                z: 0,
            },
            diameter: {
                inner: diameter.inner,
                outer: diameter.outer,
            },
            defaultLength: part.defaultLength,
            maxLength: part.maxLength,
            name: part.name,
            rotationDisabled: part.rotationDisabled,
        }
    } else if (part.segmentedTube) {
        return {
            loaded: false,
            id,
            name: part.name,
            position: {
                x: position.x || 0,
                y: position.y || 0,
                z: position.z || 0,
            },
            rotation: {
                x: rotation.x || 0,
                y: rotation.y || 0,
                z: rotation.z || 0,
                w: rotation.w || 0,
            },
            userRotation: userRotation || 0,
            apiTypeId: part.id,
            type: PartTypeEnum.segmentedTube,
            color,
            markers: part.connections.map(c => {
                return {
                    name: c.placeholderId,
                    id: c.connectorId,
                    oppositeOf: c.oppositeOf,
                    sizeId: c.size.id,
                    fullLeg: c.fullLeg,
                    iELenght: c.iELength,
                    position: c.position,
                    lateralFacing: c.lateralFacing,
                    boundary: c.boundary,
                }
            }),
            length: part.defaultMiddles || 3,
            lengthNegativeSide: 0,
            defaultLength: part.defaultLength,
            maxLength: 80,
            fileURL: part.assethPath,
            segmentLength: part.segmentLength!,
            startSegmentLength: part.startSegmentLength!,
            endSegmentLength: part.endSegmentLength!,
            initialMarkerName: initialMarkerName
                ? initialMarkerName : part.connections[0].placeholderId,
            maxMiddles: part.maxMiddles || 80,
            minMiddles: part.minMiddles || 1,
            rotationSteps: part.rotationSteps,
            rotationDisabled: part.rotationDisabled,
            heightScaling: part.heightScaling,
            widthScaling: part.widthScaling,
            realWidth: part.realWidth,
            realHeight: part.realHeight,
            baseName: part.baseName,
        }
    } else {
        return {
            loaded: false,
            id,
            apiTypeId: part.id,
            color,
            type: PartTypeEnum.connector,
            initialMarkerName: initialMarkerName
                ? initialMarkerName : part.connections[0].placeholderId,
            rotationMarkerName: part.specialRotationMarker
                ? getRotationMeshName(initialMarkerName || part.connections[0].placeholderId)
                : initialMarkerName || part.connections[0].placeholderId,
            fileURL: part.assethPath,
            userRotation: userRotation || 0,
            rotation: {
                x: rotation.x,
                y: rotation.y,
                z: rotation.z,
                w: rotation.w,
            },
            position: {
                x: position.x,
                y: position.y,
                z: position.z,
            },
            name: part.name,
            markers: part.connections.map(c => {
                return {
                    name: c.placeholderId,
                    id: c.connectorId,
                    oppositeOf: c.oppositeOf,
                    sizeId: c.size.id,
                    fullLeg: c.fullLeg,
                    iELenght: c.iELength,
                }
            }),
            instanciated: false,
            rotationDisabled: part.rotationDisabled,
            rotationSteps: part.rotationSteps || EnvHelper.rotationStep,
            specialRotationMarker: part.specialRotationMarker ?? false,
        }
    }
}

type CompatibleTypesAndSize = {
    sizeId: string,
    compatibleConnections: ConnectionTypeAPI["compatibleWithMulti"],
}

const removeDuplicatedParts = (parts: PartTypeAPI[]) => {
    const partsDictionary = toDictionary(parts, part => part.id)
    return Object.values(partsDictionary)
}

const getParts = async (
    sizes: ObjDictionary<SizeAPI>,
    compatibleTypesAndSize?: CompatibleTypesAndSize,
    userQuery?: any,
    sizeIgnoreSizeCompatibility?: boolean,
): Promise<PartTypeAPI[]> => {
    if (compatibleTypesAndSize) {
        //console.log("compatibleTypesAndSize", compatibleTypesAndSize)
        //console.log("ignoreSizeCompatibility", sizeIgnoreSizeCompatibility)
        const { sizeId, compatibleConnections, } = compatibleTypesAndSize
        const query = compatibleConnections.map(typeId =>
            (sizeIgnoreSizeCompatibility ? typeId : `${typeId}${sizeId}`)
        )
        //console.log("query", query)
        const parts = flatten(
            await mapAsync(query,
                async (compatibleConnectionId: string) => {
                    return await searchCompatibleParts(compatibleConnectionId, userQuery)
                }
            )
        ).map(p => {
            try {
                return partAdapter(p, sizes)
            } catch {
                return undefined
            }
        })
        return removeDuplicatedParts(filterWithValue(parts))
    } else {
        const query = ["",]
        const parts = flatten(
            await mapAsync(query,
                async (compatibleConnectionId: string) => {
                    return await searchCompatibleParts(compatibleConnectionId, userQuery)
                }
            )
        ).map(p => {
            try {
                return partAdapter(p, sizes)
            } catch {
                return undefined
            }
        })
        return removeDuplicatedParts(filterWithValue(parts))
    }
}

const getSwapParts = async (
    sizes: ObjDictionary<SizeAPI>,
    compatibleA: CompatibleTypesAndSize,
    compatibleB: CompatibleTypesAndSize,
    userQuery: any,
) => {
    const queries = compatibleA.compatibleConnections.flatMap(ccA => {
        return compatibleB.compatibleConnections.map(ccB => {
            return `${ccA}${compatibleA.sizeId} ${ccB}${compatibleB.sizeId}`
        })
    })
    const parts = await Promise.all(queries.map(async query => {
        return filterWithValue((await searchCompatibleParts(query, userQuery)).map(part => {
            try {
                return partAdapter(part, sizes)
            } catch {
                return undefined
            }
        }))
    }))
    return removeDuplicatedParts(parts.flat())
}

const getConnectionTypes = async () => {
    return await GetDataFromFirebase<ConnectionTypeAPI>("connectionTypes")
}

const getCompatibleConnections = async (id: string): Promise<string[]> => {
    const query_ = query(connectionTypesCollection(), where(documentId(), "==", id))
    return (await GetDataFromFirebase<ConnectionTypeAPI>("connectionTypes", query_))[0]
        .compatibleWithMulti
}

const getSizeIgnore = async (id: string): Promise<boolean | null> => {
    const query_ = query(connectionTypesCollection(), where(documentId(), "==", id))
    const results = await GetDataFromFirebase<ConnectionTypeAPI>("connectionTypes", query_)
    //console.log("results of size ignore", results)
    if (results.length > 0 && results[0].hasOwnProperty("ignoreSizeCompatibility")) {
        return results[0].ignoreSizeCompatibility
            === undefined ? null : results[0].ignoreSizeCompatibility
    } else {
        return null
    }
}

const getCategories = async (): Promise<CategoryTypeAPI[]> => {
    return (await GetDataFromFirebase<CategoryTypeAPIRaw>("Categories"))
        .map(c => CategoryAdapter(c))
}

const getMaterials = async () => {
    return await GetDataFromFirebase<MaterialAPI>("Materials")
}

const getSubClasses = async () => {
    return await GetDataFromFirebase<SubClassAPI>("Subclasses")
}

const getSizes = async () => {
    return await GetDataFromFirebase<SizeAPI>("Sizes")
}

const getColors = async () => {
    return await GetDataFromFirebase<ColorAPI>("Colors")
}

const getShopifyIds = async () => {
    return await GetDataFromFirebase<ShopifyIdAPI>("shopifyIDs")
}

const getPartsWithCompatibleConnection = async () => {
    const allCompatibility = await getConnectionTypes()

    return toDictionary(allCompatibility, (compatibleConnection) => compatibleConnection.id)
}

const getInitialData = async () => {
    const [
        sizes,
        colors,
        materials,
        subclasses,
        categories,
        connectionTypes,
        shopifyIds,
    ] = await Promise.all([
        getSizes(),
        getColors(),
        getMaterials(),
        getSubClasses(),
        getCategories(),
        getConnectionTypes(),
        getShopifyIds(),
    ])

    const parts = await getParts(toDictionary(sizes, ({ id, }) => id))

    return {
        sizes: toDictionary(sizes, ({ id, }) => id),
        colors: toDictionary(colors, ({ id, }) => id),
        materials,
        subclasses,
        categories,
        connectionTypes: toDictionary(connectionTypes, ({ id, }) => id),
        parts: parts,
        shopifyIds: toDictionary(shopifyIds, ({ barcode, }) => barcode),
    }
}

export const ApiClient = {
    translateToPartValue,
    getParts,
    getSwapParts,
    getSizes,
    getColors,
    getMaterials,
    getCategories,
    getSubClasses,
    getInitialData,
    getConnectionTypes,
    getCompatibleConnections,
    getSizeIgnore,
    getPartsWithCompatibleConnection,
    removeDuplicatedParts,
    getShopifyIds,
}