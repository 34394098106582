/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react"
import Button from "../../../../../../../../../common/components/Button"
import {
    SliderContainer,
    ButtonContainer,
    SliderText
} from "./ActionPanelStyles"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import RelativeMove from "../../tube/ui/RelativeMove"

type SegmentedSliderProps = {
    freePositions: number[],
    positions: number[],
    currentValue: number,
    onNewPosition: (newPosition: number) => void,
    onMouseDown?: (newPosition: number) => void,
    onMouseUp: (value: number) => void,
    handleFinishEditing: () => void,
    startLength: number,
    segmentLength: number,
    disabledText?: string,
    unit: string,
    updateUnit: (unit: string) => void,
    useDragSlide: boolean,
    connectedToSegmentedParts: PartConnectionType[] | undefined,
    connectionIndex: number,
    setConnectionIndex: (index: number) => void,
}


const SegmentedSlider = (props: SegmentedSliderProps) => {
    const renderSlider = () => {
        if (props.disabledText) {
            return (
                <SliderText>
                    {props.disabledText}
                </SliderText>
            )
        }

        if (props.useDragSlide) {
            if (props.connectedToSegmentedParts && props.connectedToSegmentedParts.length > 1) {
                return (
                    <>
                        <SliderText>
                            Moving selected relative to:
                            <RelativeMove
                                connectedToSegmentedParts={props.connectedToSegmentedParts}
                                connectionIndex={props.connectionIndex}
                                setConnectionIndex={props.setConnectionIndex}
                            />
                        </SliderText>
                    </>
                )
            }
        }
    }

    return <SliderContainer>
        {renderSlider()}
    </SliderContainer>
}

export default SegmentedSlider