import React, { useLayoutEffect } from "react"
import { Typography } from "antd"
import styled from "styled-components"
import { useRecoilState, useRecoilValue } from "recoil"
import { selectedPartSelector } from "../../../../state/scene/selectors"
import {
  PartTypeEnum,
  SegmentedTubePart,
  SegmentedTubeValues,
  TubePart
} from "../../../../utils/Types"
import { roundLength, } from "../../../../utils/utils"
import UnitToggle from "./UnitToggle"
import useUnitConversion from "../utils/UnitUtils"
import { sizeStringAtom } from "../../../../state/scene/atoms"
import { boundingBoxAtom } from "../../../../state/scene/atoms"
import { ReactComponent as SvgButton } from
  "../../../../../../src/common/assets/icons/Dimensions.svg"

const { Text, } = Typography


const SelectedPartNameContainer = styled.div<{ $isMobile: boolean, }>`
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    margin-top: ${({ $isMobile, }) => ($isMobile ? "calc(1rem + 44px)" : "1rem")};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`
const SelectedPartNameText = styled(Text)`
    margin-bottom: 10px;
    color:grey;
`
const SvgButtonContainer = styled.span<{ $isShown: boolean, }>`
    padding-right: 8px;
    cursor: pointer;

    svg path {
        fill: ${({ $isShown, }) => ($isShown ? "blue" : "black")}
    }

    &:hover svg path,
    &:active svg path {
        fill: blue;
    }
`


const getLength = (selectedPart: TubePart | SegmentedTubePart) => {
  if (selectedPart.type === PartTypeEnum.tube) {
    return selectedPart.length
  }

  if (selectedPart.unitRealValue) {
    return selectedPart.unitRealValue
  }

  let adjustedStartLength = selectedPart.startSegmentLength
  let adjustedEndLength = selectedPart.endSegmentLength

  if (adjustedStartLength <= 0.01) {
    adjustedStartLength = 0
  }
  if (adjustedEndLength <= 0.01) {
    adjustedEndLength = 0
  }
  return (selectedPart.length + selectedPart.lengthNegativeSide)
    * selectedPart.segmentLength
    + adjustedStartLength
    + adjustedEndLength
}



const SelectedPartName = ({ isMobile, ...otherProps }:
  { isMobile: boolean, [key: string]: any, }) => {
  const [boundingBox, setBoundingBox,] = useRecoilState(boundingBoxAtom)

  useLayoutEffect(() => {
    setBoundingBox((prevState: Record<string, any>) => ({ ...prevState, show: false, }))
  }, [])

  const handleClick = () => {
    setBoundingBox((prevState: Record<string, any>) => ({ ...prevState, show: !prevState.show, }))
  }

  const selectedPart = useRecoilValue(selectedPartSelector)

  //const sceneData = useRecoilValue(sceneAtom)

  //showHelper(boundingBox, true, sceneData.scene)

  const sizeStringText = useRecoilValue(sizeStringAtom)
  const { convertFromUnit, unit, } = useUnitConversion()
  if (!selectedPart) {
    return (
      <SelectedPartNameContainer $isMobile={isMobile}>
        {sizeStringText.readout && <SvgButtonContainer
          $isShown={boundingBox?.show}
          onClick={handleClick}
        >
          <SvgButton />
        </SvgButtonContainer>}
        <SelectedPartNameText>
          {sizeStringText.readout}
        </SelectedPartNameText>
      </SelectedPartNameContainer>
    )
  }

  const partName = selectedPart.name
  const partType = selectedPart.type
  const partLengthRaw = getLength(selectedPart as TubePart | SegmentedTubePart)
  const partLengthConverted = roundLength(partLengthRaw)
  const partLength = `${partLengthConverted}`

  const getUnitSymbol = (selectedPart: TubePart | SegmentedTubePart, unit: string) => {
    if ((selectedPart as SegmentedTubeValues)?.partUnits) {
      return (selectedPart as SegmentedTubeValues).partUnits
    }
    return unit === "cm" ? " cm" : "\""
  }

  const unitSymbol = getUnitSymbol(selectedPart as TubePart | SegmentedTubePart, unit)

  const isConnector = partType === PartTypeEnum.connector

  const displayText = isConnector
    ? partName
    : `${partName} - ${partLength}${unitSymbol} Length`

  return (
    <SelectedPartNameContainer style={{ flexDirection: "column", }} $isMobile={isMobile}>
      <SelectedPartNameText>
        {displayText}
      </SelectedPartNameText>
      <span id="selected-part-name-root" />
      {!isConnector && !unitSymbol && <UnitToggle />}
    </SelectedPartNameContainer>
  )
}


export default SelectedPartName