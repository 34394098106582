/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable max-len */
import produce from "immer"
import { WritableDraft } from "immer/dist/internal"
import { Snapshot, useRecoilCallback, useRecoilValue } from "recoil"
import { PartTypeAPI } from "../../../common/api/Types"
import { useAddToHistoryPrivate } from "../../components/main/DesignScreen/History"
import {
    assertPartType,
    ConnectorPart,
    GenericPartState,
    PartTypeEnum,
    SegmentedTubePart,
    TubeMarkerEnum,
    TubePart,
    XYZ,
    XYZW
} from "../../utils/Types"
import { filterWithValue } from "../../../common/utils/utils"
import { roundLength } from "../../utils/utils"
import {
    generateNewPart,
    getNewConnections,
    getOtherPartOfTheConnection,
    isInConnection
} from "./util"
import { partsIdsSelector, sceneAtom } from "./atoms"
import { existentPartSelector, partConnections } from "./selectors"
import {
    ConnectedPartType,
    ConnectionOfPart,
    NewPartParamsType,
    OppositePartType,
    PartConnectionType,
    SceneType,
    UnitType
} from "./types"
import {
    addPartModal,
    initialData,
    renderConnectionsData,
    saveDesignAtom,
    selectedItemID,
    uiState
} from "../atoms"
import { AddPartState, RenderConnectionsType } from "../types"
import {
    getOppositeTubeMarker,
    outerToInner,
    getPlaceholderIdWithoutPosition
} from "../../utils/MarkerUtil"
import { layoutState } from "../layoutState"
import { breadcrumb } from "../../../common/utils/sentrySetup"
import { useGlobalAnimation } from "../../components/main/DesignScreen/scene/part/parts/utils/animations/GlobalAnimationProvider"
import { SoundHelper } from "../../components/main/DesignScreen/utils/SoundHelper"

const addConnections = (props: {
    draft: WritableDraft<SceneType>,
    snapshot: Snapshot,
    newPart: GenericPartState,
    placeholderId?: string,
    connectedPart?: ConnectedPartType,
    oppositePart?: OppositePartType,
}) => {
    const { draft, snapshot, newPart, placeholderId, connectedPart, oppositePart, } = props

    const connections = getNewConnections({
        placeholderId,
        newId: newPart.id,
        connectedPart,
        oppositePart,
    })

    connections.forEach(c => {
        draft.connections.push({
            partA: c.newPart,
            partB: c.otherPart,
        })
        const addedConnection = {
            partMarkerName: c.otherPart.markerName,
            destinationPartId: newPart.id,
            destinationMarkerName: placeholderId!,
        }
        updateRotationMarker({
            draft,
            snapshot,
            otherPartId: c.otherPart.partId,
            addedConnection,
        })
    })
}

export const useNewPart = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    const initialDataValue = useRecoilValue(initialData)

    return useRecoilCallback(
        ({ set, snapshot, }) => {
            return (params: NewPartParamsType) => {
                const {
                    part, posAndRot, placeholderId, connectedPart,
                    oppositePart, expandReduce, userRotation, originMarkerName,
                    duplicatedFrom, markerOffset, length, lengthNegativeSide,
                    initialMarkerName,
                } = params

                //console.log(params, "params")

                breadcrumb({
                    message: "Init new part create in state",
                    level: "info",
                })

                const actualPartId = snapshot.getLoadable(partsIdsSelector).getValue()
                    .find(part => part.id === connectedPart?.partId)

                const newPart = generateNewPart({
                    initialDataValue,
                    part,
                    placeholderId: placeholderId && getPlaceholderIdWithoutPosition(placeholderId!),
                    posAndRot,
                    connectionLength: connectedPart?.connectionLength,
                    actualPartType: actualPartId?.type,
                    initialLength: part.id === expandReduce?.partId
                        ? expandReduce.length : undefined,
                    userRotation: userRotation,
                })

                const newPartId = newPart.id

                if (newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.initialMarkerName = placeholderId
                        || newPart.markers[newPart.markers.length - 1].name
                }

                if (newPart.type === PartTypeEnum.tube && newPart.originMarkerName) {
                    newPart.originMarkerName = originMarkerName ?? TubeMarkerEnum.BOTTOM
                }

                if (duplicatedFrom) {
                    newPart.duplicatedFrom = duplicatedFrom
                }
                if (markerOffset) {
                    newPart.markerOffset = markerOffset
                }
                if (length) {
                    newPart.length = length
                }
                if (lengthNegativeSide) {
                    newPart.lengthNegativeSide = lengthNegativeSide
                }
                if (part.partUnits && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.partUnits = part.partUnits
                }
                if (part.unitRealValue && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.unitRealValue = part.unitRealValue
                }
                if (part.scaledSegmentLength && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.scaledSegmentLength = part.scaledSegmentLength
                }
                if (part.segmentScaleFactor && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.segmentScaleFactor = part.segmentScaleFactor
                }
                if (part.modifiedWidth && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.modifiedWidth = part.modifiedWidth
                }
                if (part.modifiedWidthUnits && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.modifiedWidthUnits = part.modifiedWidthUnits
                }
                if (part.modifiedHeight && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.modifiedHeight = part.modifiedHeight
                }
                if (part.modifiedHeightUnits && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.modifiedHeightUnits = part.modifiedHeightUnits
                }
                if (part.realWidth && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.realWidth = part.realWidth
                }
                if (part.realHeight && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.realHeight = part.realHeight
                }
                if (part.baseName && newPart.type === PartTypeEnum.segmentedTube) {
                    newPart.baseName = part.baseName
                }

                breadcrumb({
                    message: "Generate new part",
                    level: "info",
                    data: {
                        partGenerated: {
                            id: newPart.id,
                        },
                        apiPart: {
                            id: part.id,
                        },
                    },
                })

                const updatedAtom = set(sceneAtom, (atom: SceneType) => {
                    const withNewPart = produce(
                        atom,
                        (draft) => {
                            //this is done to avoid messing with the addConnections function
                            //which uses newPart
                            const modNewPart = initialMarkerName
                                ? { ...newPart, initialMarkerName, } : newPart
                            draft.parts[newPart.id] = modNewPart
                            draft.partsIds.push({ id: newPart.id, type: newPart.type, })

                            breadcrumb({
                                message: "Part id is pushed to the partsIds scene array",
                                level: "info",
                                data: {
                                    partGenerated: {
                                        id: newPart.id,
                                    },
                                },
                            })
                            addConnections({
                                draft,
                                snapshot,
                                newPart,
                                placeholderId,
                                connectedPart,
                                oppositePart,
                            })

                            if (expandReduce && expandReduce.partId !== part.id) {
                                const partToExpandReduce = draft.parts[expandReduce.partId]
                                assertPartType(partToExpandReduce, PartTypeEnum.tube)
                                const sourcePartShouldInvert
                                    = partToExpandReduce.id === connectedPart?.partId
                                    && connectedPart.markerName
                                    === partToExpandReduce.originMarkerName

                                const oppositePartShouldInvert
                                    = partToExpandReduce.id
                                    === oppositePart?.oppositeConnectedPart.partId
                                    && oppositePart.oppositeConnectedPart.markerName
                                    === partToExpandReduce.originMarkerName

                                if (sourcePartShouldInvert || oppositePartShouldInvert) {
                                    partToExpandReduce.originMarkerName = getOppositeTubeMarker(
                                        partToExpandReduce.originMarkerName
                                    )
                                }
                                partToExpandReduce.length
                                    = roundLength(partToExpandReduce.length + expandReduce.length)
                            }
                        },
                        addToHistory
                    )
                    return withNewPart
                })

                // Return both the updated atom and the new part ID
                return { updatedAtom, newPartId, }
            }
        }
    )
}

const getOtherConnections = (connections: ConnectionOfPart[], partId: string) => {
    return connections.filter(c => c.destinationPartId !== partId)
}

export const getRotationMarker = (props: {
    snapshot: Snapshot,
    partId: string,
    deletedPartId?: string,
    addedConnection?: ConnectionOfPart,
}) => {
    const { snapshot, partId, deletedPartId, addedConnection, } = props
    const connections = snapshot
        .getLoadable(partConnections(partId))
        .getValue()
    const partValues = snapshot
        .getLoadable(existentPartSelector({ id: partId, }))
        .getValue()
    if (partValues.type === PartTypeEnum.connector) {
        let otherConnections = deletedPartId ? getOtherConnections(
            connections,
            deletedPartId
        ) : connections
        if (addedConnection) {
            otherConnections = [...otherConnections, addedConnection,]
        }
        if (otherConnections.length === 1) {
            return otherConnections[0].partMarkerName
        } else if (otherConnections.length === 2) {
            const oppositeOf = partValues.markers.find(
                m => m.name === outerToInner(otherConnections[0].partMarkerName)
            )?.oppositeOf
            if (oppositeOf && otherConnections[1].partMarkerName.includes(oppositeOf)) {
                return otherConnections[0].partMarkerName
            }
            return ""
        }
        return ""
    }
    return ""
}

const getOriginMarker = (
    snapshot: Snapshot,
    partId: string,
    deletedPartId?: string,
    addedConnection?: ConnectionOfPart,
) => {
    const connections = snapshot
        .getLoadable(partConnections(partId))
        .getValue()
    const partValues = snapshot
        .getLoadable(existentPartSelector({ id: partId, }))
        .getValue()
    assertPartType(partValues, PartTypeEnum.tube)
    let otherConnections = deletedPartId ? getOtherConnections(
        connections,
        deletedPartId
    ) : connections
    if (addedConnection) {
        otherConnections = [...otherConnections, addedConnection,]
    }
    if (otherConnections.length === 1) {
        return otherConnections[0].partMarkerName as TubeMarkerEnum
    } else {
        return partValues.originMarkerName
    }
}

const getRotationMeshName = (meshName: string): string => {
    const numberMatch = meshName.match(/\d+/)
    if (numberMatch) {
        const number = numberMatch[0]
        return `rotation${number}`
    }
    return meshName
}

const updateRotationMarker = (props: {
    draft: WritableDraft<SceneType>,
    snapshot: Snapshot,
    otherPartId: string,
    deletedPartId?: string,
    addedConnection?: ConnectionOfPart,
}) => {
    const {
        draft, snapshot, otherPartId,
        deletedPartId, addedConnection,
    } = props

    const otherPart = draft.parts[otherPartId]
    if (!otherPart) {
        return
    }
    const otherPartInReadable = JSON.parse(JSON.stringify(otherPart, null, 2))
    if (otherPart.type === PartTypeEnum.connector) {
        let newRotationMarker = getRotationMarker({
            snapshot,
            partId: otherPartId,
            deletedPartId,
            addedConnection,
        })

        if (otherPartInReadable.specialRotationMarker) {
            newRotationMarker = getRotationMeshName(newRotationMarker)
        }

        //console.log(newRotationMarker, "updateRotationMarker newRotationMarker")
        //console.log(otherPartInReadable.specialRotationMarker, "updateRotationMarker otherPart.rotationMarkerName")

        if (otherPart.rotationMarkerName !== newRotationMarker && newRotationMarker !== "") {
            otherPart.rotationMarkerName = newRotationMarker
        }
    } else if (otherPart.type === PartTypeEnum.tube) {
        const newOriginMarker = getOriginMarker(
            snapshot,
            otherPartId,
            deletedPartId,
            addedConnection
        )
        if (otherPart.originMarkerName !== newOriginMarker) {
            otherPart.originMarkerName = newOriginMarker
        }
    }
}

export const useDeletePart = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    return useRecoilCallback(
        ({ set, snapshot, }) => {
            return (id: string) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(atom, (draft) => {
                        const index = draft.partsIds.findIndex((v) => {
                            return v.id === id
                        })
                        draft.partsIds.splice(index, 1)
                        delete draft.parts[id]
                        filterWithValue(
                            draft.connections.map((v, i) => (
                                isInConnection(v, id)
                                    ? i
                                    : undefined))
                        )
                            .reverse()
                            .forEach(i => {
                                const connection = draft.connections[i]
                                const otherPartId = getOtherPartOfTheConnection(connection, id)
                                updateRotationMarker({
                                    draft,
                                    snapshot,
                                    otherPartId,
                                    deletedPartId: id,
                                })

                                draft.connections.splice(i, 1)
                            })
                    },
                        addToHistory)
                })
            }
        }
    )
}

export const useUpdateConnector = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    return useRecoilCallback(
        ({ set, }) => {
            return (
                id: string,
                updater: (c: WritableDraft<ConnectorPart>) => void,
                ignoreHistory?: boolean
            ) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(atom, (draft) => {
                        const toModify = draft.parts[id]
                        assertPartType(toModify, PartTypeEnum.connector)
                        updater(toModify)
                    },
                        ignoreHistory ? undefined : addToHistory
                    )
                })
            }
        }
    )
}

export const useUpdateTube = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    return useRecoilCallback(
        ({ set, }) => {
            return (id: string, updater: (t: WritableDraft<TubePart>) => void) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(atom, (draft) => {
                        const toModify = draft.parts[id]
                        assertPartType(toModify, PartTypeEnum.tube)
                        updater(toModify)
                    },
                        addToHistory)
                })
            }
        }
    )
}

export const useUpdateSegmentedTube = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    return useRecoilCallback(
        ({ set, }) => {
            return (id: string, updater: (t: WritableDraft<SegmentedTubePart>)
                => void, ignoreHistory?: boolean) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(atom, (draft) => {
                        const toModify = draft.parts[id]
                        assertPartType(toModify, PartTypeEnum.segmentedTube)
                        updater(toModify)
                    },
                        ignoreHistory ? undefined : addToHistory)
                })
            }
        }
    )
}

export const useSetSnap = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    return useRecoilCallback(
        ({ set, snapshot, }) => {
            return (id: string, length: number, connection: PartConnectionType) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(atom, (draft) => {
                        const toModify = draft.parts[id]
                        assertPartType(toModify, PartTypeEnum.tube)
                        toModify.length = roundLength(length)
                        draft.connections.push(connection)

                        const connectionOfPart = connection.partA.partId === id
                            ? {
                                partMarkerName: connection.partB.markerName,
                                destinationPartId: connection.partA.partId,
                                destinationMarkerName: connection.partA.markerName,
                            }
                            : {
                                partMarkerName: connection.partA.markerName,
                                destinationPartId: connection.partB.partId,
                                destinationMarkerName: connection.partB.markerName,
                            }

                        const part = draft.parts[connectionOfPart.destinationPartId]
                        if (part.type === PartTypeEnum.connector) {
                            const newRotationMarker = getRotationMarker({
                                snapshot,
                                partId: connectionOfPart.destinationPartId,
                                addedConnection: connectionOfPart,
                            })
                            if (part.rotationMarkerName !== newRotationMarker) {
                                part.rotationMarkerName = newRotationMarker
                            }
                        }
                    },
                        addToHistory)
                })
            }
        }
    )
}

const removeConnections = (
    draft: WritableDraft<SceneType>,
    snapshot: Snapshot,
    partId: string,
    markerName: string
) => {
    filterWithValue<number>(
        draft.connections.map((v, i) => ((
            (v.partA.partId === partId && v.partA.markerName === markerName)
                || (v.partB.partId === partId && v.partB.markerName === markerName)
                ? i
                : undefined))
        )
    )
        .reverse()
        .forEach(i => {
            const connection = draft.connections[i]
            const otherPartId = getOtherPartOfTheConnection(connection, partId)
            updateRotationMarker({
                draft,
                snapshot,
                otherPartId,
                deletedPartId: partId,
            })
            draft.connections.splice(i, 1)
        })
}

const removeAllConnectionsForPart = (
    draft: WritableDraft<SceneType>,
    snapshot: Snapshot,
    partId: string,
    triggerAnimation: (animation: string, id: string, markerName: string, color: string) => void
) => {
    filterWithValue<number>(
        draft.connections.map((v, i) => ((
            (v.partA.partId === partId)
                || (v.partB.partId === partId)
                ? i
                : undefined))
        )
    )
        .reverse()
        .forEach(i => {
            const connection = draft.connections[i]
            const otherPartId = getOtherPartOfTheConnection(connection, partId)
            const connectionBelongingToPartId = connection.partA.partId === partId ? connection.partA : connection.partB
            triggerAnimation("unsnap", partId, connectionBelongingToPartId.markerName, "black")
            updateRotationMarker({
                draft,
                snapshot,
                otherPartId,
                deletedPartId: partId,
            })
            draft.connections.splice(i, 1)
        })
}

export const useUnsnap = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    const { triggerAnimation, } = useGlobalAnimation()
    return useRecoilCallback(
        ({ set, snapshot, }) => {
            return (id: string, markerNames?: string[]) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(atom, (draft) => {
                        if (markerNames) {
                            markerNames.forEach(markerName => {
                                removeConnections(draft, snapshot, id, markerName)
                            })
                        } else {
                            SoundHelper.playUnsnap()
                            removeAllConnectionsForPart(draft, snapshot, id, triggerAnimation)
                        }
                    },
                        addToHistory)
                })
            }
        }
    )
}

const getMarkerName = (part: PartTypeAPI, placeholderId: string) => {
    if (part.tube) {
        if (placeholderId.includes("1")) {
            return TubeMarkerEnum.BOTTOM
        } else {
            return TubeMarkerEnum.TOP
        }
    }
    return placeholderId
}

export const useSwapPart = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    const initialDataValue = useRecoilValue(initialData)

    return useRecoilCallback(
        ({ set, }) => {
            return (
                part: PartTypeAPI,
                placeholderId: string,
                source: NonNullable<AddPartState["step1"]["source"]>
                    | RenderConnectionsType["connectionData"],
                expandReduce?: {
                    length: number,
                    partId: string,
                },
                connectionLength?: number
            ) => {
                if (part.tube) {
                    part.defaultLength = source.swap!.actualLength
                }
                const newPart = generateNewPart({
                    initialDataValue,
                    part,
                    placeholderId,
                    connectionLength,
                    posAndRot: source.posAndRot,
                    actualPartType: source?.type,
                    initialLength: part.id === expandReduce?.partId
                        ? expandReduce.length : undefined,
                })
                set(sceneAtom, (atom: SceneType) => {
                    return produce(
                        atom,
                        (draft) => {
                            draft.parts[newPart.id] = newPart
                            draft.partsIds.push({ id: newPart.id, type: newPart.type, })
                            const index = draft.partsIds.findIndex((v) => {
                                return v.id === source.swap!.partToSwapId
                            })
                            draft.partsIds.splice(index, 1)
                            delete draft.parts[source.swap!.partToSwapId]
                            const connections = filterWithValue(
                                draft.connections.map((v, i) => (
                                    isInConnection(v, source.swap!.partToSwapId)
                                        ? i
                                        : undefined))
                            ).reverse()
                            const newConnections: PartConnectionType[] = []

                            connections.forEach(i => {
                                let markerName
                                const connectionDraft = draft.connections[i]
                                const connection: PartConnectionType = {
                                    partA: {
                                        partId: connectionDraft.partA.partId,
                                        markerName: connectionDraft.partA.markerName,
                                    },
                                    partB: {
                                        partId: connectionDraft.partB.partId,
                                        markerName: connectionDraft.partB.markerName,
                                    },
                                }
                                if (isInConnection(connection, source.partId!, source.markerName)) {
                                    markerName = getMarkerName(part, placeholderId)
                                } else {
                                    markerName = getMarkerName(
                                        part,
                                        `inner${part.connections.find(
                                            c => c.placeholderId === placeholderId)!.oppositeOf!}`
                                    )
                                }
                                const thisPart = {
                                    partId: newPart.id,
                                    markerName,
                                }
                                newConnections.push(
                                    connection.partA.partId === source.swap!.partToSwapId
                                        ? {
                                            partA: thisPart,
                                            partB: {
                                                partId: connection.partB.partId,
                                                markerName: connection.partB.markerName,
                                            },
                                        } : {
                                            partA: {
                                                partId: connection.partA.partId,
                                                markerName: connection.partA.markerName,
                                            },
                                            partB: thisPart,
                                        })
                                draft.connections.splice(i, 1)
                            })

                            let oppositeTubeMarkerName
                            newConnections.forEach(nc => {
                                draft.connections.push(nc)
                                if (nc.partA.partId === expandReduce?.partId) {
                                    oppositeTubeMarkerName = nc.partA.markerName
                                } else if (nc.partB.partId === expandReduce?.partId) {
                                    oppositeTubeMarkerName = nc.partB.markerName
                                }
                            })

                            if (expandReduce) {
                                const partToExpandReduce = draft.parts[expandReduce.partId]
                                assertPartType(partToExpandReduce, PartTypeEnum.tube)

                                if (partToExpandReduce.originMarkerName
                                    === oppositeTubeMarkerName) {
                                    partToExpandReduce.originMarkerName = getOppositeTubeMarker(
                                        partToExpandReduce.originMarkerName
                                    )
                                }

                                partToExpandReduce.length
                                    = roundLength(partToExpandReduce.length + expandReduce.length)
                            }
                        },
                        addToHistory
                    )
                })
            }
        }
    )
}

export const useUnmountDesign = () => {
    return useRecoilCallback(
        ({ reset, }) => {
            return () => {
                reset(sceneAtom)
                reset(addPartModal)
                reset(renderConnectionsData)
                reset(selectedItemID)
                reset(saveDesignAtom)
                reset(uiState)
                reset(layoutState)
            }
        }
    )
}

export const useMultipleUpdates = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    return useRecoilCallback(
        ({ set, }) => {
            return (
                partsUpdaters: {
                    id: string,
                    updater: (p: WritableDraft<GenericPartState>) => void,
                }[],
                newConnections: PartConnectionType[],
                ignoreHistory?: boolean
            ) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(
                        atom,
                        (draft) => {
                            partsUpdaters.forEach(partUpdater => {
                                const toModify = draft.parts[partUpdater.id]
                                partUpdater.updater(toModify)
                            })
                            newConnections.forEach(newConnection => {
                                draft.connections.push(newConnection)
                            })
                        },
                        ignoreHistory ? undefined : addToHistory
                    )
                })
            }
        }
    )
}

export const useReplaceConnection = () => {
    const addToHistory = useAddToHistoryPrivate(true)
    return useRecoilCallback(
        ({ set, snapshot, }) => {
            return (
                partId: string,
                markerName: string,
                newConnection: PartConnectionType,
                newPosition: XYZ,
                newRotation?: XYZW,
                ignoreHistory?: boolean
            ) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(
                        atom,
                        (draft) => {
                            if (newPosition) {
                                draft.parts[partId].position = newPosition
                            }
                            if (newRotation) {
                                draft.parts[partId].rotation = newRotation
                            }
                            const index = draft.connections.findIndex(({ partA, partB, }) => {
                                return (partA.partId === partId && partA.markerName === markerName)
                                    || (partB.partId === partId && partB.markerName === markerName)
                            })
                            draft.connections[index] = { ...newConnection, }
                        },
                        ignoreHistory ? undefined : addToHistory
                    )
                })
            }
        }
    )
}

export const useUpdateUnit = () => {
    return useRecoilCallback(
        ({ set, }) => {
            return (
                newUnit: UnitType
            ) => {
                set(sceneAtom, (atom: SceneType) => {
                    return produce(
                        atom,
                        (draft) => {
                            draft.unit = newUnit
                        }
                    )
                })
            }
        }
    )
}
