/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable complexity */
//eslint-disable-next-line max-lines-per-function

import React, { createContext, useEffect, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import {
  ArrowHelper,
  Box3,
  Box3Helper,
  Camera,
  Color,
  DoubleSide,
  EdgesGeometry,
  Group,
  IcosahedronGeometry,
  LineBasicMaterial,
  LineSegments,
  Material,
  MathUtils,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Quaternion,
  Vector3,
} from "three"
import { CameraControls } from "../cameraProvider/CameraControls"
import { useLevaControls } from "../debugProvider/useLevaControls"
import { sceneAtom, boundingBoxAtom } from "../../state/scene/atoms"
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil"
import { isItemSelected, selectedItemID } from "../../state/atoms"
//import { SelectionBox } from "three/examples/jsm/interactive/SelectionBox"
//import { SelectionHelper } from "three/examples/jsm/interactive/SelectionHelper"
import { useComponentRegistry } from "../../providers/multiselectProvider/useComponentMethods"
import useCamera from "../cameraProvider/useCamera"
import { useMultipleUpdates, useUnsnap } from "../../state/scene/setters"
import { PartConnectionType } from "../../state/scene/types"
import { TransformControls } from "@react-three/drei"
import { TransformControls as TransformControlsImpl } from "three-stdlib"
import MultiSelectUI from "./MultiSelectUI"
import TopRightButtons from "./TopRightButtons"
import { selectedPartSelector } from "../../state/scene/selectors"
import { ROTATION_AXES_ENUM } from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import hotkeys from "hotkeys-js"
import { ConvexHull } from "three/examples/jsm/math/ConvexHull"
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry"
import { MeshUtils } from "../../utils/MeshUtils"
import { innerToOuter, outerToInner } from "../../utils/MarkerUtil"
import { SoundHelper } from "../../components/main/DesignScreen/utils/SoundHelper"
import { isMarkerConnected } from "../../state/scene/util"
import { isCompatibleWith2Meshes } from "../../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"
import { connectionTypesSelector } from "../../state/initialDataSelectors"
import useGetDebugVariables from "../../components/main/DesignScreen/utils/useGetDebugVariables"
import isMobile from "ismobilejs"
import { message } from "antd"
import usePinchZoom from "../../utils/usePinchGestures"
import { messageUtils } from "../../components/main/DesignScreen/scene/LowerRightMessages"
import { useGlobalAnimation } from "../../components/main/DesignScreen/scene/part/parts/utils/animations/GlobalAnimationProvider"


interface Props {
  cameraControls: React.MutableRefObject<CameraControls | null>;
  designId: string | undefined;
  children: React.ReactNode;
}
interface DuplicatedPart {
  sourceId: string;
  duplicateId: string;
  sourceRotation: Quaternion;
  initialMarkerName: string;
  sourceLength: number;
  sourceNegativeLength: number;
  status: boolean;
  partUnits?: string;
  unitRealValue?: number;
  scaledSegmentLength?: number;
  segmentScaleFactor?: number;
}
interface PartInfoAndMarkerData {
  partId: string;
  partPosition: Vector3;
  partRotation: Quaternion;
  markers: { name: string, worldPosition: Vector3, worldQuaternion: Quaternion, }[];
  positionMarker?: string;
  rotationMarker?: string;
}


interface PartDataWithMarkerInfo {
  id: string;
  markers: {
    name: string, worldPosition: Vector3, worldQuaternion: Quaternion,
    markerDirection: Vector3, meshObject: Object3D, camera2dPosition?: { x: number, y: number, },
  }[];
  [key: string]: any;
}

type xy = { x: number, y: number, }


export const MultiSelectContext = createContext<
  | {
    selectionMode: boolean,
    // eslint-disable-next-line func-call-spacing
    setSelectionMode: (mode: boolean) => void,
    newPartIds: DuplicatedPart[],
    checkDuplicationStatus: () => "not started" | "running" | "done",
    transformMode: string,
    setTransformMode: (mode: "translate" | "rotate" | "scale" | "off") => void,
    duplicateSelectedParts: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void,
    updateCounter: number,
    setUpdateCounter: (value: number) => void,
    setIdsAsHighlightedAndTurnOnControl: (ids: string[], control: "selection" | "translate" | "rotate") => void,
    hideMultiUIControls: boolean,
    setHideMultiUIControls: (hide: boolean) => void,
    resetSelection: () => void,
    onRotationSliderChange: (rotationAxis: ROTATION_AXES_ENUM, value: number) => void,
    blockUI: boolean,
    setBlockUI: (block: boolean) => void,
  }
  | undefined
>(undefined)



const MultiSelectProvider: React.FC<Props> = ({ children, cameraControls, designId, }) => {
  const selectedPart = useRecoilValue(selectedPartSelector)
  const setIsSelected = useSetRecoilState(isItemSelected(selectedPart?.id ?? ""))
  const { scene, gl, } = useThree()

  const [selectionMode, setSelectionMode,] = useState(false)
  const [highlightedPartIdsLength, setHighlightedPartIdsLength,] = useState<number>(0)
  const highlightedPartIds = useRef<string[]>([])
  //const selectionBoxRef = useRef<SelectionBox | null>(null)
  //const selectionHelperRef = useRef<SelectionHelper | null>(null)

  const [selectionBox, setSelectionBox,] = useState<{ start: { x: number, y: number, }, end: { x: number, y: number, } | null, }>({ start: { x: 0, y: 0, }, end: null, })
  const selectionBoxOverlayRef = useRef<HTMLDivElement | null>(null)
  const [selectionBoxIsDragging, setSelectionBoxIsDragging,] = useState(false)


  const selectionBoundingBox = useRef<Box3>(new Box3())
  const positionForDuplicatedGroup = useRef<Vector3 | null>(null)
  const offsetForDuplicatedGroup = useRef<Vector3 | null>(null)
  const { isPinching, } = usePinchZoom(document.body)
  const boundingBoxRef = useRef<Box3>(new Box3())
  const multipleUpdate = useMultipleUpdates()

  const newPartIds = useRef<DuplicatedPart[]>([])

  const transformsAnchorRef = useRef<Object3D>(new Object3D())

  const highlightedPartsPositionRotationInfo = useRef<PartInfoAndMarkerData[]>([])

  const highlightedPartsConnections = useRef<PartConnectionType[]>([])

  const [transformMode, setTransformMode,] = useState<"off" | "translate" | "rotate" | "scale">(
    "off",
  )
  const [hideMultiUIControls, setHideMultiUIControls,] = useState(false)
  const transformControlsRef = useRef<TransformControlsImpl>(null)

  const [cameraState, setCameraState,] = useState<"rotate" | "pan" | "neither">("pan")
  const [onTopOfTransformControls, setOnTopOfTransformControls,] = useState(false)
  const [updateCounter, setUpdateCounter,] = useState(0)

  const copiedPartIds = useRef<string[]>([])

  const highlightedPartsMarkerInfo = useRef<PartDataWithMarkerInfo[]>([])
  const notHighlightedPartsMarkerInfo = useRef<PartDataWithMarkerInfo[]>([])
  const boundingBoxesOfNotHighlightedParts = useRef<{ boundingBox: Box3, partId: string, }[]>([])

  const [anchor, setAnchor,] = useState<Object3D | null>(null)

  const closeCompatibleMarkers = useRef<{ clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, distance3D?: number, }[]>([])
  const [anchorChanging, setAnchorChanging,] = useState(false)
  const checkIntervalRef = useRef<NodeJS.Timeout | null>(null)

  const overlayCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null)
  const lastAnchorValue = useRef<Vector3>(new Vector3(0, 0, 0))
  const lastAnchorRotation = useRef<Quaternion>(new Quaternion())

  const [tempAnchorLock, setTempAnchorLock,] = useState(false)
  //const [anchorLockPositionValue, setAnchorLockPositionValue,] = useState<Vector3 | null>(null)
  const selectionBox2D = useRef<{ startPoint: xy, endPoint: xy, }>({ startPoint: { x: 0, y: 0, }, endPoint: { x: 0, y: 0, }, })

  const unsnap = useUnsnap()

  const connectionTypes = useRecoilValue(connectionTypesSelector)
  const { triggerAnimation, } = useGlobalAnimation()

  const { getVariables, } = useGetDebugVariables()
  const isAdmin = getVariables().isAdmin

  const [isCreatingModels, setIsCreatingModels,] = useState(false)
  const lastOffsetAppliedTime = useRef<number>(performance.now())
  const intersectingIds = useRef<string[]>([])
  const pointerStartPosition = useRef<{ x: number, y: number, }>({ x: 0, y: 0, })
  const CLICK_THRESHOLD = 5 // pixels

  const previousParentsToMergedMeshes = useRef<Map<string, { parentUuid: string, partId: string, }>>(new Map())

  const [blockUI, setBlockUI,] = useState(false)

  useEffect(() => {
    // Create selection box element
    const selectionBoxElement = document.createElement("div")
    selectionBoxElement.style.position = "fixed"
    selectionBoxElement.style.border = "1px solid #55aaff"
    selectionBoxElement.style.backgroundColor = "rgba(75, 160, 255, 0.3)"
    selectionBoxElement.style.pointerEvents = "none"
    selectionBoxElement.style.display = "none"
    document.body.appendChild(selectionBoxElement)
    selectionBoxOverlayRef.current = selectionBoxElement

    return () => {
      // Clean up
      if (selectionBoxOverlayRef.current) {
        document.body.removeChild(selectionBoxOverlayRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (selectionBoxOverlayRef.current && selectionBoxIsDragging && selectionBox.start && selectionBox.end) {
      const left = Math.min(selectionBox.start.x, selectionBox.end.x)
      const top = Math.min(selectionBox.start.y, selectionBox.end.y)
      const width = Math.abs(selectionBox.end.x - selectionBox.start.x)
      const height = Math.abs(selectionBox.end.y - selectionBox.start.y)

      selectionBoxOverlayRef.current.style.left = `${left}px`
      selectionBoxOverlayRef.current.style.top = `${top}px`
      selectionBoxOverlayRef.current.style.width = `${width}px`
      selectionBoxOverlayRef.current.style.height = `${height}px`
      selectionBoxOverlayRef.current.style.display = "block"


    } else if (selectionBoxOverlayRef.current) {
      selectionBoxOverlayRef.current.style.display = "none"
    }
  }, [selectionBox.start.x, selectionBox.start.y, selectionBox.end?.x, selectionBox.end?.y, selectionBoxIsDragging,])

  useEffect(() => {
    if (isAdmin) {
      overlayCanvasRef.current = createOverlayCanvas()
      ctxRef.current = overlayCanvasRef.current.getContext("2d")

      return () => {
        if (overlayCanvasRef.current) {
          console.log("removing overlay canvas")
          document.body.removeChild(overlayCanvasRef.current)
        }
      }
    }
  }, [])

  const requestidleCallbackWithFallback = (callback: () => void, timeout: number) => {
    if (typeof window !== "undefined" && "requestIdleCallback" in window) {
      (window as any).requestIdleCallback(callback, { timeout, })
    } else {
      setTimeout(callback, timeout)
    }
  }

  //canvas debugging methods
  const clearCanvas = () => {
    if (ctxRef.current && overlayCanvasRef.current) {
      ctxRef.current.clearRect(0, 0, overlayCanvasRef.current.width, overlayCanvasRef.current.height)
    }
  }
  const createOverlayCanvas = () => {
    const canvasId = '"multiselect"-overlay-canvas'

    const existingCanvas = document.getElementById(canvasId)
    if (existingCanvas) {
      //existingCanvas.remove()
      //console.log("existing canvas", existingCanvas)
    }
    const canvas = document.createElement("canvas",)
    canvas.id = canvasId
    canvas.style.position = "absolute"
    canvas.style.top = "0"
    canvas.style.left = "0"
    canvas.style.backgroundColor = "rgba(0, 0, 0, 0.0)"
    canvas.style.pointerEvents = "none"
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    canvas.style.zIndex = "1000" // Ensure it's on top of other elements
    document.body.appendChild(canvas)
    //console.log("Overlay canvas created and added to the DOM")
    return canvas
  }

  const drawPoint = (ctx: CanvasRenderingContext2D, x: number, y: number, radius = 5, color = "red") => {
    //console.log("Drawing point:", { x, y, radius, color, })
    if (ctxRef.current && overlayCanvasRef.current) {
      ctxRef.current.beginPath()
      ctxRef.current.arc(x, y, radius, 0, 2 * Math.PI, false)
      ctxRef.current.globalAlpha = 0.40
      ctxRef.current.fillStyle = color
      ctxRef.current.fill()
    } else {
      console.warn("ctxRef.current or overlayCanvasRef.current is null or undefined")
    }

  }

  const drawRectangle = (x: number, y: number, width: number, height: number, color = "red") => {
    if (ctxRef.current) {
      ctxRef.current.beginPath()
      ctxRef.current.rect(x, y, width, height)
      ctxRef.current.fillStyle = color
      ctxRef.current.fill()
    }
  }

  const checkDuplicationStatus = () => {
    if (newPartIds.current.length === 0 || newPartIds.current.length === undefined) {
      return "not started"
    }
    const allCompleted = newPartIds.current.every(
      (part) =>
        part.status,
    )

    if (allCompleted) {
      return "done"
    }

    const anyStarted = newPartIds.current.some(
      (part) =>
        part.status,
    )
    return anyStarted ? "running" : "not started"
  }

  const getLatestSceneData = useRecoilCallback(
    ({ snapshot, }) =>
      () => {
        return snapshot.getLoadable(sceneAtom).contents
      },
    [],
  )

  const getLatestBoundingBox = useRecoilCallback(
    ({ snapshot, }) =>
      () => {
        return snapshot.getLoadable(boundingBoxAtom).contents
      },
    [],
  )

  boundingBoxRef.current = getLatestBoundingBox().box

  const { disableCamera, enableCamera, cameraPanZoomRotateStates, findEmptyPositionForBox, drawVector3Point, } = useCamera()

  const { getComponent, } = useComponentRegistry()

  const isInArea = (event: MouseEvent) => {
    const targetArea = {
      x: 0,
      y: window.innerHeight - 150,
      width: 150,
      height: 150,
    }
    const withinXRange
      = event.clientX >= targetArea.x && event.clientX <= targetArea.x + targetArea.width
    const withinYRange
      = event.clientY >= targetArea.y && event.clientY <= targetArea.y + targetArea.height

    return withinXRange && withinYRange
  }

  const updateHighlightedPartIdsLengthFromReftoState = () => {
    setHighlightedPartIdsLength(highlightedPartIds.current.length)
  }

  const cam = cameraControls.current

  useEffect(() => {
    //console.log(selectionMode, "selectionMode")
    //console.log(transformMode, "transformMode")
    if (cam && selectionMode) {
      /*selectionBoxRef.current = new SelectionBox(cam.camera, scene)
      selectionHelperRef.current = new SelectionHelper(
        gl,
        "custom-select-box",
      )*/
      messageUtils.custom("Switching to Selection Mode...", {
        duration: 1,
      })
      setTimeout(() => {
        messageUtils.custom("Click and drag to select parts", {
          duration: 3,
        })
      }, 1200)
      setTimeout(() => {
        messageUtils.custom("You can rotate, move, duplicate parts freely in this mode", {
          duration: 3,
        })
      }, 4400)

    }
    if (cam && !selectionMode && transformMode === "off") {
      // reset camera state when going back to hand mode
      setCameraState("pan")
    }
  }, [selectionMode,])

  /*const removeHiddenClassFromSelectionHelper = () => {
    if (selectionHelperRef.current && selectionHelperRef.current.element.classList.contains("hidden")) {
      selectionHelperRef.current.enabled = true
      selectionHelperRef.current.element.classList.remove("hidden")
    }
  }

  const addHiddenClassToSelectionHelper = () => {
    if (selectionHelperRef.current && !selectionHelperRef.current.element.classList.contains("hidden")) {
      selectionHelperRef.current.enabled = false
      selectionHelperRef.current.element.classList.add("hidden")
    }
  }*/

  useEffect(() => {
    if (cam) {
      cameraPanZoomRotateStates(cameraState, true, cam,)
    }
  }, [cameraState, cam,])

  const createSelectionBoundingBoxHelper = (removePrevious = false, color = "green") => {
    // Remove any existing selection bounding box helper
    if (removePrevious) {
      scene.children = scene.children.filter(child => child.name !== "selectionBoundingBoxHelper")
    }

    if (!selectionBoundingBox.current.isEmpty()) {
      const helper = new Box3Helper(selectionBoundingBox.current, new Color(color)) // Green color
      helper.name = "selectionBoundingBoxHelper"
      const material = helper.material as Material
      material.transparent = true
      material.opacity = 0.5
      material.depthTest = false
      scene.add(helper)
    }
  }

  //managing all things related to mouse and camera... camera and mouse states and selection box
  useEffect(() => {
    const handlePointerDown = (event: PointerEvent) => {
      pointerStartPosition.current = { x: event.clientX, y: event.clientY, }

      if (!selectionMode) {
        return
      }

      if (!isInArea(event) && cam && selectionMode) {
        //disableCamera(cameraControls.current || undefined)
      }


      /*selectionBoxRef.current.startPoint.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
        0.5,
      )*/

      selectionBox2D.current.startPoint.x = event.clientX
      selectionBox2D.current.startPoint.y = event.clientY

      if (!selectionBoxIsDragging && !isPointInExcludedArea(event.clientX, event.clientY)) {
        setSelectionBox({ start: { x: event.clientX, y: event.clientY, }, end: { x: event.clientX, y: event.clientY, }, },)
        setSelectionBoxIsDragging(true)
      }

    }



    const handlePointerMove = (event: PointerEvent) => {

      const cam = cameraControls.current

      //console.log(transformControlsRef, "transformcontrols ref")

      //isInArea is used for the rotation control in lower left
      if (isInArea(event) && cam) {
        setCameraState("rotate")
        //addHiddenClassToSelectionHelper()
        document.body.style.cursor = "default"
      }


      // Not in the rotation control area
      if (!isInArea(event) && cam) {
        //console.log(selectionMode, "selectionMode")
        //console.log(transformMode, "transformMode")
        //console.log(onTopOfTransformControls, "onTopOfTransformControls")
        if (selectionMode) {
          //removeHiddenClassFromSelectionHelper()
          document.body.style.cursor = "default"
        }
        if (transformMode !== "off") {
          //addHiddenClassToSelectionHelper()
          document.body.style.cursor = "grab"
        }
        if (selectionMode) {
          //console.log("setting camera to neither", "transformMode:", transformMode, "selectionMode:", selectionMode, "onTopOfTransformControls:", onTopOfTransformControls)
          setCameraState("neither")
        }
        if (transformMode !== "off") {
          //console.log("setting camera to pan", "transformMode:", transformMode, "selectionMode:", selectionMode, "onTopOfTransformControls:", onTopOfTransformControls)
          setCameraState("pan")
        }
        if (!selectionMode && transformMode === "off") {
          //console.log("setting camera to pan", "transformMode:", transformMode, "selectionMode:", selectionMode, "onTopOfTransformControls:", onTopOfTransformControls)
          //addHiddenClassToSelectionHelper()
          setCameraState("pan")
        }
      }

      /*if (selectionBoxRef.current && selectionHelperRef.current && selectionHelperRef.current.isDown) {
        selectionBoxRef.current.endPoint.set(
          (event.clientX / window.innerWidth) * 2 - 1,
          -(event.clientY / window.innerHeight) * 2 + 1,
          0.5,
        )
      }*/
      if (selectionMode && selectionBoxIsDragging) {
        setSelectionBox(prev => ({ ...prev, end: { x: event.clientX, y: event.clientY, }, }))
      }
    }

    const getPartIdsWithinSelectionBox = (startPoint2D: xy, endpoint2D: xy,) => {
      const uniquePartIds = new Set()

      const isWithinBox = (point: xy, start: xy, end: xy,) => {
        // Calculate the corners of the box
        const left = Math.min(start.x, end.x)
        const right = Math.max(start.x, end.x)
        const top = Math.min(start.y, end.y)
        const bottom = Math.max(start.y, end.y)

        // Check if the point is within the box
        return point.x >= left && point.x <= right && point.y >= top && point.y <= bottom
      }

      const checkMarkers = (parts: PartDataWithMarkerInfo[]) => {
        //console.log(parts, "parts inside check markers")
        parts.forEach(part => {
          part.markers.forEach(marker => {
            //console.log(marker.camera2dPosition, "marker.camera2dPosition")
            if (isWithinBox(marker.camera2dPosition as xy, startPoint2D, endpoint2D)) {
              uniquePartIds.add(part.id)
            }
          })
        })
      }

      checkMarkers(notHighlightedPartsMarkerInfo.current)
      checkMarkers(highlightedPartsMarkerInfo.current)

      return Array.from(uniquePartIds)
    }

    const isPointInExcludedArea = (x: number, y: number, debug = false): boolean => {
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      if (debug) {
        console.log(windowWidth, "windowWidth")
        console.log(windowHeight, "windowHeight")
        console.log(x, "x")
        console.log(y, "y")
      }

      // Top area
      if (y <= 57) {
        return true
      }

      if (debug) {
        drawRectangle(0, 0, windowWidth, 57, "red")
        console.log("isPointInExcludedArea", x, y)
      }

      // Bottom left area
      if (x <= 170 && y >= windowHeight - 230) {
        return true
      }

      if (debug) {
        drawRectangle(0, windowHeight - 230, 170, 230, "blue")
      }

      // Bottom center area
      const bottomCenterLeft = (windowWidth - 380) / 2
      const bottomCenterRight = bottomCenterLeft + 380
      let heightVar = 80

      // Check for actions-container and adjust heightVar
      const actionsContainer = document.querySelector("#actions-container")

      //console.log(actionsContainer, "actionsContainer")
      if (isMobile(window.navigator).phone) {
        heightVar = 120
      }
      if (actionsContainer && actionsContainer.children.length > 0) {
        heightVar = 150
      }

      if (x >= bottomCenterLeft && x <= bottomCenterRight && y >= windowHeight - heightVar) {
        return true
      }

      if (debug) {
        drawRectangle(bottomCenterLeft, windowHeight - heightVar, 380, heightVar, "green")
      }

      return false
    }

    const handlePointerUp = (event: PointerEvent) => {

      const dx = Math.abs(event.clientX - pointerStartPosition.current.x)
      const dy = Math.abs(event.clientY - pointerStartPosition.current.y)
      const isClick = dx <= CLICK_THRESHOLD && dy <= CLICK_THRESHOLD

      if (transformMode !== "off" && !onTopOfTransformControls && !isPointInExcludedArea(event.clientX, event.clientY, false) && !anchorChanging) {
        if (isClick) {
          //console.log("resetSelection in handlePointerUp")
          //this handles only resetting when not doing a hold and drag
          //when on move move
          resetSelection()
        } else {
          //console.log("Drag detected, not resetting selection")
        }
      }

      if (!selectionMode || isInArea(event)) {
        return
      }

      if (!isInArea(event) && cam && selectionMode) {
        //enableCamera(cameraControls.current || undefined)
      }
      setSelectionBoxIsDragging(false)
      setSelectionBox(prev => ({ ...prev, end: { x: event.clientX, y: event.clientY, }, }))

      /*selectionBoxRef.current.endPoint.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
        0.5,
      )*/
      selectionBox2D.current.endPoint.x = event.clientX
      selectionBox2D.current.endPoint.y = event.clientY

      const singleClickCheck = () => {
        const xDistance = Math.abs(selectionBox2D.current.startPoint.x - selectionBox2D.current.endPoint.x)
        const yDistance = Math.abs(selectionBox2D.current.startPoint.y - selectionBox2D.current.endPoint.y)
        return xDistance < 1 && yDistance < 1
      }

      const isSingleClick = singleClickCheck()

      const partIds = getPartIdsWithinSelectionBox(selectionBox2D.current.startPoint, selectionBox2D.current.endPoint)
      //console.log(partIds, "partIds from new funk")

      if (isSingleClick) {
        // Check if the click is outside excluded areas

        //console.log(isSingleClick, "isSingleClick")

        //this handles the resseting in selection mode

        if (!isPointInExcludedArea(event.clientX, event.clientY)) {
          resetSelection()
        }
      } else if (partIds.length > 0) {
        {
          updateSelection(undefined, partIds as string[], undefined, true)
        }
      }
    }

    document.addEventListener("pointerdown", handlePointerDown)
    document.addEventListener("pointermove", handlePointerMove)
    document.addEventListener("pointerup", handlePointerUp)

    return () => {
      document.removeEventListener("pointerdown", handlePointerDown)
      document.removeEventListener("pointermove", handlePointerMove)
      document.removeEventListener("pointerup", handlePointerUp)
    }
  }, [selectionMode, transformMode, onTopOfTransformControls, selectionBoxIsDragging, transformControlsRef.current, anchorChanging,])



  const getOffsetFromFirstTube = () => {
    // Step 1: Retrieve the position values of the first tube using highlightedPartIds[0]

    const firstTubeId = highlightedPartIds.current[0]
    const firstTube = getComponent(firstTubeId)

    //console.log(firstTubeId, "getOffsetFromFirstTube firstTubeId")
    //console.log(firstTube, "getOffsetFromFirstTube firstTube")

    if (firstTubeId && firstTube) {
      const tubeInfo = firstTube.getPartInfo()
      //console.log(tubeInfo, "getOffsetFromFirstTube tubeinfo")
      const tubePosition = new Vector3(
        tubeInfo.position.x,
        tubeInfo.position.y,
        tubeInfo.position.z,
      )

      // Step 2: Calculate the world position of the center of the selectionBoundingBox
      const boundingBoxCenter = new Vector3()
      selectionBoundingBox.current.getCenter(boundingBoxCenter)

      //drawVector3Point(boundingBoxCenter, scene, 0xff0000, 0.005)
      //drawVector3Point(tubePosition, scene, 0xffff00, 0.005)

      // Step 3: Compute the offset values (x, y, z) between the two positions
      const offset = new Vector3(
        boundingBoxCenter.x - tubePosition.x,
        boundingBoxCenter.y - tubePosition.y,
        boundingBoxCenter.z - tubePosition.z,
      )

      // Step 4: Return these offset values as a Vector3
      return { offset, firstTubeId, tubePosition, }
    }
  }

  const updateSelectionBoundingBoxFromHighlightedPartIds = (debug = false) => {
    const containerBox = new Box3()
    highlightedPartIds.current.forEach((partId) => {
      const component = getComponent(partId)
      if (component) {
        const partBoundingBox = component.getBoundingBox()
        if (partBoundingBox !== undefined) {
          containerBox.union(partBoundingBox)
        }
        if (debug) {
          const helper = new Box3Helper(partBoundingBox, new Color(0xff0000)) // Red color
          helper.name = `debug_bounding_box_${partId}`
            ; (helper.material as Material).transparent = true
            ; (helper.material as Material).opacity = 0.5
          scene.add(helper)
        }
      }
    })
    selectionBoundingBox.current = containerBox

    if (containerBox.isEmpty() === false && debug) {
      const helper = new Box3Helper(containerBox, new Color("red"))
        ; (helper.material as Material).transparent = true
        ; (helper.material as Material).opacity = 0.2
      scene.add(helper)
    }
  }

  const selectAndSortMeshes = (meshes: Mesh[], limitMarkersPerModel?: number, forceIncludeMarkers?: string[]): Mesh[] => {
    return meshes
  }

  useEffect(() => {
    hotkeys.unbind("delete")
    hotkeys.unbind("backspace")

    hotkeys("command+a,ctrl+a", (event, handler) => {
      event.preventDefault()
      highlightAllParts()
      setSelectionMode(true)
    })
    hotkeys("delete,backspace", (event, handler) => {
      console.log("delete,backspace in multiselectprovider")
      event.preventDefault()
      if (highlightedPartIds.current.length > 0) {
        deleteSelectedParts()
      }
    })
    hotkeys("command+c,ctrl+c", (event, handler) => {
      event.preventDefault()
      copiedPartIds.current = highlightedPartIds.current
    })
    hotkeys("command+v,ctrl+v", (event, handler) => {
      event.preventDefault()
      if (copiedPartIds.current.length > 0) {
        duplicateSelectedParts(false, copiedPartIds.current)
      }
    })

    // Cleanup function
    return () => {
      hotkeys.unbind("command+a,ctrl+a")
      hotkeys.unbind("delete,backspace")
      hotkeys.unbind("command+c,ctrl+c")
      hotkeys.unbind("command+v,ctrl+v")
    }
  }, [selectionMode,])

  function getMiddleMeshes(allMeshes: Mesh[]): Record<string, Mesh> {
    const meshGroups: Record<string, Mesh[]> = {}

    // Group meshes by their prefix
    allMeshes.forEach(mesh => {
      const [prefix, number,] = mesh.name.split("_")
      if (!meshGroups[prefix]) {
        meshGroups[prefix] = []
      }
      meshGroups[prefix].push(mesh)
    })

    const middleMeshes: Record<string, Mesh> = {}

    // Find the middle mesh for each group with multiple occurrences
    Object.keys(meshGroups).forEach(prefix => {
      const group = meshGroups[prefix]
      if (group.length > 1) {
        // Sort the group by the number after the underscore
        group.sort((a, b) => {
          const numA = parseInt(a.name.split("_")[1], 10)
          const numB = parseInt(b.name.split("_")[1], 10)
          return numA - numB
        })

        // Find the middle index
        const middleIndex = Math.floor(group.length / 2)
        middleMeshes[prefix] = group[middleIndex]
      }
    })

    return middleMeshes
  }


  const createConnectionsPerIdMap = (connections: PartConnectionType[]): Record<string, { partId: string, markers: string[], }> => {
    return connections.reduce((acc: Record<string, { partId: string, markers: string[], }>, connection: PartConnectionType) => {
      const { partA, partB, } = connection

      const updateAcc = (part: { partId: string, markerName: string, }) => {
        if (!acc[part.partId]) {
          acc[part.partId] = { partId: part.partId, markers: [], }
        }
        acc[part.partId].markers.push(part.markerName)
      }

      updateAcc(partA)
      updateAcc(partB)

      return acc
    }, {})
  }

  const findMatchingConnections = (newConnections: PartConnectionType[]): PartConnectionType[] => {
    const currentConnections = getLatestSceneData().connections
    const matchingConnections: PartConnectionType[] = []

    currentConnections.forEach((currentConn: PartConnectionType) => {
      newConnections.forEach((newConn: PartConnectionType) => {
        const isMatch = (
          (currentConn.partA.partId === newConn.partA.partId && currentConn.partB.partId === newConn.partB.partId)
          || (currentConn.partA.partId === newConn.partB.partId && currentConn.partB.partId === newConn.partA.partId)
        )

        if (isMatch && !matchingConnections.some(conn =>
          (conn.partA.partId === currentConn.partA.partId && conn.partB.partId === currentConn.partB.partId)
          || (conn.partA.partId === currentConn.partB.partId && conn.partB.partId === currentConn.partA.partId)
        )) {
          matchingConnections.push(currentConn)
        }
      })
    })

    return matchingConnections
  }


  const checkConnectionsSnapUnsnap = (Unsnap = true, processNewConnections = false) => {
    const TOLERANCE = 0.05 // Adjust this value as needed
    const latestSceneData = getLatestSceneData()
    const connections = latestSceneData.connections

    //console.log(getLatestSceneData().connections, "getLatestSceneData().connections starting")

    const getWorldPosition = (markerName: string, partId: string) => {
      const worldPosition = new Vector3()
      scene.traverse((object) => {
        if (object.name === markerName && object.userData.partId === partId) {
          object.getWorldPosition(worldPosition)
          return false // Stop traversing once we find a match
        }
      })
      return worldPosition
    }

    const newConnections = connections.map((connection: PartConnectionType) => {
      const partAWorldPosition = getWorldPosition(connection.partA.markerName, connection.partA.partId)
      const partBWorldPosition = getWorldPosition(connection.partB.markerName, connection.partB.partId)

      return {
        partA: {
          ...connection.partA,
          worldPosition: partAWorldPosition,
        },
        partB: {
          ...connection.partB,
          worldPosition: partBWorldPosition,
        },
      }
    })

    const stillConnected: PartConnectionType[] = []
    const notConnectedAnymore: PartConnectionType[] = []
    let notConnectedAnymorePerID: Record<string, { partId: string, markers: string[], }> = {}

    newConnections.forEach((connection: PartConnectionType & { partA: { worldPosition: Vector3, }, partB: { worldPosition: Vector3, }, }) => {
      const distance = connection.partA.worldPosition.distanceTo(connection.partB.worldPosition)
      if (distance <= TOLERANCE) {
        stillConnected.push(connection)
      } else {
        notConnectedAnymore.push(connection)
      }
    })

    // Filter out connections where both parts are in highlightedPartIds.current or
    //both are not in highlightedPartIds.current
    const filteredNotConnectedAnymore = notConnectedAnymore.filter(connection => {
      const partAHighlighted = highlightedPartIds.current.includes(connection.partA.partId)
      const partBHighlighted = highlightedPartIds.current.includes(connection.partB.partId)
      return (partAHighlighted && !partBHighlighted) || (!partAHighlighted && partBHighlighted)
    })

    if (Unsnap) {
      // Create NotConnectedAnymorePerID
      notConnectedAnymorePerID = createConnectionsPerIdMap(filteredNotConnectedAnymore)

      //console.log(notConnectedAnymorePerID, "notConnectedAnymorePerID")

      //for debugging only to figure out which parts ara not connected anymore
      /* const notConnectedPartIds = Object.values(notConnectedAnymorePerID).map(({ partId, }) => partId)
      setTimeout(() => {
        resetSelection()
        updateSelection(undefined, notConnectedPartIds)
      }, 3000)*/
      //


      // Run unsnap for each partId
      const count = Object.values(notConnectedAnymorePerID).length

      if (Object.values(notConnectedAnymorePerID).length > 0) {
        Object.values(notConnectedAnymorePerID).forEach(({ partId, markers, }) => {
          unsnap(partId, markers)
          //console.log("during unsnap -> usnapping for", partId, markers)
          triggerAnimation("unsnap", partId, markers[0], "black")
        })
        messageUtils.custom(`Unsnapping ${count} part${count > 1 ? "s" : ""}... `, {
          duration: 1, showSpinner: true, forceShow: true,
        })
        SoundHelper.playUnsnap()
      }

      if (processNewConnections) {
        const { threedResults, } = checkCloseCollinearMarkers3DOnly(false, 0.0005, true)

        //console.log(threedResults, "threedResults from close collinear")

        const newConnections = processFilteredResults(threedResults)

        //find the existing connections for the same parts as the new connections
        //need to remove these
        const matchingExistingConnections = findMatchingConnections(newConnections)

        //create them in a format for unsnap function
        const matchingExistingConnectionsPerID = createConnectionsPerIdMap(matchingExistingConnections)

        // Run unsnap for each partId
        if (Object.values(matchingExistingConnectionsPerID).length > 0) {
          SoundHelper.playUnsnap()
          Object.values(matchingExistingConnectionsPerID).forEach(({ partId, markers, }) => {
            unsnap(partId, markers)
            const count = Object.values(matchingExistingConnectionsPerID).length
            triggerAnimation("unsnap", partId, markers[0], "black")
            messageUtils.custom(`Unsnapping ${count} part${count > 1 ? "s" : ""}... `, {
              duration: 1, showSpinner: true, forceShow: true,
            })
            //console.log("during unsnap for existing connections -> usnapping for", partId, markers)
          })
        }

        //now lets add the new connections
        if (newConnections.length > 0) {
          // console.log(newConnections, "newConnections")
          // console.log(connections, "connections")
          setTimeout(() => {
            multipleUpdate([], newConnections, false)
            newConnections.forEach((connection: PartConnectionType) => {
              triggerAnimation("snap", connection.partB.partId, connection.partB.markerName, "blue")
            })
            SoundHelper.playSnap()
            messageUtils.custom("Snapping parts...", {
              duration: 1, showSpinner: true, forceShow: true,
            })
            setTimeout(() => {
              //console.log(getLatestSceneData().connections, "getLatestSceneData().connections after multipleUpdate")
            }, 200)
          }, 200)

          //have to wait for the connections to update
          setTimeout(() => {
            newConnections.forEach((connection: PartConnectionType) => {
              const updateInitialMarkerForPart = (partId: string, markerName: string) => {
                if (markerName === "TOP" || markerName === "BOTTOM") {
                  const component = getComponent(partId)
                  if (component && component.updateInitialMarkerName) {
                    component.updateInitialMarkerName(markerName)
                  }
                }
              }

              updateInitialMarkerForPart(connection.partA.partId, connection.partA.markerName)
              updateInitialMarkerForPart(connection.partB.partId, connection.partB.markerName)
            })
          }, 200)

        }
      }

      //console.log(getLatestSceneData().connections, "getLatestSceneData().connections ending")

    }
    setTimeout(() => {
      afterRotationMovementCleanup()
    }, 250)

    return { stillConnected, notConnectedAnymore, notConnectedAnymorePerID, }
  }

  const storeBoundingBoxesOfNotHighlightedParts = () => {
    notHighlightedPartsMarkerInfo.current.forEach((part, index) => {
      const component = getComponent(part.id)
      if (component) {
        const boundingBox = component.getBoundingBox()
        if (boundingBox) {
          boundingBoxesOfNotHighlightedParts.current.push({ boundingBox, partId: part.id, })
        }
      }
    })
  }



  const createClonedPartsWithMarkerInfo = (debug = false) => {
    const latestSceneData = getLatestSceneData()
    //console.log(latestSceneData, "latestSceneData")
    const clonedParts = JSON.parse(JSON.stringify(latestSceneData.parts))

    const highlightedParts: { [partId: string]: PartDataWithMarkerInfo, } = {}
    const otherParts: { [partId: string]: PartDataWithMarkerInfo, } = {}

    Object.entries(clonedParts).forEach(([partId, part,]: [string, any,]) => {

      const component = getComponent(partId)
      const allMeshes: Mesh[] = component?.getAllMarkers() ?? []

      const initialMarkerName = component?.getPartInfo(partId)?.initialMarkerName

      const finalMeshes = selectAndSortMeshes(allMeshes, 20, initialMarkerName ? [initialMarkerName,] : undefined)

      const processedPart = {
        id: partId,
        markers: finalMeshes.map((mesh) => {
          const worldPosition = new Vector3()
          const worldQuaternion = new Quaternion()
          const markerDirection = MeshUtils.copyWorldDirection(mesh)

          mesh.getWorldPosition(worldPosition)
          mesh.getWorldQuaternion(worldQuaternion)

          if (debug) {
            // Create debug visuals
            const planeGeometry = new PlaneGeometry(0.01, 0.01)
            const planeMaterial = new MeshBasicMaterial({
              color: 0xff0000,
              side: DoubleSide,
              wireframe: true,
              depthTest: false,
            })
            const planeMesh = new Mesh(planeGeometry, planeMaterial)
            planeMesh.position.copy(worldPosition)
            planeMesh.quaternion.copy(worldQuaternion)
            scene.add(planeMesh)

            const arrowHelper = new ArrowHelper(
              markerDirection,
              worldPosition,
              0.1,
              0xff0000,
              0.02,
              0.01
            )
              ; (arrowHelper.line.material as LineBasicMaterial).depthTest = false
            scene.add(arrowHelper)
          }

          return {
            name: mesh.name,
            worldPosition,
            worldQuaternion,
            markerDirection,
            meshObject: mesh,
          }
        }),
      }

      if (highlightedPartIds.current.includes(partId)) {
        highlightedParts[partId] = processedPart
      } else {
        otherParts[partId] = processedPart
      }
      //console.log(processedPart, "processedPart")
    })

    return { highlightedParts, otherParts, }
  }

  const highlightAllParts = () => {
    const allPartIds = Object.values(getLatestSceneData().parts).map((part: any) => part.id)
    highlightedPartIds.current = allPartIds
    updateSelection(undefined, allPartIds)
  }

  const updateSelection = (selectedObjects?: Object3D[], partIds?: string[], skipColorUpdate = false, appendIds = false) => {
    const latestSceneData = getLatestSceneData()


    if (selectedObjects && selectedObjects.length === 0 && partIds?.length === 0) {
      return
    }
    const newSelectedPartIds = selectedObjects
      ?.filter((obj) => obj.userData.partId)
      .map((obj) => obj.userData.partId)
      .filter((partId, index, self) => self.indexOf(partId) === index)

    if (partIds && !appendIds) {
      highlightedPartIds.current = partIds
    }
    if (partIds && appendIds) {
      highlightedPartIds.current = Array.from(
        new Set([...highlightedPartIds.current, ...partIds,]),
      )
    } else if (newSelectedPartIds) {
      //console.log(newSelectedPartIds, "newSelectedPartIds")
      highlightedPartIds.current = Array.from(
        new Set([...highlightedPartIds.current, ...newSelectedPartIds,]),
      )
    }

    updateSelectionBoundingBoxFromHighlightedPartIds(false)
    updateHighlightedPartIdsLengthFromReftoState()

    highlightedPartsConnections.current = latestSceneData.connections.filter(
      (connection: PartConnectionType) =>
        highlightedPartIds.current.includes(connection.partA.partId)
        && highlightedPartIds.current.includes(connection.partB.partId),
    )


    //console.log(highlightedPartsConnections.current, "highlightedPartsConnections")
    //console.log(latestSceneData.connections, "latestSceneData.connections")
    //console.log(highlightedPartIds.current, "highlightedPartIds")

    const { highlightedParts, otherParts, } = createClonedPartsWithMarkerInfo(false)

    //console.log(highlightedParts, "highlightedParts")
    //console.log(otherParts, "otherParts")

    highlightedPartsMarkerInfo.current = Object.values(highlightedParts)
    notHighlightedPartsMarkerInfo.current = Object.values(otherParts)

    storeBoundingBoxesOfNotHighlightedParts()

    get2dPositionOfHighlightedMarkers()

    //console.log(highlightedPartsMarkerInfo.current, "highlightedPartsMarkerInfo")
    //console.log(notHighlightedPartsMarkerInfo.current, "notHighlightedPartsMarkerInfo")

    // Change color of selected objects

    highlightedPartIds.current.forEach((partId) => {
      if (partId) {
        const component = getComponent(partId)
        //console.log(component, "component", partId)
        if (component) {
          component.updateColor(0x1b7fe3)
          const partBoundingBox = component.getBoundingBox()
          if (partBoundingBox !== undefined) {
            selectionBoundingBox.current.union(partBoundingBox)
          }
        }
      }
    })

    /* if(selectionBoundingBox.current.isEmpty() === false) {
            const helper = new Box3Helper(selectionBoundingBox.current, new Color("blue"))
            setTimeout(() => {
            scene.add(helper)
            }, 100)
        }*/
  }

  const removeHelpersFromScene = () => {
    scene.children = scene.children.filter(
      (child) => !(child instanceof Box3Helper) && !(child.name === "helper"),
    )
  }
  const resetSelection = () => {
    if (blockUI) {
      return
    }
    if (highlightedPartIds.current.length > 0) {
      highlightedPartIds.current.forEach((partId) => {
        if (partId) {
          const component = getComponent(partId)
          if (component) {
            component.originalColor()
          }
        }
      })
    }
    highlightedPartIds.current = []
    updateHighlightedPartIdsLengthFromReftoState()


    selectionBoundingBox.current = new Box3()
    setTransformMode("off")
    removeHelpersFromScene()
  }

  const createDuplicatedConnections = (
    originalConnections: PartConnectionType[],
    duplicatedParts: DuplicatedPart[],
  ): PartConnectionType[] => {
    const idMap = new Map(duplicatedParts.map((part) => [part.sourceId, part.duplicateId,]))

    return originalConnections.map((connection) => ({
      partA: {
        partId: idMap.get(connection.partA.partId) || connection.partA.partId,
        markerName: connection.partA.markerName,
      },
      partB: {
        partId: idMap.get(connection.partB.partId) || connection.partB.partId,
        markerName: connection.partB.markerName,
      },
    }))
  }

  useEffect(() => {
    let originalCursor: string | undefined
    let clickHandler: ((e: MouseEvent) => void) | undefined
    let overlayElement: HTMLDivElement | undefined

    if (blockUI) {
      // Store the original cursor style
      originalCursor = gl.domElement.style.cursor

      // Set cursor to wait (spinner)
      gl.domElement.style.cursor = "wait"

      // Create an overlay to prevent clicks
      overlayElement = document.createElement("div")
      overlayElement.style.position = "fixed"
      overlayElement.style.top = "0"
      overlayElement.style.left = "0"
      overlayElement.style.width = "100%"
      overlayElement.style.height = "100%"
      overlayElement.style.zIndex = "9999"
      overlayElement.style.cursor = "wait"

      // Disable clicking
      clickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
      }
      overlayElement.addEventListener("click", clickHandler, true)
      overlayElement.addEventListener("mousedown", clickHandler, true)
      overlayElement.addEventListener("mouseup", clickHandler, true)

      document.body.appendChild(overlayElement)
    } else {
      // Restore original cursor
      if (originalCursor !== undefined) {
        gl.domElement.style.cursor = originalCursor
      }

      // Remove overlay if it exists
      if (overlayElement && overlayElement.parentNode) {
        overlayElement.parentNode.removeChild(overlayElement)
      }
    }

    // Cleanup function
    return () => {
      if (overlayElement && overlayElement.parentNode) {
        overlayElement.parentNode.removeChild(overlayElement)
      }
      if (originalCursor !== undefined) {
        gl.domElement.style.cursor = originalCursor
      }
    }
  }, [blockUI, gl.domElement,])


  const duplicateSelectedParts = async (duplicateEverything = false, duplicateSpecificPartsIds?: string[]) => {
    newPartIds.current = []

    setBlockUI(true)

    const latestSceneData = getLatestSceneData()
    boundingBoxRef.current = getLatestBoundingBox().box

    //need some time to get a new position in case it's not updated when someone
    //is duplicating with one intial part
    if (Object.values(latestSceneData.partsIds).length < 2) {
      setUpdateCounter(prev => prev + 1)
      //above gets a new empty position
      await new Promise(resolve => setTimeout(resolve, 50))
    }

    if (boundingBoxRef.current) {
      if (duplicateEverything) {
        selectionBoundingBox.current = boundingBoxRef.current
        highlightedPartIds.current = Object.values(latestSceneData.partsIds).map(
          (part: any) => part.id,
        )
        updateHighlightedPartIdsLengthFromReftoState()
      }

      if (duplicateSpecificPartsIds) {
        highlightedPartIds.current = duplicateSpecificPartsIds
        updateSelectionBoundingBoxFromHighlightedPartIds(false)
        updateHighlightedPartIdsLengthFromReftoState()
      }

      messageUtils.custom(`Duplicating ${highlightedPartIds.current.length} parts...`, {
        duration: 1, showSpinner: true, forceShow: true,
      })

      const { offset, firstTubeId, tubePosition, } = getOffsetFromFirstTube() ?? {}

      positionForDuplicatedGroup.current = findEmptyPositionForBox(
        scene,
        selectionBoundingBox.current,
        boundingBoxRef.current,
        false,
      )

      if (positionForDuplicatedGroup.current && offset && tubePosition) {
        const newPosition = positionForDuplicatedGroup.current.clone().sub(offset)
        const newOffset = newPosition.clone().sub(tubePosition)
        offsetForDuplicatedGroup.current = newOffset
      }
    } else {
      console.warn(boundingBoxRef.current, "boundingBoxRef.current is empty")
    }

    let duplicatedConnections: PartConnectionType[] = []

    newPartIds.current.forEach((part) => {
      part.status = false
    })

    const MAX_RETRIES = 5
    const RETRY_DELAY = 100 // milliseconds

    const checkPartExists = (
      newPartId: string,
      retries = 0,
      callback: (exists: boolean) => void,
    ) => {
      let newPartExists = false
      scene.traverse((object) => {
        if (object.userData.partId === newPartId) {
          newPartExists = true
          return false // Stop traversing once we find a match
        }
      })

      if (newPartExists || retries >= MAX_RETRIES) {
        callback(newPartExists)
        return
      }

      setTimeout(() => {
        checkPartExists(newPartId, retries + 1, callback)
      }, RETRY_DELAY)
    }


    const checkDuplicatedPartsExist = (retryCount = 0, maxRetries = 10) => {
      const latestSceneData = getLatestSceneData()
      const allDuplicatedPartsExist = newPartIds.current.every((newPart: DuplicatedPart) => {
        const partExists = Object.values(latestSceneData.parts).some(
          (part: any) => part.id === newPart.duplicateId,
        )

        let sceneChildrenMatch = false
        scene.traverse((object) => {
          if (
            object.userData.partId === newPart.duplicateId
          ) {
            sceneChildrenMatch = true
            return false // Stop traversing once we find a match
          }
        })
        return partExists && sceneChildrenMatch
      })

      const allConnectionsExistInScene = duplicatedConnections.every((conn) => {
        let partAExists = false
        let partBExists = false
        scene.traverse((object) => {
          if (
            object.userData.partId === conn.partA.partId
            && object.name === conn.partA.markerName
          ) {
            partAExists = true
          }
          if (
            object.userData.partId === conn.partB.partId
            && object.name === conn.partB.markerName
          ) {
            partBExists = true
          }
          if (partAExists && partBExists) {
            return false // Stop traversing if both parts are found
          }
        })

        return partAExists && partBExists
      })

      //console.log(allDuplicatedPartsExist, "allDuplicatedPartsExist")
      //console.log(allConnectionsExistInScene, "allConnectionsExistInScene")

      if (allDuplicatedPartsExist && allConnectionsExistInScene) {
        console.log(
          "All duplicated parts exist in the scene. Proceeding with the rest of the function.",
        )
        proceedWithDuplication()
      } else if (retryCount < maxRetries) {
        console.log(
          `Not all duplicated parts exist yet. Retrying in 500ms. Attempt ${retryCount + 1
          } of ${maxRetries}`,
        )
        setTimeout(() => checkDuplicatedPartsExist(retryCount + 1, maxRetries), 500)
      } else {
        console.error("Max retries reached. Some duplicated parts are missing from the scene.")
        resetSelection()
      }
    }

    const connectionsUpdate = (duplicatedConnections: PartConnectionType[]) => {
      if (duplicatedConnections.length > 0) {
        //console.log(duplicatedConnections, "duplicatedConnections")
        multipleUpdate([], duplicatedConnections, false)
      }
    }

    const proceedWithDuplication = () => {
      duplicatedConnections = createDuplicatedConnections(
        highlightedPartsConnections.current,
        newPartIds.current,
      )
      connectionsUpdate(duplicatedConnections)
      resetSelection()
      setBlockUI(false)
      setTimeout(() => {
        setUpdateCounter(prev => prev + 1)
      }, 500)
      //console.log(newPartIds.current, "newPartIds.current")
    }

    if (highlightedPartIds.current.length > 0 && offsetForDuplicatedGroup.current) {
      const processPart = (index: number) => {
        if (index >= highlightedPartIds.current.length) {
          console.log("All parts processed. Checking duplicated parts exist...")
          checkDuplicatedPartsExist()
          return
        }

        const partId = highlightedPartIds.current[index]
        if (!partId) {
          processPart(index + 1)
          return
        }

        const component = getComponent(partId)
        if (!component) {
          console.warn("component not found", partId)
          processPart(index + 1)
          return
        }

        const tubeInfo = component.getPartInfo()

        if (!tubeInfo || !tubeInfo.position || !offsetForDuplicatedGroup.current) {
          console.warn("Invalid tube info or offset", partId)
          processPart(index + 1)
          return
        }

        const currentPosition = new Vector3(
          tubeInfo.position.x,
          tubeInfo.position.y,
          tubeInfo.position.z,
        )
        const newPosition = currentPosition.add(offsetForDuplicatedGroup.current)

        let currentOffset = new Vector3(0, 0, 0)
        let newOffsetPosition = new Vector3(0, 0, 0)
        if (tubeInfo.markerOffset) {
          currentOffset = new Vector3(
            tubeInfo.markerOffset.x,
            tubeInfo.markerOffset.y,
            tubeInfo.markerOffset.z,
          )
          newOffsetPosition = currentOffset.add(offsetForDuplicatedGroup.current)
        }
        const newPartId = component.duplicatePart(
          newPosition,
          tubeInfo.initialMarkerName || tubeInfo.originMarkerName,
          tubeInfo.markerOffset ? newOffsetPosition : undefined,
          tubeInfo.rotation || undefined,
          tubeInfo.length ?? undefined,
          tubeInfo.lengthNegativeSide ?? undefined,
          tubeInfo.partUnits ?? undefined,
          tubeInfo.unitRealValue ?? undefined,
          tubeInfo.scaledSegmentLength ?? undefined,
          tubeInfo.segmentScaleFactor ?? undefined,
          tubeInfo.modifiedWidth ?? undefined,
          tubeInfo.modifiedWidthUnits ?? undefined,
          tubeInfo.modifiedHeight ?? undefined,
          tubeInfo.modifiedHeightUnits ?? undefined,
          tubeInfo.realWidth ?? undefined,
          tubeInfo.realHeight ?? undefined,
          tubeInfo.baseName ?? undefined,
        )

        newPartIds.current.push({
          sourceId: partId,
          duplicateId: newPartId,
          sourceRotation: tubeInfo.rotation,
          initialMarkerName: tubeInfo.initialMarkerName || tubeInfo.originMarkerName,
          sourceLength: tubeInfo.length,
          sourceNegativeLength: tubeInfo.lengthNegativeSide,
          partUnits: tubeInfo.partUnits,
          unitRealValue: tubeInfo.unitRealValue,
          scaledSegmentLength: tubeInfo.scaledSegmentLength,
          segmentScaleFactor: tubeInfo.segmentScaleFactor,
          status: true,
        })

        if (!newPartId) {
          console.warn("Failed to duplicate part", partId)
          processPart(index + 1)
          return
        }

        const checkNewPart = (retries = 0) => {
          checkPartExists(newPartId, retries, (exists) => {
            if (exists) {
              getComponent(newPartId)?.setUItoNone()
              //console.log(`New part ${newPartId} successfully created and found in scene.`)
              processPart(index + 1)
            } else if (retries < MAX_RETRIES) {
              setTimeout(() => checkNewPart(retries + 1), RETRY_DELAY)
            } else {
              console.warn(
                `New part ${newPartId} not found in scene after ${MAX_RETRIES} attempts. Skipping.`,
              )
              processPart(index + 1)
            }
          })
        }

        checkNewPart()
      }

      processPart(0)
    } else {
      //console.log(highlightedPartIds.current, "highlightedPartIds.current maybe empty")
      //console.log(offsetForDuplicatedGroup.current, "offsetForDuplicatedGroup.current maybe empty")
    }

  }

  const deleteSelectedParts = () => {
    if (highlightedPartIds.current.length > 0) {
      highlightedPartIds.current.forEach((partId) => {
        if (partId) {
          const component = getComponent(partId)
          if (component) {
            component.deletePart()
          }
        }
      })
    }
    messageUtils.custom(`Deleted ${highlightedPartIds.current.length} parts...`, {
      duration: 1, forceShow: true,
    })
    resetSelection()
  }

  const removeAnchors = () => {
    const anchorsToRemove: Object3D[] = []
    scene.traverse((object) => {
      if (object.name === "Transforms Anchor") {
        anchorsToRemove.push(object)
      }
    })

    anchorsToRemove.forEach((anchor) => {
      scene.remove(anchor)
    })
  }

  useEffect(() => {
    scene.add(transformsAnchorRef.current)

  }, [])

  const projectTo2D = (position: Vector3, camera: Camera): { x: number, y: number, } => {
    const vector = position.clone().project(camera)

    // Get the renderer's DOM element to account for any offset
    const rendererDomElement = gl.domElement
    const rect = rendererDomElement.getBoundingClientRect()

    return {
      x: Math.round((vector.x + 1) * rect.width / 2),
      y: (Math.round((-vector.y + 1) * rect.height / 2)) + rect.top,
    }
  }

  const get2dPositionOfHighlightedMarkers = () => {

    const cam = cameraControls.current
    if (!cam) {
      return
    }

    // Project 3D positions to 2D for notHighlightedPartsMarkerInfo
    if (notHighlightedPartsMarkerInfo.current.length > 0) {
      notHighlightedPartsMarkerInfo.current = notHighlightedPartsMarkerInfo.current.map(part => ({
        ...part,
        markers: part.markers.map(marker => ({
          ...marker,
          camera2dPosition: projectTo2D(marker.worldPosition, cam.camera,),
        })),
      }))
    }

    // Project 3D positions to 2D for highlightedPartsMarkerInfo
    if (highlightedPartsMarkerInfo.current.length > 0) {
      highlightedPartsMarkerInfo.current = highlightedPartsMarkerInfo.current.map(part => ({
        ...part,
        markers: part.markers.map(marker => ({
          ...marker,
          camera2dPosition: projectTo2D(marker.worldPosition, cam.camera,),
        })),
      }))
    }

    //console.log(highlightedPartsMarkerInfo.current, "get2dPositionOfHighlightedMarkers: highlightedPartsMarkerInfo.current")
    //console.log(notHighlightedPartsMarkerInfo.current, "get2dPositionOfHighlightedMarkers: notHighlightedPartsMarkerInfo.current")
  }


  useEffect(() => {
    const cam = cameraControls.current
    if (!cam) {
      return
    }

    const drawNotHighlightedParts = () => {
      notHighlightedPartsMarkerInfo.current.forEach((part) => {
        part.markers.forEach((marker) => {
          if (marker.camera2dPosition) {
            drawPoint(ctxRef.current!, marker.camera2dPosition.x, marker.camera2dPosition.y, 1, "red")
          }
        })
      })
    }

    const drawHighlightedParts = () => {
      highlightedPartsMarkerInfo.current.forEach((part) => {
        part.markers.forEach((marker) => {
          if (marker.camera2dPosition) {
            drawPoint(ctxRef.current!, marker.camera2dPosition.x, marker.camera2dPosition.y, 1, "red")
          }
        })
      })
    }


    //updating of 2d position of markers on the screen for snapping logic
    //you need a slight timeout bc the camera still moved for a little right after it says
    //controlend

    let debounceTimeout: NodeJS.Timeout | null = null

    const debounce = (func: () => void, delay: number) => {
      return () => {
        if (debounceTimeout) {
          clearTimeout(debounceTimeout)
        }
        debounceTimeout = setTimeout(func, delay)
      }
    }

    const onChange = debounce(() => {
      //console.log("onChange")
      get2dPositionOfHighlightedMarkers()

      if (isAdmin) {
        clearCanvas()
        drawNotHighlightedParts()
        drawHighlightedParts()
        console.log("Updated notHighlightedPartsMarkerInfo:", notHighlightedPartsMarkerInfo.current)
        console.log("Updated highlightedPartsMarkerInfo:", highlightedPartsMarkerInfo.current)
      }
    }, 500)


    cam.addEventListener("control", onChange)

    // Clean up
    return () => {
      cam.removeEventListener("control", onChange)
    }
  }, [cameraControls,])

  const setupAnchorForTransform = (debug = false) => {
    removeAnchors()
    const anchor = new Object3D()
    updateSelectionBoundingBoxFromHighlightedPartIds(false)
    const boundingBoxCenter = new Vector3()
    selectionBoundingBox.current.getCenter(boundingBoxCenter)

    //createSelectionBoundingBoxHelper()

    if (debug) {
      drawVector3Point(boundingBoxCenter, scene, 0x00ff00, 0.008)
    }
    anchor.position.set(boundingBoxCenter.x, boundingBoxCenter.y, boundingBoxCenter.z)
    anchor.name = "Transforms Anchor"
    //console.log(transformMode, "transformMode in setupAnchorForTransform")

    if (transformMode === "rotate") {
      // Create a visible icosahedron for the anchor
      // Find the longest side of the bounding box
      const size = new Vector3()
      selectionBoundingBox.current.getSize(size)
      const shortestSide = Math.min(size.x, size.y, size.z)

      // Calculate the radius as 30% of the longest side
      const radius = shortestSide / 2
      const detail = 1 // Increase this value for a smoother shape with more faces
      const geometry = new IcosahedronGeometry(radius, detail)
      const material = new MeshBasicMaterial({ color: 0x000082, transparent: true, opacity: 0.3, })
      const visibleIcosahedron = new Mesh(geometry, material)

      // Create outline
      const edgesGeometry = new EdgesGeometry(geometry)
      const edgesMaterial = new LineBasicMaterial({
        color: 0x000082,
        linewidth: 1,
        transparent: true,
        opacity: 0.4,
        depthTest: false,
        depthWrite: false,
      })
      const outline = new LineSegments(edgesGeometry, edgesMaterial)

      // Add both the icosahedron and its outline to the anchor
      anchor.add(visibleIcosahedron)
      anchor.add(outline)
    }
    //setTransformsAnchor(anchor)
    transformsAnchorRef.current = anchor
    setAnchor(transformsAnchorRef.current)
    scene.add(transformsAnchorRef.current)

    //console.log("running set anchor position", transformsAnchorRef.current.position)
    //setAnchorPosition(transformsAnchorRef.current.position)

    lookForClonedGroups()
  }

  useEffect(() => {
    if (transformsAnchorRef.current) {
      //drawVector3Point(transformsAnchor.position, scene, "blue", 0.008)
      //console.log(transformsAnchor.position, "transformsAnchor.position useeffect")
    }
  }, [transformsAnchorRef.current, transformMode,])

  useEffect(() => {
    if (transformMode === "rotate" || transformMode === "scale" || transformMode === "translate") {
      setupAnchorForTransform(false)
      afterRotationMovementCleanup()

    }
    if (transformMode === "off") {
      setSceneMarkerColorsBackToOriginal()
      setVisibilityOfClonedGroupChildren(false)
      removeAnchors()
      //console.log("running destruction of models")
      removeHelpersFromScene()
    }
  }, [transformMode, selectionMode,])

  useEffect(() => {
    //when user turns on selection mode, store the 2d positions so we can use it for selector
    const { highlightedParts, otherParts, } = createClonedPartsWithMarkerInfo(false)
    highlightedPartsMarkerInfo.current = Object.values(highlightedParts)
    notHighlightedPartsMarkerInfo.current = Object.values(otherParts)
    get2dPositionOfHighlightedMarkers()
    storeBoundingBoxesOfNotHighlightedParts()
    //console.log(updateCounter, "updateCounter running when selection mode turns on")

    //console.log(highlightedParts, "highlightedParts running when selection mode turns on")
    //console.log(otherParts, "otherParts running when selection mode turns on")

  }, [selectedPart, selectionMode, updateCounter,])


  const createOrientedBoundingBox = (objects: Object3D[]) => {
    const points: Vector3[] = []

    objects.forEach(object => {
      object.updateMatrixWorld(true)
      if (object instanceof Mesh && object.geometry) {
        const geometry = object.geometry
        const positionAttribute = geometry.getAttribute("position")
        for (let i = 0; i < positionAttribute.count; i++) {
          const vertex = new Vector3()
          vertex.fromBufferAttribute(positionAttribute, i)
          vertex.applyMatrix4(object.matrixWorld)
          points.push(vertex)
        }
      }
    })

    const hull = new ConvexHull().setFromPoints(points)
    const hullVertices = hull.vertices.map(v => new Vector3(v.point.x, v.point.y, v.point.z))
    const hullGeometry = new ConvexGeometry(hullVertices)

    const material = new MeshBasicMaterial({
      color: 0x1b7fe3,
      wireframe: true,
      transparent: true,
      opacity: 0.5,
    })
    const boundingBoxMesh = new Mesh(hullGeometry, material)
    boundingBoxMesh.name = "orientedBoundingBox"

    return boundingBoxMesh
  }
  const createClonedMarkerModel = (
    id: string,
    meshes: Mesh[],
    visible = true,
    offsetMarkerPosition?: Vector3,
    initialMarkerName?: string,
    currentTubePosition?: Vector3,
    limitMarkersPerModel?: number,
  ): Group => {
    const group = new Group()
    group.name = `cloned_${id}`

    //console.log("running createclonedmarkermodel", id, "visible", visible)


    // Combine selected middle markers with other markers
    const finalMeshes = selectAndSortMeshes(meshes, limitMarkersPerModel, initialMarkerName ? [initialMarkerName,] : undefined)

    finalMeshes.forEach((mesh) => {

      if (mesh.name === "BOTTOM" || mesh.name === "TOP"
        || mesh.name.includes("inner") || mesh.name.includes("outer")) {

        const clonedMesh = mesh.clone()
        clonedMesh.name = `cloned_${mesh.name}`
        clonedMesh.visible = visible

        // Remove children if the mesh name is "BOTTOM" or "TOP" for the tube case

        if (mesh.name === "BOTTOM" || mesh.name === "TOP" || mesh.name.includes("inner") || mesh.name.includes("outer")) {
          while (clonedMesh.children.length > 0) {
            clonedMesh.remove(clonedMesh.children[0])
          }
        }



        clonedMesh.material = new MeshBasicMaterial({
          color: 0xe8f0f8,
          wireframe: true,
          wireframeLinewidth: 5,
          transparent: true,
          opacity: 0.5,
          depthTest: false,
          depthWrite: false,
        })

        // Copy userData
        //clonedMesh.userData = { ...mesh.userData }

        // Copy position, rotation, and scale
        const worldPosition = new Vector3()
        const worldQuaternion = new Quaternion()

        mesh.getWorldPosition(worldPosition)
        mesh.getWorldQuaternion(worldQuaternion)

        clonedMesh.position.copy(worldPosition)
        clonedMesh.quaternion.copy(worldQuaternion)

        group.add(clonedMesh)
      }
    })

    if (offsetMarkerPosition && initialMarkerName) {
      const initialMarker = meshes.find(mesh => mesh.name === innerToOuter(initialMarkerName))
        || meshes.find(mesh => mesh.name === outerToInner(initialMarkerName))

      if (initialMarker) {
        const offsetMarker = initialMarker.clone()
        offsetMarker.name = "cloned_offsetMarker"
        offsetMarker.position.copy(offsetMarkerPosition)
        offsetMarker.material = new MeshBasicMaterial({
          color: 0x900090, // Purple color
          wireframe: false,
          wireframeLinewidth: 5,
          transparent: true,
          opacity: 1,
          depthTest: false,
          depthWrite: false,
          visible: visible,
        })
        group.add(offsetMarker)
      }
    }

    if (currentTubePosition && initialMarkerName) {
      const initialMarker = meshes.find(mesh => mesh.name === innerToOuter(initialMarkerName))
        || meshes.find(mesh => mesh.name === outerToInner(initialMarkerName))

      if (initialMarker) {
        const offsetPositionMarker = initialMarker.clone()
        offsetPositionMarker.name = "cloned_offsetPositionMarker"
        offsetPositionMarker.position.copy(currentTubePosition)
        offsetPositionMarker.material = new MeshBasicMaterial({
          color: 0x900090, // Purple color
          wireframe: false,
          wireframeLinewidth: 5,
          transparent: true,
          opacity: 1,
          depthTest: false,
          depthWrite: false,
          visible: visible,
        })
        group.add(offsetPositionMarker)
      }
    }

    const orientedBoundingBox = createOrientedBoundingBox(meshes)
    orientedBoundingBox.name = `boundingBox_${id}`
    orientedBoundingBox.visible = visible
    group.add(orientedBoundingBox)

    // Function to update bounding box
    const updateBoundingBox = () => {
      group.remove(orientedBoundingBox)
      const newOrientedBoundingBox = createOrientedBoundingBox(meshes)
      newOrientedBoundingBox.name = `boundingBox_${id}`
      newOrientedBoundingBox.visible = visible
      group.add(newOrientedBoundingBox)
    }

    // Add the update function to the group
    group.userData.updateBoundingBox = updateBoundingBox

    group.position.set(0, 0, 0)

    return group
  }

  const destroyClonedMarkerModel = (id: string, archived?: boolean) => {
    //console.log("running destroy")

    const prefix = archived ? "archived" : "cloned"
    const clonedModels = scene.children.filter(child =>
      ((child as Object3D).isObject3D || child instanceof Group)
      && child.name.includes(prefix)
      && child.name.includes(id)
    ) as (Object3D | Group)[]

    //console.log(clonedModels, "clonedModels",)

    clonedModels.forEach(clonedModel => {
      // Recursively dispose of geometries and materials
      clonedModel.traverse((child) => {
        if (child instanceof Mesh) {
          if (child.geometry) {
            child.geometry.dispose()
          }
          if (child.material) {
            if (Array.isArray(child.material)) {
              child.material.forEach((material) => material.dispose())
            } else {
              child.material.dispose()
            }
          }
        } else if (child instanceof LineSegments) {
          if (child.geometry) {
            child.geometry.dispose()
          }
          if (child.material) {
            if (Array.isArray(child.material)) {
              child.material.forEach((material) => material.dispose())
            } else {
              child.material.dispose()
            }
          }
        }
      })

      // Remove from parent
      if (clonedModel.parent) {
        clonedModel.parent.remove(clonedModel)
      }

      // Clear any references
      clonedModel.clear()

      // If it's a Group, remove all children
      if (clonedModel instanceof Group) {
        while (clonedModel.children.length > 0) {
          clonedModel.remove(clonedModel.children[0])
        }
      }
    })

    // Force a garbage collection (optional, and only works if your environment supports it)
    if (typeof window !== "undefined" && "gc" in window) {
      (window as any).gc()
    }
  }

  const destroyAllClonedModels = () => {
    highlightedPartIds.current.forEach((id) => {
      destroyClonedMarkerModel(id, true)
      destroyClonedMarkerModel(id, false)
    })
  }

  const archiveAllClonedModels = (visible = true, destroyAfterArchive = true, functionToRunAfterArchive?: () => void, delayBeforeRunningFunction?: number) => {
    highlightedPartIds.current.forEach((id) => {
      const clonedObjects = scene.children.filter(child =>
        ((child as Object3D).isObject3D || child instanceof Group)
        && child.name.includes("cloned")
        && child.name.includes(id)
      )

      clonedObjects.forEach(obj => {
        obj.name = `archived_${obj.name}`
        obj.visible = visible
        //console.log(obj, `archiveAllClonedModels ${obj instanceof Group ? "groupContainer" : "clonedModel"}`, visible)
      })
    })
    if (destroyAfterArchive) {
      destroyAllClonedModels()
    }
    if (functionToRunAfterArchive) {
      setTimeout(() => {
        functionToRunAfterArchive()
      }, delayBeforeRunningFunction ?? 0)
    }

  }

  const getPartInfoAndMarkerData = (partId: string, markers: Mesh[]) => {
    const component = getComponent(partId)
    if (!component) {
      console.warn(`Component not found for partId: ${partId}`)
      return null
    }

    const partInfo = component.getPartInfo()
    if (!partInfo) {
      console.warn(`PartInfo not found for partId: ${partId}`)
      return null
    }

    const partPosition = new Vector3(partInfo.position.x, partInfo.position.y, partInfo.position.z)
    const partRotation = new Quaternion(
      partInfo.rotation.x,
      partInfo.rotation.y,
      partInfo.rotation.z,
      partInfo.rotation.w,
    )

    const markerData = markers.map((marker) => {
      marker.rotateY(MathUtils.degToRad(180))

      const worldPosition = new Vector3()
      const worldQuaternion = new Quaternion()
      marker.getWorldPosition(worldPosition)
      marker.getWorldQuaternion(worldQuaternion)
      marker.rotateY(MathUtils.degToRad(-180))

      return {
        name: marker.name,
        worldPosition,
        worldQuaternion,
      }
    })

    const positionMarker = markerData.find(
      (m) => m.worldPosition.distanceTo(partPosition) < 0.001, // Use a small threshold for floating-point comparison
    )

    const rotationMarker = markerData.find(
      (m) => Math.abs(m.worldQuaternion.dot(partRotation) - 1) < 0.001, // Use a small threshold for quaternion comparison
    )

    return {
      partId,
      partPosition,
      partRotation,
      markers: markerData,
      positionMarker: positionMarker?.name,
      rotationMarker: rotationMarker?.name,
    }
  }


  const getMarkersandCreateClonedModelsForHighlightedPartIds = () => {
    setIsCreatingModels(true)
    highlightedPartIds.current.forEach((partId) => {
      const component = getComponent(partId)
      if (component) {
        let markers: Mesh[] = component.getAllMarkers()

        //console.log(markers, "from get all markers")

        const initialMarkerName = component.getPartInfo(partId)?.initialMarkerName

        markers = selectAndSortMeshes(markers, 20, initialMarkerName ? [initialMarkerName,] : undefined)

        markers = markers.filter((marker: Mesh) => marker !== null && marker !== undefined)

        const partInfo = component.getPartInfo()

        const currentTubePosition = new Vector3(partInfo.position.x, partInfo.position.y, partInfo.position.z)

        let clonedModel

        if (partInfo.markerOffset && partInfo.initialMarkerName) {
          const offsetMarkerPosition = new Vector3(
            partInfo.markerOffset.x,
            partInfo.markerOffset.y,
            partInfo.markerOffset.z
          )
          clonedModel = createClonedMarkerModel(
            partId,
            markers,
            false,
            offsetMarkerPosition,
            partInfo.initialMarkerName,
            undefined,
            20,
          )
        } else {
          clonedModel = createClonedMarkerModel(partId, markers, false, undefined, partInfo.initialMarkerName, currentTubePosition, 20)
        }

        scene.add(clonedModel)

        const partInfoAndMarkerData = getPartInfoAndMarkerData(partId, markers)

        if (partInfoAndMarkerData) {

          const existingIndex = highlightedPartsPositionRotationInfo.current.findIndex(
            (info: any) => info.partId === partInfoAndMarkerData.partId
          )

          if (existingIndex !== -1) {
            // Remove the existing entry
            highlightedPartsPositionRotationInfo.current.splice(existingIndex, 1)
          }

          highlightedPartsPositionRotationInfo.current.push(partInfoAndMarkerData)
        }
      }
    })
    setIsCreatingModels(false)
  }

  async function updatePartPositionAndRotation(partId: string) {
    const partPositionRotationInfoFromClonedModel: any = highlightedPartsPositionRotationInfo.current.find(
      (part: any) => part.partId === partId,
    )

    if (partPositionRotationInfoFromClonedModel) {
      const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
      if (clonedGroup) {
        const component = getComponent(partId)
        const partInfoRef = component?.getPartInfo()

        let initialMarkerNameWithFallBack

        if (partInfoRef) {
          initialMarkerNameWithFallBack = partInfoRef.initialMarkerName || partInfoRef.originMarkerName
        }

        let positionMarker: Object3D | Group | undefined
        let rotationMarker: Object3D | Group | undefined
        let offsetMarker: Object3D | Group | undefined

        //console.log(partInfoRef.initialMarkerName, "partinfo initial marker name")

        if (partPositionRotationInfoFromClonedModel) {
          if (
            partInfoRef.markerOffset
            && initialMarkerNameWithFallBack
          ) {
            positionMarker = clonedGroup.getObjectByName(
              `cloned_${innerToOuter(initialMarkerNameWithFallBack)}`,
            ) as Object3D | Group

            const markerPosition = new Vector3()
            positionMarker.getWorldPosition(markerPosition)

            offsetMarker = clonedGroup.getObjectByName(
              "cloned_offsetMarker",
            ) as Object3D | Group

            //console.log(clonedGroup, offsetMarker, "offsetMarker x")
            //console.log("found both offset and position markers in part info")
          }
          else if (partPositionRotationInfoFromClonedModel.positionMarker) {
            positionMarker = clonedGroup.getObjectByName(`cloned_${partPositionRotationInfoFromClonedModel.positionMarker}`) as
              | Object3D
              | Group

            //console.log(positionMarker.name, "positionMarker name")
            if (positionMarker.name.includes("-") || /inner\d+_/.test(positionMarker.name) || /outer\d+_/.test(positionMarker.name)) {
              positionMarker = clonedGroup.getObjectByName(
                `cloned_${innerToOuter(initialMarkerNameWithFallBack)}`,
              ) as Object3D | Group
            }
            //console.log("found position marker in in  partPositions")
          }

          else if (!partPositionRotationInfoFromClonedModel.positionMarker) {
            //console.log(clonedGroup, "cloned group here yo")
            //console.log(partInfoRef, "partInfoRef here yo")
            positionMarker = clonedGroup.getObjectByName(
              `cloned_${innerToOuter(initialMarkerNameWithFallBack)}`,
            ) as Object3D | Group
            //console.log(positionMarker, "positionMarker here yo")
            //console.log(initialMarkerNameWithFallBack, "initialMarkerNameWithFallBack here yo")

            if (!positionMarker && initialMarkerNameWithFallBack.includes("outer")) {
              positionMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("outer", "inner")}`,
              ) as Object3D | Group
            }

            if (!positionMarker && initialMarkerNameWithFallBack.includes("inner")) {
              positionMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("inner", "outer")}`,
              ) as Object3D | Group
            }

            //console.log("couldn't find position marker in in  partPositions so set it using initial marker name")
          }

          rotationMarker = clonedGroup.getObjectByName(`cloned_${partPositionRotationInfoFromClonedModel.rotationMarker}`) as
            | Object3D
            | Group

          if (!rotationMarker) {
            rotationMarker = clonedGroup.getObjectByName(
              `cloned_${initialMarkerNameWithFallBack}`,
            ) as Object3D | Group

            if (!rotationMarker && initialMarkerNameWithFallBack.includes("inner")) {
              rotationMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("inner", "outer")}`,
              ) as Object3D | Group
            }
            if (!rotationMarker && initialMarkerNameWithFallBack.includes("outer")) {
              rotationMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("outer", "inner")}`,
              ) as Object3D | Group
            }
          }

          if (!rotationMarker) {
            console.warn("rotationMarker not found", initialMarkerNameWithFallBack, clonedGroup)
          }
        }

        //console.log(clonedGroup, "cloned group")
        //console.log(positionMarker, "positionMarker", rotationMarker, "rotationMarker")

        //console.log(clonedGroup, "cloned group")
        //console.log(positionMarker, "positionMarker", rotationMarker, "rotationMarker")

        if (!offsetMarker && partInfoRef.lengthNegativeSide) {
          positionMarker = clonedGroup.getObjectByName(
            "cloned_offsetPositionMarker",
          ) as Object3D | Group

        }

        if (positionMarker && rotationMarker) {
          const newPosition = new Vector3()
          const newOffsetPosition = new Vector3()
          const newRotation = new Quaternion()

          rotationMarker.rotateY(MathUtils.degToRad(180))
          rotationMarker.getWorldQuaternion(newRotation)
          positionMarker.getWorldPosition(newPosition)

          if (offsetMarker) {
            offsetMarker.getWorldPosition(newOffsetPosition)
          }
          rotationMarker.rotateY(MathUtils.degToRad(-180))

          const modifiedRotation = {
            x: newRotation.x ?? (newRotation as any)._x,
            y: newRotation.y ?? (newRotation as any)._y,
            z: newRotation.z ?? (newRotation as any)._z,
            w: newRotation.w ?? (newRotation as any)._w,
          }

          // Get the component and update its position and rotation
          if (component && component.updatePositionAndRotation) {
            if (offsetMarker) {
              await component.updatePositionAndRotation(newOffsetPosition, modifiedRotation, newOffsetPosition)
            } else {
              await component.updatePositionAndRotation(newPosition, modifiedRotation)
            }
          }
        }
      }
    }
  }
  const applyRotationPositionToHighlightedParts = async (rotationDiff?: Quaternion, debug = false, rotationAxis?: ROTATION_AXES_ENUM, value?: number) => {
    if (debug) {
      drawVector3Point(transformsAnchorRef.current.position, scene, "red", 0.005)
    }

    //console.log(
    //  highlightedPartsPositionRotationInfo.current,
    //  "highlightedPartsPositionRotationInfo.current",
    //)

    // Apply rotation to the master group when user is using the sphere
    // the direct controls to rotate

    if (rotationAxis && value) {
      //console.log(rotationAxis, "rotationAxis in applyRotationPositionToHighlightedParts")
      //console.log(value, "value in applyRotationPositionToHighlightedParts")
      if (rotationAxis === ROTATION_AXES_ENUM.X) {
        transformsAnchorRef.current.rotateX(value)
      }
      if (rotationAxis === ROTATION_AXES_ENUM.Y) {
        transformsAnchorRef.current.rotateY(value)
      }
      if (rotationAxis === ROTATION_AXES_ENUM.Z) {
        transformsAnchorRef.current.rotateZ(value)
      }
    }

    if (transformsAnchorRef.current) {
      if (debug) {
        drawVector3Point(transformsAnchorRef.current.position, scene, "red", 0.0005)
      }
    } else {
      console.warn("transformsAnchor is null")
    }

    //grab the rotations from the markers in the group

    const updatePromises = highlightedPartIds.current.map(partId => updatePartPositionAndRotation(partId))

    await Promise.all(updatePromises)
    setBlockUI(false)

    // Needed to wait for all the values to update on all parts
    setTimeout(() => {
      updateSelectionBoundingBoxFromHighlightedPartIds(false)
    }, 100)
  }

  const setIdsAsHighlightedAndTurnOnControl = (ids: string[], control: "selection" | "translate" | "rotate",) => {
    setIsSelected(false)

    resetSelection()

    setTimeout(() => {
      updateSelection(undefined, ids, false)
      setTimeout(() => {
        if (control === "selection") {
          setSelectionMode(true)
        }
        if (control === "translate") {
          setSelectionMode(false)
          setTimeout(() => {
            setTransformMode("translate")
          }, 300)
        }
        if (control === "rotate") {
          setSelectionMode(false)
          setTimeout(() => {
            setTransformMode("rotate")
          }, 300)
        }
      }, 200)
    }, 200)

  }

  useEffect(() => {
    //console.log(hideMultiUIControls, "hideMultiUIControls")
  }, [hideMultiUIControls,])

  useEffect(() => {
    //console.log(selectedPart, "selectedPart")
    if (selectedPart) {
      setHideMultiUIControls(true)
      setSelectionMode(false)
      //console.log(highlightedPartIds.current, "highlightedPartIds.current in use effect")
      if (!highlightedPartIds.current.includes(selectedPart.id)) {
        resetSelection()
      }
    }
    if (!selectedPart) {
      setHideMultiUIControls(false)
      setTransformMode("off")
      resetSelection()
    }
  }, [selectedPart,])

  useEffect(() => {
    //console.log(transformsAnchorRef.current, "transformAnchor useffect when transform is changing")
  }, [transformMode,])

  const lookForClonedGroups = () => {
    //console.log("lookForClonedGroups")
    for (const partId of highlightedPartIds.current) {
      const component = getComponent(partId)
      if (component) {
        const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
        if (!clonedGroup) {
          if (!isCreatingModels) {
            getMarkersandCreateClonedModelsForHighlightedPartIds()
          }
          break
        }
      }
    }
  }

  const afterRotationMovementCleanup = () => {
    //console.log("afterRotationMovementCleanup")
    archiveAllClonedModels(false, true, getMarkersandCreateClonedModelsForHighlightedPartIds, 200)
    setUpdateCounter(prevState => prevState + 1)
  }

  const processFilteredResults = (results: any[]) => {
    const uniquePairs = new Map()

    // Step 1: Filter results to ensure unique pairings
    results.forEach(({ clonedMarker, sceneMarkerMesh, }) => {
      const clonedPartId = clonedMarker.userData.partId
      const scenePartId = sceneMarkerMesh.userData.partId

      const pairKey = [clonedPartId, scenePartId,].sort((a, b) => a.localeCompare(b)).join("-")

      if (!uniquePairs.has(pairKey)) {
        uniquePairs.set(pairKey, { clonedMarker, sceneMarkerMesh, })
      }
    })

    // Step 2: Create the array of connections
    const connections = Array.from(uniquePairs.values()).map(({ clonedMarker, sceneMarkerMesh, }) => ({
      partA: {
        markerName: sceneMarkerMesh.name,
        partId: sceneMarkerMesh.userData.partId,
      },
      partB: {
        markerName: clonedMarker.name.replace("cloned_", ""),
        partId: clonedMarker.userData.partId,
      },
    }))

    return connections
  }

  const preventDefault = (event: TouchEvent) => {
    event.preventDefault()
  }

  const startCheckInterval = () => {
    // Always clear existing interval first
    if (checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current)
    }
    // Set new interval
    checkIntervalRef.current = setInterval(() => {
      checkForCloseCollinearMarkersAndColorThem()
    }, 300)
  }
  const stopCheckInterval = () => {
    if (checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current)
      checkIntervalRef.current = null
    }
  }

  const transformsOnMouseUp = () => {
    setBlockUI(true)
    setTimeout(() => {
      const partCount = highlightedPartIds.current.length

      messageUtils.custom(`Updating positions + rotations for ${partCount} part${partCount > 1 ? "s" : ""}`, {
        duration: 1, showSpinner: true, forceShow: true,
      })

      //const point1 = performance.now()

      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current)
      }


      enableCamera(cameraControls.current || undefined)
      setTimeout(() => {
        setAnchorChanging(false)
      }, 200)

      // Clear the interval
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current)
        checkIntervalRef.current = null
      }

      document.body.removeEventListener("touchmove", preventDefault)

      //set visibilityforallClonedgroups
      // setCameraState("pan")
      setOnTopOfTransformControls(false)
      reattachMergedMeshesToPreviousParents()
      setBlockUI(false)

      requestidleCallbackWithFallback(() => {
        setSceneMarkerColorsBackToOriginal()
        setVisibilityOfClonedGroupChildren(false)
      }, 300)

      //console.log(transformMode, "transformMode in transformsOnMouseUp")

      if (transformsAnchorRef.current) {

        //const point1 = performance.now()
        if (transformMode === "translate" || transformMode === "rotate") {
          //const point1 = performance.now()
          applyRotationPositionToHighlightedParts()
          //const point2 = performance.now()
          //console.log(`applyRotationPositionToHighlightedParts took ${point2 - point1} milliseconds`)
        }
        //const point2 = performance.now()
        //console.log(`applyRotationPositionToHighlightedParts took ${point2 - point1} milliseconds`)

        // Update the selection bounding box
        updateSelectionBoundingBoxFromHighlightedPartIds(false)

        //console.log(highlightedPartsConnections.current, "highlightedPartsConnections.current")
        //console.log(connectedSets, "connectedSets")
      }

      //rename so that any other translate or rotate functions dont interfere and get confused grabbing an outdated model

      setTimeout(() => {
        checkConnectionsSnapUnsnap(true, true)
      }, 250)

      //const point2 = performance.now()
      //console.log(`transformMouseUp took ${point2 - point1} milliseconds`)
    }, 0)


  }

  const transformsOnMouseDown = () => {
    disableCamera(cameraControls.current || undefined)
    document.body.addEventListener("touchmove", preventDefault, { passive: false, })
    setOnTopOfTransformControls(true)
    //const point1 = performance.now()
    attachHighlightedPartsToTransformControls()
    //const point2 = performance.now()
    //console.log(`attachHighlightedPartsToTransformControls took ${point2 - point1} milliseconds`)
    setAnchorChanging(true)
    // Start the interval immediately
    startCheckInterval()
    // Give message to users that they can hold the parts close to where they want it and then it'll stick
    messageUtils.custom("Move the parts near your desired location, wait a moment, and a green indicator will show when they're ready to snap together.", {
      duration: 8,
      showUpTo: 3,
      minTimeBetweenShows: 10,
    })
  }


  const onRotationSliderChange = (rotationAxis: ROTATION_AXES_ENUM, value: number) => {
    if (!rotationAxis || !value) {
      console.warn("rotationAxis and value are undefined")
      return
    }

    if (transformsAnchorRef.current) {

      if (transformMode === "rotate") {
        setBlockUI(true)
        attachHighlightedPartsToTransformControls()
        applyRotationPositionToHighlightedParts(undefined, false, rotationAxis, value)
        reattachMergedMeshesToPreviousParents()
        setBlockUI(false)

      } else {
        console.warn("transformMode is not rotate")
        return
      }

      // Update the selection bounding box
      updateSelectionBoundingBoxFromHighlightedPartIds(false)

    }

    afterRotationMovementCleanup()

    //console.log(highlightedPartsConnections.current, "highlightedPartsConnections.current")
    //console.log(connectedSets, "connectedSets")
  }

  const setVisibilityOfClonedGroupChildren = (visibility = true, setColor = 0x1a548e) => {
    highlightedPartIds.current.forEach((partId) => {
      const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
      if (clonedGroup) {
        clonedGroup.visible = visibility
        clonedGroup.traverse((child) => {
          child.visible = visibility
          if (child instanceof Mesh) {
            child.material.color.setHex(setColor)
          }
        })
        //console.log("setting visibility of cloned group to ", clonedGroup.uuid, visibility)
      }
    })
  }

  const attachHighlightedPartsToTransformControls = () => {
    highlightedPartIds.current.forEach((partId) => {
      const component = getComponent(partId)
      if (component) {
        // Find the cloned group for this part
        const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
        if (clonedGroup && transformsAnchorRef.current) {
          // Add the cloned group to the master group
          requestidleCallbackWithFallback(() => setVisibilityOfClonedGroupChildren(true), 200)
          transformsAnchorRef.current.attach(clonedGroup)
        } else {
          console.warn(`Cloned group not found for part ${partId}`)
        }

        // Find and attach any meshes with the name "MergedMesh_${partId}"

        let mesh = component.getMesh(0x1b7fe3)
        //console.log(mesh, "mesh we got back")
        if (mesh.name.includes("BOTTOM") || mesh.name.includes("TOP")) {
          //console.log("case, mesh.name", mesh)
          //return
        }
        if (mesh.name.includes("delete_after_use")) {
          component.changeVisibilityOnSpecificIndex(false)
        }
        if (mesh.name.includes("MergedMesh")) {
          //this is because we need the origin in order to avoid strange matrix behaviour
          mesh = mesh.parent.parent
        }
        if (mesh && transformsAnchorRef.current) {
          const parentUuid = mesh.parent ? mesh.parent.uuid : scene.uuid

          previousParentsToMergedMeshes.current.set(mesh.uuid, { parentUuid, partId, })
          //console.log(mesh.uuid, "mesh.uuid", parentUuid, "parentUuid", partId, "partId")
          //console.log(previousParentsToMergedMeshes.current, "previousParentsToMergedMeshes.current in attachHighlightedPartsToTransformControls")
          transformsAnchorRef.current.attach(mesh)
        } else {
          console.warn(`Merged mesh not found for part ${partId}`)
        }
      }
    })
    //console.log(previousParentsToMergedMeshes.current, "at attaching previousParentsToMergedMeshes.current")
  }

  const reattachMergedMeshesToPreviousParents = () => {
    //console.log(previousParentsToMergedMeshes.current, "previousParentsToMergedMeshes.current")
    previousParentsToMergedMeshes.current.forEach((parentInfo, uuid) => {
      //console.log(parentInfo, "parentInfo", uuid, "uuid")
      const mesh = scene.getObjectByProperty("uuid", uuid) as Mesh
      if (mesh) {
        if (mesh.name.includes("delete_after_use")) {
          const component = getComponent(parentInfo.partId)
          if (component) {
            component.changeVisibilityOnSpecificIndex(true)
            setTimeout(() => {
              mesh.removeFromParent()
              component.originalColor()
            }, 100)
          }
          return
        }
        if (parentInfo.parentUuid) {
          const parent = scene.getObjectByProperty("uuid", parentInfo.parentUuid) as Object3D
          //console.log(parent, "parent", mesh, "mesh")
          if (parent && mesh) {
            parent.attach(mesh)
          }
        } else {
          console.warn(`Parent not found for mesh ${uuid}`)
        }
        if (mesh.name.includes("BOTTOM") || mesh.name.includes("TOP")) {
          const component = getComponent(parentInfo.partId)
          if (component) {
            component.originalColor()
          }
        }
      }
    })
    previousParentsToMergedMeshes.current.clear()
  }

  const areDirectionsFacingEachOther = (dir1: Vector3, dir2: Vector3, tolerance = 0.9): boolean => {
    // Normalize the directions
    const normalizedDir1 = dir1.clone().normalize()
    const normalizedDir2 = dir2.clone().normalize()

    // Calculate the dot product
    const dotProduct = normalizedDir1.dot(normalizedDir2)

    // Check if the dot product is close to -1 (vectors pointing in oppos directions)
    return dotProduct <= -tolerance
  }

  const checkCloseCollinearMarkers3DOnly = (debug = false, distanceThresholdthreeD = 3, checkForCompatibility = false, skipResults?: boolean, intersectingBoxFactor?: number) => {
    const DISTANCE_THRESHOLD_FOR_3D = distanceThresholdthreeD
    const threedResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, }[] = []
    if (!transformsAnchorRef.current) {
      return { threedResults: [], uniqueClosestResults: [], }
    }

    const boundingBoxOfGroupAttachedToAnchor: Mesh | null = null
    const box3OfGroupAttachedToAnchor: Box3 | null = null
    const boundingBoxOfGroupAttachedToAnchorsArray: Box3[] = []
    const intersectingIdsSet = new Set<string>()


    const notHighlightedPartsSet = new Set(boundingBoxesOfNotHighlightedParts.current.map(box => box.partId))

    transformsAnchorRef.current.children.forEach(child => {
      const cam = cameraControls.current
      if (child.name.includes("cloned_") && cam) {
        child.children.forEach(childObject => {
          if (childObject.name.includes("boundingBox")) {
            const boundingBoxMesh = childObject as Mesh
            const geometry = boundingBoxMesh.geometry

            if (!geometry.boundingBox) {
              geometry.computeBoundingBox()
            }

            if (geometry.boundingBox) {
              const box = new Box3().copy(geometry.boundingBox)
              box.applyMatrix4(boundingBoxMesh.matrixWorld)
              box.expandByScalar(intersectingBoxFactor || 0.005)
              boundingBoxOfGroupAttachedToAnchorsArray.push(box)
            }
          }
        })
      }
    })

    boundingBoxOfGroupAttachedToAnchorsArray.forEach(box => {
      boundingBoxesOfNotHighlightedParts.current.forEach(notHighlightedBox => {
        if (box.intersectsBox(notHighlightedBox.boundingBox) && notHighlightedPartsSet.has(notHighlightedBox.partId)) {
          intersectingIdsSet.add(notHighlightedBox.partId)
        }
      })
    })



    //intersectingIds.current = Array.from(intersectingIdsSet)
    //console.log(intersectingIds, "intersectingIds")
    // Get the set of valid IDs from notHighlightedPartsMarkerInfo
    const validIds = new Set(notHighlightedPartsMarkerInfo.current.map(part => part.id))

    // Filter the intersectingIdsSet to only include valid IDs
    const filteredIntersectingIds = Array.from(intersectingIdsSet).filter(id => validIds.has(id))

    // Set the filtered IDs to intersectingIds.current
    intersectingIds.current = filteredIntersectingIds


    if (skipResults) {
      return { threedResults: [], uniqueClosestResults: [], }
    }
    transformsAnchorRef.current.children.forEach(child => {
      child.children
        .filter(childObject => !childObject.name.includes("boundingBox"))
        .forEach(clonedMarker => {
          const clonedMarkerPosition = new Vector3()
          clonedMarker.getWorldPosition(clonedMarkerPosition)

          const clonedMarkerDirection = MeshUtils.copyWorldDirection(clonedMarker)
          intersectingIds.current.forEach(id => {
            const partInfo = notHighlightedPartsMarkerInfo.current.find(part => part.id === id)
            if (partInfo) {
              //console.log(partInfo, "partInfo")
              partInfo.markers.forEach(sceneMarker => {
                const sceneMarkerMesh = sceneMarker.meshObject

                const sceneMarkerPosition = new Vector3()
                sceneMarkerPosition.setFromMatrixPosition(sceneMarkerMesh.matrixWorld)


                const distance3D = clonedMarkerPosition.distanceTo(sceneMarkerPosition)

                const potentialMatches: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, }[] = []

                // First, filter based on distance only
                if (distance3D <= DISTANCE_THRESHOLD_FOR_3D) {
                  potentialMatches.push({ clonedMarker, sceneMarkerMesh, distance3D, })
                }

                // Process the potential matches
                potentialMatches.forEach(match => {
                  if (areDirectionsFacingEachOther(clonedMarkerDirection, sceneMarker.markerDirection)) {
                    if (checkForCompatibility) {
                      const isMarkerAlreadyConnected = isMarkerConnected(
                        match.clonedMarker.name,
                        match.clonedMarker.userData.partId,
                        getLatestSceneData().connections,
                        match.sceneMarkerMesh.name
                      )

                      if (!isMarkerAlreadyConnected) {
                        const compatibleWith = isCompatibleWith2Meshes(
                          match.clonedMarker as Mesh,
                          match.sceneMarkerMesh as Mesh,
                          connectionTypes,
                          true
                        )
                        if (compatibleWith) {
                          threedResults.push(match)
                        }
                      }
                    } else {
                      // If we're not checking for compatibility, all direction-facing matches are valid
                      threedResults.push(match)
                    }
                  }
                })
              })
            }
          })
        })
    }
    )

    return { threedResults, }
  }

  const checkCloseCollinearMarkers = (debug = false, distanceThresholdthreeD = 0.5, distanceThreshold2D = 25, checkForCompatibility = false) => {

    //const startTime = performance.now()

    //be careful in reducing the 2d distance, it will increase the results by quite a lot and make this slow

    const DISTANCE_THRESHOLD_FOR_3D = distanceThresholdthreeD
    const DISTANCE_THRESHOLD_2D = distanceThreshold2D
    const twodresults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, }[] = []
    const threedDistanceFilteredResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, }[] = []
    const nonMiddleResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, }[] = []
    const atLeastOneEndResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, }[] = []

    if (!transformsAnchorRef.current) {
      return { twodresults, threedDistanceFilteredResults, uniqueClosestResults: [], }
    }

    //console.log(intersectingIds.current, "intersectingIds.current")

    transformsAnchorRef.current.children.forEach(child => {
      const cam = cameraControls.current
      if (child.name.includes("cloned_") && cam) {
        child.children
          .filter(childObject => !childObject.name.includes("boundingBox"))
          .forEach(clonedMarker => {
            const clonedMarkerPosition = new Vector3()
            clonedMarker.getWorldPosition(clonedMarkerPosition)
            const clonedMarkerDirection = MeshUtils.copyWorldDirection(clonedMarker)
            const clonedMarker2DPosition = projectTo2D(clonedMarkerPosition, cam.camera)

            const partsToCheck = intersectingIds.current.length > 0
              ? intersectingIds.current
              : notHighlightedPartsMarkerInfo.current.map(part => part.id)

            partsToCheck.forEach(id => {
              const partInfo = notHighlightedPartsMarkerInfo.current.find(part => part.id === id)
              if (partInfo) {
                partInfo.markers.forEach(sceneMarker => {
                  const sceneMarkerMesh = sceneMarker.meshObject
                  const sceneMarker2DPosition = sceneMarker.camera2dPosition

                  //console.log(sceneMarkerMesh, "sceneMarkerMesh")
                  //console.log(sceneMarker2DPosition, "sceneMarker2DPosition")

                  if (!sceneMarker2DPosition) {
                    console.warn("Scene marker 2D position is undefined")
                    return
                  }

                  const distance2D = Math.sqrt(
                    Math.pow(clonedMarker2DPosition.x - sceneMarker2DPosition.x, 2)
                    + Math.pow(clonedMarker2DPosition.y - sceneMarker2DPosition.y, 2)
                  )

                  const potentialMatches: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, }[] = []

                  // First, filter based on 2D distance only
                  if (distance2D <= DISTANCE_THRESHOLD_2D) {
                    potentialMatches.push({ clonedMarker, sceneMarkerMesh, distance2D, })
                  }

                  // Process the potential matches
                  potentialMatches.forEach(match => {
                    const clonedMarkerUnderscores = match.clonedMarker.name.split("_").length - 1
                    const sceneMarkerUnderscores = match.sceneMarkerMesh.name.split("_").length - 1

                    if (areDirectionsFacingEachOther(clonedMarkerDirection, sceneMarker.markerDirection)) {
                      if (checkForCompatibility) {
                        const isMarkerAlreadyConnected = isMarkerConnected(
                          match.clonedMarker.name,
                          match.clonedMarker.userData.partId,
                          getLatestSceneData().connections,
                          match.sceneMarkerMesh.name
                        )

                        if (!isMarkerAlreadyConnected) {
                          const compatibleWith = isCompatibleWith2Meshes(
                            match.clonedMarker as Mesh,
                            match.sceneMarkerMesh as Mesh,
                            connectionTypes,
                            true
                          )

                          if (compatibleWith) {
                            if ((clonedMarkerUnderscores === 1 && sceneMarkerUnderscores === 0) || (clonedMarkerUnderscores === 1 || sceneMarkerUnderscores === 0)) {
                              twodresults.push(match)
                            }
                          }
                        }
                      } else if ((clonedMarkerUnderscores === 1 && sceneMarkerUnderscores === 0) || (clonedMarkerUnderscores === 1 || sceneMarkerUnderscores === 0)) {
                        // If we're not checking for compatibility, all direction-facing matches are valid
                        twodresults.push(match)
                      }
                      // Check if the cloned marker name has only one underscore and scene marker mesh has no underscores
                    }
                  })
                })
              }
            })
          })
      }
    })


    //nonMiddleResults.sort((a, b) => a.distance2D - b.distance2D)
    //atLeastOneEndResults.sort((a, b) => a.distance2D - b.distance2D)

    twodresults.sort((a, b) => a.distance2D - b.distance2D)

    const closestResult: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, }[] = []
    const uniqueClosestResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, }[] = []

    //if (!(nonMiddleResults.length > 0 || atLeastOneEndResults.length > 0)) {

    //now filter for the results that are just way too far away in 3d space

    //console.log(twodresults.length, "twodresults length")
    twodresults.forEach(({ clonedMarker, sceneMarkerMesh, distance2D, }) => {
      const clonedMarkerPosition = new Vector3()
      clonedMarker.getWorldPosition(clonedMarkerPosition)

      const sceneMarkerPosition = new Vector3()
      sceneMarkerPosition.setFromMatrixPosition(sceneMarkerMesh.matrixWorld)

      const distance3D = clonedMarkerPosition.distanceTo(sceneMarkerPosition)

      if (distance3D < DISTANCE_THRESHOLD_FOR_3D) {
        threedDistanceFilteredResults.push({ clonedMarker, sceneMarkerMesh, distance3D, distance2D, })
      }


    })

    threedDistanceFilteredResults.sort((a, b) => a.distance3D - b.distance3D)


    /*

    // Group results by cloned marker and find the top 3 closest 3D distances
    const groupedByClonedMarker = new Map<string, { sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, }[]>()

    threedDistanceFilteredResults.forEach(({ clonedMarker, sceneMarkerMesh, distance3D, distance2D, }) => {
      const clonedMarkerKey = clonedMarker.uuid

      if (!groupedByClonedMarker.has(clonedMarkerKey)) {
        groupedByClonedMarker.set(clonedMarkerKey, [])
      }

      groupedByClonedMarker.get(clonedMarkerKey)!.push({ sceneMarkerMesh, distance3D, distance2D, })
    })


    // Sort each group by 3D distance and keep the top 3 closest
    groupedByClonedMarker.forEach((matches, key) => {
      matches.sort((a, b) => a.distance3D - b.distance3D)
      groupedByClonedMarker.set(key, matches.slice(0, 3))
    })

    // Find the best match for each cloned marker based on the lowest 2D distance among the top 3 closest 3D distances
    const bestMatches = new Map<string, { sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, }>()

    groupedByClonedMarker.forEach((matches, key) => {
      const bestMatch = matches.reduce((prev, curr) => (prev.distance3D < curr.distance3D ? prev : curr))
      bestMatches.set(key, bestMatch)
    })

    //console.log(bestMatches, "bestMatches")

    // Convert the Map back to an array of results
    uniqueClosestResults = Array.from(bestMatches.entries()).map(([clonedMarkerKey, { sceneMarkerMesh, distance3D, distance2D, },]) => {
        const clonedMarker = scene.getObjectByProperty("uuid", clonedMarkerKey,) as Object3D
        return { clonedMarker, sceneMarkerMesh, distance3D, distance2D, }
      })

    // Grab only the closest 3D distance result from the set
    closestResult = uniqueClosestResults.length > 0
      ? [uniqueClosestResults.reduce((prev, curr) => (prev.distance3D < curr.distance3D
        ? prev : curr), uniqueClosestResults[0]),]
      : []

  //}
  */




    //const endTime = performance.now()
    //const timeTaken = endTime - startTime
    //console.log(`Time taken: ${timeTaken} milliseconds`)

    return { twodresults, threedDistanceFilteredResults, uniqueClosestResults, closestResult, nonMiddleResults, atLeastOneEndResults, }
  }

  const setSceneMarkerColorsBackToOriginal = (color = "white", visibility = false) => {
    closeCompatibleMarkers.current.forEach(({ sceneMarkerMesh, }) => {
      if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
        sceneMarkerMesh.material.color.setColorName(color) // Red color for cloned markers
        sceneMarkerMesh.visible = visibility
        sceneMarkerMesh.material.visible = visibility
        //console.log(sceneMarkerMesh, "sceneMarkerMesh in setSceneMarkerColorsBackToOriginal")
      }
    })
    scene.traverse((object) => {
      if (object instanceof Mesh && object.userData.highlighted) {
        if (object.material instanceof MeshBasicMaterial) {
          object.material.color.setColorName(color)
          object.visible = visibility
          object.material.visible = visibility
        }
        object.userData.highlighted = false
      }
    })
  }

  // Make sure to clear the interval when the component unmounts
  useEffect(() => {
    //console.log = function() {};
    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (onTopOfTransformControls) {
      disableCamera(cameraControls.current || undefined)
    } else {
      enableCamera(cameraControls.current || undefined)
    }
  }, [onTopOfTransformControls,])

  useEffect(() => {
    if (isPinching && selectionMode) {
      setSelectionBoxIsDragging(false)
    }
  }, [isPinching, selectionMode,])

  const transformsOnChange = () => {
    //console.log("transformsOnChange")
  }



  const checkForCloseCollinearMarkersAndColorThem = () => {

    const currentTime = performance.now()
    const timeSinceLastOffset = currentTime - lastOffsetAppliedTime.current

    checkCloseCollinearMarkers3DOnly(false, undefined, false, true, 0.20)

    //console.log(timeSinceLastOffset, "timeSinceLastOffset")

    if (timeSinceLastOffset < 500) {
      return
    }

    const newAnchorPosition = new Vector3()
    transformsAnchorRef.current?.getWorldPosition(newAnchorPosition)

    const newAnchorRotation = new Quaternion()
    transformsAnchorRef.current?.getWorldQuaternion(newAnchorRotation)

    //console.log(newAnchorPosition, "newAnchorPosition in check")
    //console.log(lastAnchorValue.current, "lastAnchorValue.current in check in collinears")

    if ((transformMode === "translate" && !newAnchorPosition.equals(lastAnchorValue.current))
      || (transformMode === "rotate" && !newAnchorRotation.equals(lastAnchorRotation.current))) {

      lastAnchorValue.current = newAnchorPosition
      lastAnchorRotation.current = newAnchorRotation
      // Check for close and collinear markers

      //console.log("running on check")

      const { threedDistanceFilteredResults, twodresults, } = checkCloseCollinearMarkers()


      let resultsToUse: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D?: number, distance2D: number, }[] = []

      if (threedDistanceFilteredResults.length > 0) {
        resultsToUse = [threedDistanceFilteredResults[0],]
      } else if (twodresults.length > 0) {
        resultsToUse = [twodresults[0],]
      }

      if (resultsToUse) {
        // Set visibility of markers that are no longer in uniqueClosestResults to false

        if (closeCompatibleMarkers.current.length > 0 && closeCompatibleMarkers.current[0]) {
          closeCompatibleMarkers.current.forEach(({ clonedMarker, sceneMarkerMesh, }) => {
            if (resultsToUse.length > 0 && resultsToUse[0]) {
              const isClonedMarkerStillClose = resultsToUse.some(
                (result) => result.clonedMarker === clonedMarker
              )
              const isSceneMarkerMeshStillClose = resultsToUse.some(
                (result) => result.sceneMarkerMesh === sceneMarkerMesh
              )

              if (!isClonedMarkerStillClose) {
                if (clonedMarker instanceof Mesh && clonedMarker.material instanceof MeshBasicMaterial) {
                  clonedMarker.material.visible = false
                  clonedMarker.visible = false
                }
              }

              if (!isSceneMarkerMeshStillClose) {
                if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
                  sceneMarkerMesh.material.visible = false
                  sceneMarkerMesh.visible = false
                }
              }
            }
          })
        }

        if (resultsToUse) {
          resultsToUse.forEach(({ clonedMarker, sceneMarkerMesh, }) => {
            const isMarkerAlreadyConnected = isMarkerConnected(
              clonedMarker.name,
              clonedMarker.userData.partId,
              getLatestSceneData().connections,
              sceneMarkerMesh.name
            )

            if (isMarkerAlreadyConnected) {
              // If the marker is already connected, don't change its color
              // Just ensure it's visible
              if (clonedMarker instanceof Mesh && clonedMarker.material instanceof MeshBasicMaterial) {
                clonedMarker.visible = true
                clonedMarker.material.visible = true
              }
              if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
                sceneMarkerMesh.material.visible = true
                sceneMarkerMesh.visible = true
                sceneMarkerMesh.material.wireframe = true
              }
            } else {
              const compatibleWith = isCompatibleWith2Meshes(
                clonedMarker as Mesh,
                sceneMarkerMesh as Mesh,
                connectionTypes,
                true
              )

              const color = compatibleWith ? 0x00FF00 : 0xFFFF00 // Green if compatible, yellow otherwise

              if (clonedMarker instanceof Mesh && clonedMarker.material instanceof MeshBasicMaterial) {
                clonedMarker.material.color.setHex(color)
                clonedMarker.visible = true
                clonedMarker.material.visible = true
              }
              if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
                sceneMarkerMesh.material.color.setHex(color)
                sceneMarkerMesh.material.visible = true
                sceneMarkerMesh.visible = true
                sceneMarkerMesh.material.wireframe = true
                sceneMarkerMesh.userData.highlighted = true
              }
            }
          })
        }


        const applyOffsetToClonedGroups = (uniqueClosestResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, distance3D?: number, }[]) => {
          uniqueClosestResults.forEach(({ clonedMarker, sceneMarkerMesh, }) => {

            const partId = clonedMarker.userData.partId
            const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group

            const clonedGroupWorldPosition = new Vector3()
            clonedGroup.getWorldPosition(clonedGroupWorldPosition)

            const clonedGroupWorldQuaternion = new Quaternion()
            clonedGroup.getWorldQuaternion(clonedGroupWorldQuaternion)

            const anchorPosition = new Vector3()
            transformsAnchorRef.current?.getWorldPosition(anchorPosition)

            const anchorRotation = new Quaternion()
            transformsAnchorRef.current?.getWorldQuaternion(anchorRotation)

            //console.log("setting anchor position")

            if (clonedGroup && timeSinceLastOffset > 500) {
              if (clonedGroup.children.includes(clonedMarker)) {
                // Get the world position of the cloned marker
                const clonedMarkerWorldPosition = new Vector3()
                clonedMarker.getWorldPosition(clonedMarkerWorldPosition)

                const clonedMarkerWorldQuaternion = new Quaternion()
                clonedMarker.getWorldQuaternion(clonedMarkerWorldQuaternion)

                // Get the world position of the scene marker
                const sceneMarkerWorldPosition = new Vector3()
                sceneMarkerMesh.getWorldPosition(sceneMarkerWorldPosition)

                const sceneMarkerWorldQuaternion = new Quaternion()
                sceneMarkerMesh.getWorldQuaternion(sceneMarkerWorldQuaternion)

                // Calculate the offset between the scene marker and the cloned marker
                const offset = new Vector3()
                offset.subVectors(clonedMarkerWorldPosition, sceneMarkerWorldPosition)

                const offsetRotation = new Quaternion()
                offsetRotation.multiplyQuaternions(clonedMarkerWorldQuaternion, sceneMarkerWorldQuaternion.clone().invert())

                //debug
                //drawVector3Point(clonedGroupWorldPosition, scene, "red", 0.005)
                //drawVector3Point(clonedMarkerWorldPosition, scene, "blue", 0.005)
                //drawVector3Point(sceneMarkerWorldPosition, scene, "green", 0.005)

                // Add the offset to the cloned group's world position
                clonedGroupWorldPosition.add(offset)
                clonedGroupWorldQuaternion.multiply(offsetRotation)

                lastOffsetAppliedTime.current = currentTime

                //console.log(timeSinceLastOffset, "timeSinceLastOffset")

                anchorPosition.sub(offset)
                anchorRotation.multiply(offsetRotation)

                //drawVector3Point(anchorPosition, scene, "purple", 0.005)

                transformsAnchorRef.current.position.set(anchorPosition.x, anchorPosition.y, anchorPosition.z)
              }
            }
          })

        }

        closeCompatibleMarkers.current = resultsToUse ?? []

        if (resultsToUse.length > 0) {
          //console.log(closestResult, "closestResult in checkForCloseCollinearMarkersAndColorThem")

          applyOffsetToClonedGroups([resultsToUse[0],])
          messageUtils.custom("You can now let go of the mouse if this is where you wanted to snap!", {
            duration: 5,
            showUpTo: 3,
            minTimeBetweenShows: 10,
          })
        }
      }

    }
  }

  /*useEffect(() => {
    //console.log(tempAnchorLock, "temp anchor lock")
    if (tempAnchorLock && anchorLockPositionValue) {
      transformsAnchorRef.current.position.set(anchorLockPositionValue.x, anchorLockPositionValue.y, anchorLockPositionValue.z)
    }
  }, [tempAnchorLock,])*/



  return (
    <MultiSelectContext.Provider
      value={{
        selectionMode,
        setSelectionMode,
        newPartIds: newPartIds.current,
        checkDuplicationStatus,
        transformMode,
        setTransformMode,
        duplicateSelectedParts,
        updateCounter,
        setUpdateCounter,
        setIdsAsHighlightedAndTurnOnControl,
        hideMultiUIControls,
        setHideMultiUIControls,
        resetSelection,
        onRotationSliderChange,
        blockUI,
        setBlockUI,
      }}
    >
      {children}
      {transformMode !== "off" && anchor && (
        <TransformControls
          ref={transformControlsRef}
          object={transformsAnchorRef.current}
          mode={transformMode}
          onMouseUp={transformsOnMouseUp}
          onMouseDown={transformsOnMouseDown}
          onChange={transformsOnChange}
        />
      )}
      {highlightedPartIdsLength > 0 && !hideMultiUIControls && (
        <MultiSelectUI
          hideMultiUIControls={hideMultiUIControls}
          setHideMultiUIControls={setHideMultiUIControls}
          onDelete={deleteSelectedParts}
          duplicateSelectedParts={duplicateSelectedParts}
          changeTransformMode={setTransformMode}
          changeSelectionMode={setSelectionMode}
          resetSelection={resetSelection}
          transformMode={transformMode}
          selectionMode={selectionMode}
          onRotationSliderChange={onRotationSliderChange}
        />
      )}
      <TopRightButtons
        changeSelectionMode={setSelectionMode}
        changeTransformMode={setTransformMode}
        selectionMode={selectionMode}
        transformMode={transformMode}
        resetSelection={resetSelection}
        onTopOfTransformControls={onTopOfTransformControls}
        updateCounter={updateCounter}
        setUpdateCounter={setUpdateCounter}
      />
    </MultiSelectContext.Provider>
  )
}

export default MultiSelectProvider

